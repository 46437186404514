import React, { Component } from 'react';
import { Icon, Spin } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import _ from 'lodash';
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import { ContentExplorer } from 'box-ui-elements';
import messages from 'box-ui-elements/i18n/en-US';
import 'box-ui-elements/dist/explorer.css';

import Flex from '../styled/Flex';
import api from '../../helpers/api';

addLocaleData(enLocaleData);

class BoxFiles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFetchingToken: false,
			token: null,
			error: null,
			boxFolder: props.workspace.Box_Folder_ID,
		};
		this.fetchToken = this.fetchToken.bind(this);
	}

	componentDidMount() {
		this.fetchToken();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.customerDomain !== this.props.customerDomain) {
			this.fetchToken();
		}
	}

	async fetchToken() {
		this.setState({
			isFetchingToken: true,
		});
		try {
			const res = await axios.get(`${api().api}/box/token`, {
				headers: {
					'x-domain': this.props.customerDomain,
					'ngrok-skip-browser-warning': 40, 
				},
			});
			const data = await res.data;
			this.setState({
				token: data.token,
				isFetchingToken: false,
			});
		} catch (err) {
			this.setState({
				isFetchingToken: false,
				error: JSON.stringify(err),
			});
		}
	}

	render() {
		const { isFetchingToken, token, boxFolder } = this.state;
		const { workspace } = this.props;
		return (
			<div style={{ marginTop: '-70px' }}>
				{!_.isEmpty(workspace) ? (
					<>
						{isFetchingToken && (
							<Flex
								justifyContent="center"
								alignItems="center"
								style={{ height: '80vh' }}
							>
								<Spin />
							</Flex>
						)}

						{!boxFolder && !isFetchingToken && (
							<Flex
								justifyContent="center"
								alignItems="center"
								style={{ height: '80vh' }}
								i
								flexDirection="column"
							>
								<Icon
									type="frown"
									theme="outlined"
									style={{ fontSize: 32, marginBottom: 20 }}
								/>
								<p>Workspace not found for this domain</p>
							</Flex>
						)}

						{!isFetchingToken && boxFolder && (
							<div
								style={{
									border: '1px solid #e5e5e5',
									borderRadius: 3,
									height: '100vh',
									position: 'relative',
									top: 88,
								}}
							>
								<ContentExplorer
									token={token}
									language="en-US"
									messages={messages}
									rootFolderId={boxFolder}
									logoUrl="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
								/>
							</div>
						)}
					</>
				) : (
					<Flex
						justifyContent="center"
						alignItems="center"
						style={{ height: '80vh' }}
						i
						flexDirection="column"
					>
						<Icon
							type="frown"
							theme="outlined"
							style={{ fontSize: 32, marginBottom: 20 }}
						/>
						<p>Please select workspace</p>
					</Flex>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	workspace: state.workspace,
});
export default connect(mapStateToProps)(BoxFiles);
