import React, { forwardRef } from 'react';

import IdHeaderField from './IdHeaderField';
import ColumneHeader from './ColumnHeader';
import Flex from '../styled/Flex';

const DataTableHeader = ({
	visibleColumns,
	headerRef,
	handleSort,
	sortColumn,
	defaultQuery,
	sortDir,
}) => {
	if (visibleColumns.length > 1) {
		return (
			<Flex
				wrap="nowrap"
				style={{
					zIndex: 100,
					position: 'relative',
					overflow: 'hidden',
				}}
				ref={headerRef}
			>
				{visibleColumns.map(col =>
					col === '_id' ? (
						<IdHeaderField key={col} />
					) : (
						<ColumneHeader
							col={col}
							key={col}
							sortColumn={sortColumn}
							visibleColumns={visibleColumns}
							handleSort={handleSort}
							defaultQuery={defaultQuery}
							sortDir={sortDir}
						/>
					),
				)}
			</Flex>
		);
	}
	return null;
};

export default forwardRef((props, ref) => (
	<DataTableHeader {...props} headerRef={ref} />
));
