import React, { createRef } from 'react';
import { AutoSizer } from 'react-virtualized';

import DataTableHeader from './DataTableHeader';
import colors from './theme';

const headerRef = createRef();

const DataTableWrapper = ({
	children,
	visibleColumns,
	handleSort,
	sortColumn,
	defaultQuery,
	sortDir,
}) => {
	return (
		<div
			id="result-list"
			style={{
				marginTop: 15,
				border: `1px solid ${colors.tableBorderColor}`,
				borderRadius: 3,
				width: '100%',
				height: 450,
				overflow: 'visible',
			}}
		>
			<AutoSizer
				style={{
					height: '100% !important',
					width: '100% !important',
				}}
			>
				{({ height, width }) => (
					<>
						<DataTableHeader
							ref={headerRef}
							visibleColumns={visibleColumns}
							handleSort={handleSort}
							sortColumn={sortColumn}
							defaultQuery={defaultQuery}
							sortDir={sortDir}
						/>
						{children({ height, width, headerRef })}
					</>
				)}
			</AutoSizer>
		</div>
	);
};

export default DataTableWrapper;
