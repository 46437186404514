import React, { useState } from 'react';
import { Popover, Icon } from 'antd';

import Flex from '../styled/Flex';
import Comments from './Comments';

const CommentsPopup = ({ comments, docId, logData, currentView }) => {
	const [commentsList, setComments] = useState(comments);

	function onSaveComment(comment) {
		setComments([...commentsList, comment]);
	}
	return (
		<Popover
			placement="right"
			title="Comments"
			content={
				<Comments
					comments={commentsList}
					docId={docId}
					onSaveComment={onSaveComment}
					logData={logData}
					currentView={currentView}
				/>
			}
			trigger="click"
		>
			<Flex>
				<Icon
					type="message"
					style={{
						cursor: 'pointer',
						marginLeft: 10,
						fontSize: 20,
						color: '#1890ff',
					}}
				/>
			</Flex>
		</Popover>
	);
};

export default CommentsPopup;
