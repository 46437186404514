import React from 'react';
import { Popover } from 'antd';
import { MultiList } from '@appbaseio/reactivesearch';
import { css } from 'styled-components';
import FaFilter from 'react-icons/lib/fa/filter';
import moment from 'moment';

import Flex from '../styled/Flex';

import overflowStyles from './overflow.style';

import termAggregationStyles from './termAggregationStyles';
import colors from './theme';
import { getStringColumns } from '../../helpers/utils';

const TermAggregation = ({ field, visibleColumns }) => {
	const componentId = field;
	let dataField = field;
	const stringColumns = getStringColumns(visibleColumns);
	if (stringColumns.indexOf(field) > -1) {
		dataField = `${field}.keyword`;
	}

	const extraProps = {};

	if (field === 'Invoice_Y_N') {
		extraProps.showMissing = true;
		extraProps.missingLabel = 'Invoice Received';
		extraProps.defaultValue = ['Invoice Not Received'];
	}

	if (field === 'Invoice_Date' || field === 'Booking_Date') {
		extraProps.transformData = (data) => (data.map(item => ({
			key: moment(item.key).format('DD-MM-YY'),
			doc_count: item.doc_count
		})))
	}
	return (
		<Popover
			content={
				<div style={{ width: 200 }}>
					<MultiList
						componentId={componentId}
						dataField={dataField}
						size={150}
						filterLabel={field}
						css={termAggregationStyles}
						react={{ and: [`defaultQuery`] }}
						placeholder="Filter"
						loader="Loading ..."
						innerClass={{
							input: `ant-input ${css`
								height: 32px;
								background: ${colors.white} !important;
							`}`,
							checkbox: 'ant-checkbox-input',
						}}
						renderListItem={(label, count) => (
							<Flex
								alignItem="center"
								wrap="nowrap"
								justifyContent="space-between"
								style={{
									width: '100%',
								}}
							>
								<span
									style={{ maxWidth: 100 }}
									className={overflowStyles}
								>
									{label}
								</span>
								<span>{count}</span>
							</Flex>
						)}
						{...extraProps}
					/>
				</div>
			}
			trigger="click"
			placement="bottomRight"
		>
			<FaFilter style={{ cursor: 'pointer', marginTop: 2 }} />
		</Popover>
	);
};

export default TermAggregation;
