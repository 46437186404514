import React, { useState } from 'react';
import {
	Comment,
	Icon,
	List,
	Input,
	Button,
	ConfigProvider,
	notification,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import uuid4 from 'uuid/v4';

import Flex from '../styled/Flex';
import api from '../../helpers/api';
import Auth from '../../services/Auth';

const { TextArea } = Input;

const Comments = ({
	comments,
	onSaveComment,
	docId,
	email,
	customerDomain,
	currentView,
	logData,
	workspace,
}) => {
	const [comment, setComment] = useState('');
	const [isSaving, setIsSaving] = useState(false);
	const auth = new Auth();

	function handleCommentChange(e) {
		setComment(e.target.value);
	}

	async function saveComment() {
		try {
			setIsSaving(true);
			const data = {
				author: email,
				content: comment,
				datetime: moment().format('DD-MM-YYYY HH:mm'),
			};
			await axios.post(
				`${
					api().api
				}/airline-reports/shopelect-ui-updates/_doc/${docId}/_update`,
				{
					doc: {
						comments: [...comments, data],
					},
					doc_as_upsert: true,
				},
				{
					headers: {
						'x-autho-token': auth.getAccessToken(),
						'x-domain': customerDomain,
					},
				},
			);
			setComment('');
			await axios.put(
				`${
					api().api
				}/airline-reports/shopelect-activity-log/_doc/${uuid4()}`,
				{
					dataId: docId,
					comment: data,
					context: 'Comment',
					table: currentView,
					email: window.localStorage.getItem('email'),
					workspace,
					meta: logData,
					createdAt: moment(),
				},
				{
					headers: {
						'x-autho-token': auth.getAccessToken(),
						'x-domain': customerDomain,
					},
				},
			);
			onSaveComment(data);
			setIsSaving(false);
		} catch (err) {
			console.error(err);
			notification.error({
				message: 'Error',
				description: 'Unable to add comment',
			});
			setIsSaving(false);
		}
	}

	return (
		<div
			style={{
				width: 300,
			}}
		>
			<div style={{ maxHeight: 300, overflow: 'auto' }}>
				<ConfigProvider
					renderEmpty={() => (
						<div style={{ textAlign: 'center' }}>
							<Icon type="message" style={{ fontSize: 20 }} />
							<p>No comments</p>
						</div>
					)}
				>
					<List
						className="comment-list"
						itemLayout="horizontal"
						dataSource={comments}
						renderItem={item => (
							<Comment
								author={item.author}
								avatar={<Icon type="user" />}
								content={item.content}
								datetime={item.datetime}
							/>
						)}
					/>
				</ConfigProvider>
			</div>
			<Flex wrap="nowrap">
				<TextArea
					autosize
					placeholder="Add a note"
					value={comment}
					onChange={handleCommentChange}
				/>
				<Button
					disabled={isSaving || !comment}
					loading={isSaving}
					type="primary"
					style={{ marginLeft: 5 }}
					onClick={saveComment}
				>
					Save
				</Button>
			</Flex>
		</div>
	);
};

const mapStateToProps = state => ({
	email: window.localStorage.getItem('email'),
	customerDomain: state.customerDomain,
	workspace: state.workspace,
});

export default connect(mapStateToProps)(Comments);
