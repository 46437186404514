import React from 'react';
import { withRouter } from 'react-router-dom';
import {
	Button as BootStrapButton,
	Col,
  	FormGroup,
	Input,
	Label,
	Row as BoostrapRow,
} from 'reactstrap';
import _ from 'lodash';
import TipPencil from 'react-icons/lib/ti/pencil';
import { ModalContainer, ModalDialog } from 'react-modal-dialog';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import Panel from './styled/Panel';

import logo from '../assets/shopelect.png';
import ModalForm from './styled/ModalForm';
import FormView from './styled/FormView';
import Api from '../helpers/api';
import states from '../data/states';
import Auth from '../services/Auth';
import AlertTemplate from '../components/AlertTemplate';
import { Menu, Icon, Drawer, Button, message  } from 'antd';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const api = Api();
const auth = new Auth();

export const formatDate = dateArg => {
	const date = new Date(dateArg * 1000);
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear();

	return day + ' ' + monthNames[monthIndex] + ' ' + year;
};

export const getHeaders = () => {
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${auth.getAccessToken()}`,
		'ngrok-skip-browser-warning': 40, 
	};
	return headers;
};

class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      user: this.props.user,
      extra: this.props.extra,
      active: '',
      tab: 0,
      visibleF1: false,
      visibleF2: false,
      visibleF3: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleExtraChange = this.handleExtraChange.bind(this);
    this.handleSalutationChange = this.handleSalutationChange.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handlePlaceOfContactChange = this.handlePlaceOfContactChange.bind(this);
    this.handleGstTreatmentChange = this.handleGstTreatmentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGstSubmit = this.handleGstSubmit.bind(this);
    this.handleAddressSubmit = this.handleAddressSubmit.bind(this);
    this.handlecheckGst = this.handlecheckGst.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.user !== nextProps.user) {
      this.setState({
        user: nextProps.user
      });
    }
    // if (this.props.extra !== nextProps.extra) {
    // 	this.setState({
    // 		extra: nextProps.extra
    // 	});
    // }
  }

  toggleModal(state, active = '') {
    this.setState({
      showModal: state,
      active
    });
  }

  componentDidMount() {
    const { location: { hash } } = this.props;
    if (hash === '#pay') {
      console.log('opening payment form');
      this.paymentForm();
    }
  }

  handlecheckGst() {
    const letters = /^[0-9a-zA-Z]+$/;
    const {tinNo} = _.find(states, (o)=> o.stateCode === this.state.extra.place_of_contact);
    if((this.props.extra.gst_no.substr(0,2) === tinNo) && (this.props.extra.gst_no.substr(2).match(letters))){
      message.success('GSTIN is Valid');
    }else {
      message.error('GSTIN is Invalid');
    }
  }

  handleChange(e) {
    const {value, name} = e.target;
    const user = {...this.state.user};
    user[name] = value;
    this.setState({
      user
    });
  }

  handleAddressChange(e, type) {
    console.log(type);
    const {value, name} = e.target;
    const extra = {...this.state.extra};
    extra[type][name] = value;
    this.setState({
      extra
    });
  }

  handleExtraChange(e) {
    const {value, name} = e.target;
    const extra = {...this.state.extra};
    extra[name] = value;
    this.setState({
      extra
    });
  }

  handleSalutationChange(e) {
    const user = {...this.state.user};
    user.salutation = e.label;
    this.setState({
      user
    });
  }

  handleDepartmentChange(e) {
    const user = {...this.state.user};
    user.department = e.label;
    this.setState({
      user
    });
  }

  handleGstTreatmentChange(e) {
    const extra = {...this.state.extra};
    extra.gst_treatment = e.value;
    this.setState({
      extra
    });
  }

  handlePlaceOfContactChange(e) {
    const extra = {...this.state.extra};
    extra.place_of_contact = e.value;
    this.setState({
      extra
    });
  }

  handleAddressSubmit(e) {
    e.preventDefault();
    const extra = {...this.state.extra};
    const contactPayload = {
      contact_name: extra.contact_name,
      billing_address: extra.billing_address,
      shipping_address: extra.shipping_address
    };
    fetch(`${api.api}/update/contact/${extra.contact_id}?JSONString=${JSON.stringify(contactPayload)}`, {
      headers: getHeaders()
    })
      .then(res => res.json())
      .then((json) => {
        this.props.fetchUserInfo(auth.getContactId());
        if (json.code === 0) {
          this.onClose(3);
          message.success(json.message);
        } else {
          message.error(json.message);
        }
      })
      .catch((error) => {
        throw (error);
      });
    this.toggleModal(false);
  }

  handleGstSubmit(e) {
    e.preventDefault();
    const extra = {...this.state.extra};
    const contactPayload = {
      contact_name: extra.contact_name,
      gst_no: extra.gst_no,
      place_of_contact: extra.place_of_contact,
      gst_treatment: extra.gst_treatment
    };
    fetch(`${api.api}/update/contact/${extra.contact_id}?JSONString=${JSON.stringify(contactPayload)}`, {
      headers: getHeaders()
    })
      .then(res => res.json())
      .then((json) => {
        this.props.fetchUserInfo(auth.getContactId());
        if (json.code === 0) {
          this.onClose(2);
          message.success(json.message);
        } else {
          message.error(json.message);
        }
      })
      .catch((error) => {
        throw (error);
      });
    this.toggleModal(false);
  }

  handleSubmit(e) {
    e.preventDefault();
    const user = {...this.state.user};
    const extra = {...this.state.extra};
    const contactPayload = {
      contact_name: this.props.contactName,
      gst_no: extra.gst_no,
      website: extra.website
    };
    const personPayload = {
      contact_id: user.contact_id,
      salutation: user.salutation,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      phone: user.phone,
      mobile: user.mobile,
      skype: user.skype,
      designation: user.designation,
      department: user.department
    };
    fetch(`${api.api}/update/contact/${extra.contact_id}?JSONString=${JSON.stringify(contactPayload)}`, {
      headers: getHeaders()
    })
      .then(res => res.json())
      .then((json) => {
        this.props.fetchUserInfo(auth.getContactId());
        if (json.code === 0) {
          this.onClose(1);
          message.success(json.message);
        } else {
          message.error(json.message);
        }
      })
      .catch((error) => {
        throw (error);
      });
    fetch(`${api.api}/update/person/${user.contact_person_id}?JSONString=${JSON.stringify(personPayload)}`, {
      headers: getHeaders()
    })
      .then(res => res.json())
      .then((json) => {
        this.props.fetchUserInfo(auth.getContactId());
        if (json.code === 0) {
          this.onClose(1);
          message.success(json.message);
        } else {
          message.error(json.message);
        }
      })
      .catch((error) => {
        throw (error);
      });
    this.toggleModal(false);
  }

  paymentForm = async (e, callback) => {
    if (e) {
      e.preventDefault();
    }

    const contact_id = auth.getContactId();

    const response = await fetch(`${api.api}/subscription/create`, {
      method: "POST",
      body: JSON.stringify({
        plan: {
          plan_code: 'api-plan'
        },
        customer_id: contact_id,
      }),
      headers: {
        'content-type': 'application/json',
        'ngrok-skip-browser-warning': 40, 
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log('subscription creation response: ', res);
        return res;
      });

      this.setState({ response }, () => {
        callback();
      });
  }

  handleClick = (e) => {
    console.log(e)
    if(e.key == 'sub2') {
      this.paymentForm(false,() => {
        this.setState({
          tab:e.key == 'sub1' ? 0 : 1
        });
      })
    } else {
      this.setState({
        tab:e.key == 'sub1' ? 0 : 1
      });
    }
   
  }

  showDrawer = (form) => {
    if(form == 1) {
      this.setState({
        visibleF1: true,
      }) 
    } else if(form == 2) {
      this.setState({
        visibleF2: true,
      }) 
    } else {
      this.setState({
        visibleF3: true,
      }) 
    }
    
  }

  onClose = (form) => {
    if(form == 1) {
      this.setState({
        visibleF1: false,
      }) 
    } else if(form == 2) {
      this.setState({
        visibleF2: false,
      }) 
    } else {
      this.setState({
        visibleF3: false,
      }) 
    }
  }

  render() {
    const { user, extra: contact } = this.props;
    console.log('contact: ', contact);
    // NOTE: Currently only checking for first subscription
    const paid = (!contact.sub || contact.sub.length === 0) ? false : true;
    const currPlan = paid ? contact.sub[0] : null;

    const gstMapping = {
      business_gst: 'Registered Business - Regular',
      business_registered_composition: 'Registered Business - Composition',
      business_none: 'Unregistered Business',
      consumer: 'Consumer',
      overseas: 'Overseas',
      business_sez: 'Special Economic Zone'
    };
    const shippingAddress = this.props.extra.shipping_address;
    const billingAddress = this.props.extra.billing_address;

    return (
      <div style={{backgroundColor:'#F0F2F5', height:'100vh', width:'100vw', position:'relative', bottom:96}} className="home-container">
      <div style={{marginLeft:120, marginRight:120}} >
        <Menu onClick={this.handleClick} style={{ width: 256, position: 'relative', top: 100, border: 0, 	color: '#7A7B7D', textAlign:'left', fontFamily: 'Roboto',	fontSize: 14,	fontWeight: 500,	lineHeight: 24, backgroundColor:'#F0F2F5' }} mode="vertical">
          <Menu.Item style={{height: 48,	width: 280,	borderRadius: 4,	backgroundColor: '#D8E3EE'}} key="sub1">
            Basic Info & GSTIN <Icon style={{position:'relative', float:'right', top: 15}} type="right" />
          </Menu.Item>
          <Menu.Item style={{height: 48,	width: 280,	borderRadius: 4,	backgroundColor: '#D8E3EE'}} key="sub2">
            Plans & Payments <Icon style={{position:'relative', float:'right', top: 15}} type="right" />
          </Menu.Item>
        </Menu>
          
        { this.state.tab == 0 && 
        (
          <div style={{minHeight: 588,	width: 600,visibility: this.state.tab == 1 ? 'hidden' :'visible', position:'relative', left:310, textAlign:'left',	backgroundColor: '#FFFFFF'}}>
            <label style={{position:'relative', left: 24, top: 24, opacity: 0.5,	color: '#000000',	fontFamily: 'Roboto',	fontSize: 16, fontWeight: 500}}>Basic Info</label>
            <Button onClick={()=> {this.showDrawer(1)}} style={{position:'relative', float:'right', top:24, marginRight:24}} icon="ellipsis"/>
            <p style={{position:'relative', left:24, top:30, color: '#000000',	fontFamily: 'Roboto',	fontSize: 16,	fontWeight: 500}}>{this.state.user.first_name} {this.state.user.last_name}</p>
            <div style={{position:'relative', left:24, top:30}}>
              <BoostrapRow>
                <Col>Email: {this.state.extra.email ? this.state.extra.email : '-' }</Col>
                <Col>Website: {this.state.extra.website ? this.state.extra.website : '-' }</Col>
              </BoostrapRow>
              <BoostrapRow>
                <Col>Phone: {this.state.user.phone ? this.state.user.phone : '-'}</Col>
                <Col>Department: {this.state.user.department ? this.state.user.department : '-'}</Col>
              </BoostrapRow>
              <BoostrapRow>
                <Col>Skype: {this.state.user.skype ? this.state.user.skype : '-'}</Col>
                <Col>Designation: {this.state.user.designation ? this.state.user.designation : '-'}</Col>
              </BoostrapRow>
              <BoostrapRow>
                <Col>Mobile: {this.state.user.mobile ? this.state.user.mobile : '-'}</Col>
              </BoostrapRow>
              <Button onClick={() => {auth.createAccount(this.state.extra.email,(res) => {message.success('Reset Password link has been sent to your email.');})}} style={{position:'relative', top:10}}>Reset Password</Button>
            </div>
            <div style={{position:'relative',top:60, height: 1,	width: 600,	opacity: 0.05,	backgroundColor: '#000000'}}></div>
            <label style={{position:'relative', left: 24, top: 70, opacity: 0.5,	color: '#000000',	fontFamily: 'Roboto',	fontSize: 16, fontWeight: 500}}>GSTINs</label>
            <Button onClick={()=> {this.showDrawer(2)}} style={{position:'relative', float:'right', top:70, marginRight:24}} icon="ellipsis"/>
            <div style={{position:'relative', left:24, top:90}}>
            <p style={{position:'relative', color: '#000000',	fontFamily: 'Roboto',	fontSize: 16,	fontWeight: 500}}>{this.state.extra.contact_name}</p>
              <BoostrapRow>
                <Col>Registered Business: {this.state.extra.gst_treatment ? gstMapping[this.state.extra.gst_treatment] : '-'}</Col>
                <Col>Business GSTIN: {this.state.extra.gst_no ? this.state.extra.gst_no : '-'}</Col>
              </BoostrapRow>
              <BoostrapRow>
                <Col>State: {this.state.extra.place_of_contact ? this.state.extra.place_of_contact : '-'}</Col>
              </BoostrapRow>
              <p style={{position:'relative', top:10, color: '#000000',	fontFamily: 'Roboto',	fontSize: 13,	fontWeight: 500}}>The GST Details provided here shall be used for ShopElect’s billing purpose</p>
            </div>
            <div style={{position:'relative', top:50}}>
              <div style={{position:'relative',top:60, height: 1,	width: 600,	opacity: 0.05,	backgroundColor: '#000000'}}></div>
              <label style={{position:'relative', left: 24, top: 70, opacity: 0.5,	color: '#000000',	fontFamily: 'Roboto',	fontSize: 16, fontWeight: 500}}>Company Information</label>
              <Button onClick={this.showDrawer} style={{position:'relative', float:'right', top:70, marginRight:24}} icon="ellipsis"/>
              <div style={{position:'relative', left:24, top:90}}>
                <BoostrapRow>
                  <Col>Billing Address: {billingAddress.address ? `${billingAddress.address}, ${billingAddress.city}, ${billingAddress.state}, ${billingAddress.country}, ${billingAddress.zip}` : '-' }</Col>
                  <Col>Shipping Address: {shippingAddress.address ? `${shippingAddress.address}, ${shippingAddress.city}, ${shippingAddress.state}, ${shippingAddress.country}, ${shippingAddress.zip}` : ' -'} </Col>
                </BoostrapRow>
              </div>
            </div>
        </div>
        )}
        
        { this.state.tab == 1 &&
        (
          <div style={{height: 320, visibility: this.state.tab == 1 ? 'visible' :'hidden',	width: 600, position:'relative', left:310, textAlign:'left',	backgroundColor: '#FFFFFF'}}>
            {this.state.response.hostedpage &&
              <iframe src={this.state.response.hostedpage.url} width="100%" style={{ minHeight: '80vh' }} />
              }
          </div>
        )
        }
        
      </div>

      <Drawer
          title="My Account Details"
          placement="right"
          width={720}
          closable={true}
          onClose={()=> {this.onClose(1)}}
          visible={this.state.visibleF1}
        >
          <FormView>
                <FormGroup className="row">
                  <Col>
                    <Label> Name </Label>
                    <div className="d-flex justify-content-around ">
                      <div className="mr-auto">
                        <Select
                          value={this.state.user.salutation}
                          options={[
                            {value: 'Mr.', label: 'Mr.'},
                            {value: 'Mrs.', label: 'Mrs.'},
                            {value: 'Ms.', label: 'Ms.'},
                            {value: 'Miss.', label: 'Miss.'},
                            {value: 'Dr.', label: 'Dr.'}
                          ]}
                          searchable={false}
                          clearable={false}
                          name="salutation"
                          onChange={this.handleSalutationChange}
                          className="select-custom"
                        />
                      </div>
                      <div>
                        <Input name="first_name"
                               type="text"
                               value={this.state.user.first_name}
                               placeholder="First Name"
                               onChange={this.handleChange}/>
                      </div>
                      <div className="ml-auto">
                        <Input name="last_name"
                               type="text"
                               value={this.state.user.last_name}
                               placeholder="Last Name"
                               onChange={this.handleChange}/>
                      </div>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Label> Contact </Label>
                  <BoostrapRow>
                    <div className="w-100"></div>
                    <Col>

                      <Input name="phone"
                             type="text"
                             value={this.state.user.phone}
                             placeholder="Phone"
                             onChange={this.handleChange}/>
                    </Col>
                    <Col>
                      <Input name="mobile"
                             type="text"
                             value={this.state.user.mobile}
                             placeholder="Mobile"
                             onChange={this.handleChange}/>
                    </Col>
                  </BoostrapRow>

                </FormGroup>
                <FormGroup className="row">
                  <Col>
                    <Label for="website">Website</Label>
                    <Input name="website"
                           id="website"
                           type="text"
                           value={this.state.extra.website}
                           placeholder="Website"
                           onChange={this.handleExtraChange}/>
                  </Col>
                  <Col>
                    <Label for="skype"> Skype </Label>
                    <Input name="skype"
                           id="skype"
                           type="text"
                           value={this.state.user.skype}
                           placeholder="Skype"
                           onChange={this.handleChange}/>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Col>
                    <Label for="designation"> Designation</Label>
                    <Input name="designation"
                           id="designation"
                           type="text"
                           value={this.state.user.designation}
                           placeholder="Designation"
                           onChange={this.handleChange}/>
                  </Col>
                  <Col>
                    <Label for="dept">Department</Label>
                    <Select name="department"
                           id="dept"
                           value={this.state.user.department}
                           options={[
                            {value: 'Tax', label: 'Tax'},
                            {value: 'Accounts Finance', label: 'Accounts Finance'},
                            {value: 'Travel', label: 'Travel'},
                            {value: 'Purchase', label: 'Purchase'},
                            {value: 'Others', label: 'Others'}
                          ]}
                           placeholder="Department"
                           onChange={this.handleDepartmentChange}/>
                  </Col>
                </FormGroup>

              </FormView>
              <footer>
              <BootStrapButton color="danger"
                               size="lg"
                               onClick={this.handleSubmit}>
                Submit
              </BootStrapButton>
            </footer>
        </Drawer>

        <Drawer
          title="GST Information"
          placement="right"
          width={720}
          closable={true}
          onClose={()=> {this.onClose(2)}}
          visible={this.state.visibleF2}
        >
          <section>
              <FormView>
                <FormGroup>
                  <Label> Registered Business</Label>
                  <Select
                    value={this.state.extra.gst_treatment}
                    options={[
                      {value: 'business_gst', label: 'Registered Business - Regular'},
                      {value: 'business_registered_composition', label: 'Registered Business - Composition'},
                      {value: 'business_none', label: 'Unregistered Business'},
                      {value: 'consumer', label: 'Consumer'},
                      {value: 'overseas', label: 'Overseas'},
                      {value: 'business_sez', label: 'Special Economic Zone'}
                    ]}
                    searchable={false}
                    clearable={false}
                    name="gst_treatment"
                    onChange={this.handleGstTreatmentChange}
                  />

                </FormGroup>
                <FormGroup>
                  <Label> Company Name</Label>
                  <Input name="contact_name"
                         type="text"
                         value={this.state.extra.contact_name}
                         placeholder="Contact Name"
                         onChange={this.handleExtraChange}/>
                </FormGroup>
                {
                  this.state.extra.gst_treatment !== 'overseas' &&
                  <FormGroup>
                    <Label> Select State </Label>
                    <Select
                      value={this.state.extra.place_of_contact}
                      options={states.map(( {stateName, stateCode} )=> ({
                        value: `${stateCode}`,
                        label: `${stateCode} - ${stateName}`
                      }))}
                      searchable={false}
                      clearable={false}
                      name="place_of_contact"
                      onChange={this.handlePlaceOfContactChange}
                    />

                  </FormGroup>
                }
                {
                  this.state.extra.gst_treatment !== 'overseas' && this.state.extra.gst_treatment !== 'business_none' &&
                  <FormGroup>
                    <Label> Business GSTIN </Label>
                    <Input name="gst_no"
                           type="text"
                           maxLength="15"
                           value={this.state.extra.gst_no}
                           placeholder="Business GSTIN"
                           onChange={this.handleExtraChange}/>
                  </FormGroup>
                }

              </FormView>
            </section>
            <footer>
              <BootStrapButton color="danger"
                               size="lg"
                               onClick={this.handleGstSubmit}>
                Submit
              </BootStrapButton>
            </footer>
        </Drawer>

        <Drawer
          title="Company Details"
          placement="right"
          width={720}
          closable={true}
          onClose={()=> {this.onClose(3)}}
          visible={this.state.visibleF3}
        >
          <section>
              <FormView>
                <FormGroup>
                  <Input name="contact_name"
                         type="text"
                         value={this.state.extra.contact_name}
                         placeholder="Contact Name"
                         onChange={this.handleExtraChange}/>
                </FormGroup>
                <FormGroup className="row">
                  <Col>
                    <p><strong>Billing Address</strong></p>
                    <FormGroup>
                      <Input name="address"
                             type="text"
                             value={this.state.extra.billing_address.address}
                             placeholder="Address"
                             onChange={e => this.handleAddressChange(e, 'billing_address')}/>
                    </FormGroup>

                    <FormGroup>
                      <Input name="city"
                             type="text"
                             value={this.state.extra.billing_address.city}
                             placeholder="City"
                             onChange={e => this.handleAddressChange(e, 'billing_address')}/>
                    </FormGroup>
                    <FormGroup>
                      <Input name="state"
                             type="text"
                             value={this.state.extra.billing_address.state}
                             placeholder="State"
                             onChange={e => this.handleAddressChange(e, 'billing_address')}/>
                    </FormGroup>
                    <FormGroup>
                      <Input name="country"
                             type="text"
                             value={this.state.extra.billing_address.country}
                             placeholder="Country"
                             onChange={e => this.handleAddressChange(e, 'billing_address')}/>
                    </FormGroup>
                    <FormGroup>
                      <Input name="zip"
                             type="text"
                             value={this.state.extra.billing_address.zip}
                             placeholder="Zip"
                             onChange={e => this.handleAddressChange(e, 'billing_address')}/>
                    </FormGroup>
                  </Col>
                  <Col>
                    <p><strong>Shipping Address</strong></p>
                    <FormGroup>
                      <Input name="address"
                             type="text"
                             value={this.state.extra.shipping_address.address}
                             placeholder="Address"
                             onChange={e => this.handleAddressChange(e, 'shipping_address')}/>
                    </FormGroup>
                    <FormGroup>
                      <Input name="city"
                             type="text"
                             value={this.state.extra.shipping_address.city}
                             placeholder="City"
                             onChange={e => this.handleAddressChange(e, 'shipping_address')}/>
                    </FormGroup>
                    <FormGroup>
                      <Input name="state"
                             type="text"
                             value={this.state.extra.shipping_address.state}
                             placeholder="State"
                             onChange={e => this.handleAddressChange(e, 'shipping_address')}/>
                    </FormGroup>
                    <FormGroup>
                      <Input name="country"
                             type="text"
                             value={this.state.extra.shipping_address.country}
                             placeholder="Country"
                             onChange={e => this.handleAddressChange(e, 'shipping_address')}/>
                    </FormGroup>
                    <FormGroup>
                      <Input name="zip"
                             type="text"
                             value={this.state.extra.shipping_address.zip}
                             placeholder="Zip"
                             onChange={e => this.handleAddressChange(e, 'shipping_address')}/>
                    </FormGroup>
                  </Col>
                </FormGroup>


              </FormView>
            </section>

            <footer>
              <BootStrapButton color="danger"
                               size="lg"
                               onClick={this.handleAddressSubmit}>
                Submit
              </BootStrapButton>
            </footer>
        </Drawer>

      </div>


    );
  }
}

export default withRouter(MyAccount);
