import React from "react";
import { ArrowRightOutlined, StarFilled } from '@ant-design/icons';
import './stepper.css';
import bilingIllustration from '../../assets/billing_illustration.avif';

const Step1 = ({ handleProceed }) => {

    return (
        <div className="step-container-info">
            <div className="step">
                <h1>Revolutionize Your ITC Journey Welcome Aboard!</h1>
                <div>
                    <p>Ready to put your ITC journey on autopilot?</p>
                    <div className="step-points">
                        <p> <span style={{ marginRight: "10px", color: "#FFD700" }}><StarFilled /></span>Optimise ITC claimed</p>
                        <p> <span style={{ marginRight: "10px", color: "#FFD700" }}><StarFilled /></span>Set payment terms</p>
                        <p> <span style={{ marginRight: "10px", color: "#FFD700" }}><StarFilled /></span>Improve vendor filling behavior</p>
                        <p> <span style={{ marginRight: "10px", color: "#FFD700" }}><StarFilled /></span>Sync with your ERP</p>
                    </div>
                    <button className="gst-button" onClick={handleProceed}>Let's Go  <ArrowRightOutlined style={{ fontSize: '15px', margin: "2px 10px" }} /></button>
                    <p className="insight">It'll Hardly take a minute.</p>
                </div>
            </div>
            <div class="illustration-container">
                <img
                    alt="img"
                    src={bilingIllustration}
                    style={{ width: 'inherit', height: 'inherit' }}
                />
            </div>
        </div>
    )
}
export default Step1;