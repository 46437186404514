import React from 'react';
import { Icon } from 'antd';
import Flex from '../styled/Flex';
import colors from './theme';

const Loader = () => (
	<Flex
		justifyContent="center"
		style={{
			minHeight: 150,
			position: 'absolute',
			top: 1,
			left: 2,
			right: 2,
			bottom: 2,
			zIndex: 999,
			background: colors.white,
		}}
		id="spinner"
		alignItems="center"
	>
		<Icon
			type="loading"
			style={{ fontSize: 32, color: colors.blackTheme }}
			spin
		/>
	</Flex>
);

export default Loader;
