import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Icon } from 'antd';
import Avatar from 'react-avatar';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';
import { connect } from 'react-redux';
import FaEnvelopeO from 'react-icons/lib/fa/envelope-o';

import Auth from '../services/Auth';
import { fetchUserInfo, setSubReport, setCWTRole } from '../actions';

// import logo from '../assets/gstinput.png';
import logo from '../assets/finkraftLogo.svg';

import Navbar from '../components/Navbar?v1';

import Appbase from 'appbase-js';

const auth = new Auth();

// instantiate appbase ref
const esRef = new Appbase({
	app: 'shopelect-dashbord-reports',
	credentials: 'T8RJMNL6n:0a4d11cd-f77b-4410-8186-0897eb795846',
	url: 'https://elasticsearch-eaas-siddharthappbaseio-shopelect-cluster.searchbase.io',
});

const loginRef = new Appbase({
	app: 'gstinput-login-views',
	credentials: 'T8RJMNL6n:0a4d11cd-f77b-4410-8186-0897eb795846',
	url: 'https://elasticsearch-eaas-siddharthappbaseio-shopelect-cluster.searchbase.io',
});

const mapStateToProps = (state, ownProps) => ({
	user: state.user,
	active: ownProps.location.pathname,
	reports: state.reports,
	workspace: state.workspace,
	cwt_role: state.cwt_role,
});

const mapDispatchToProps = dispatch => ({
	fetchUserInfo: contactId => dispatch(fetchUserInfo(contactId)),
	setSubReport: subReport => dispatch(setSubReport(subReport)),
	setCWTRole: val => dispatch(setCWTRole(val)),
});

const setCustomerDomain = (email, cb, setOptionsCb, passCWTRole) => {
	let hostname = window.location.host;
	if (hostname.includes('airlines.finkraft.ai')) {
		hostname = 'airlines.gstinput.com';
	}
	if (hostname.includes('app.finkraft.ai')) {
		hostname = 'airlines.gstinput.com';
	}
	loginRef
		.search({
			body: {
				query: {
					bool: {
						must: [
							{
								term: {
									'Email.keyword': email,
								},
							},
							{
								term: {
									'Domain.keyword': hostname,
								},
							},
						],
					},
				},
			},
		})
		.on('data', res => {
			if (res.hits && res.hits.hits.length > 0) {
				// console.log('user role val: ', res.hits.hits[0]._source);
				if (
					res.hits.hits[0]._source.Login_Domain ===
						'carlsonwagonlit.com' ||
					email.split('@')[1] === 'carlsonwagonlit.com'
				) {
					passCWTRole('cwt'); // dispatch CWT action
				}
				let customer_domain = res.hits.hits[0]._source.Customer_Domain;
				if (
					res.hits.hits.find(e => e._source.Customer_Domain === 'All')
				) {
					// set default customer_domain as user's login domain
					customer_domain = email.split('@')[1];
					setDropdownOptions(email, setOptionsCb);
				} else if (res.hits.hits.length > 1) {
					customer_domain = email.split('@')[1];
					const domains = res.hits.hits.map(
						e => e._source.Customer_Domain,
					);
					setOptionsCb(domains);
				} else {
					setOptionsCb([]);
				}
				// console.log(customer_domain)
				cb(customer_domain);
			} else if (res.hits && res.hits.hits.length === 0) {
				// set default customer_domain as user's login domain
				const customer_domain = email.split('@')[1];
				// console.log(customer_domain)
				cb(customer_domain);
			}
		});
};

const setDropdownOptions = (email, cb) => {
	let hostname = window.location.host;
	if (hostname.includes('airlines.finkraft.ai')) {
		hostname = 'airlines.gstinput.com';
	}
	if (hostname.includes('app.finkraft.ai')) {
		hostname = 'airlines.gstinput.com';
	}
	loginRef
		.search({
			body: {
				size: 0,
				query: {
					term: {
						'Domain.keyword': hostname,
					},
				},
				aggs: {
					DropdownTerms: {
						terms: {
							field: 'Customer_Domain.keyword',
							size: 100,
						},
					},
				},
			},
		})
		.on('data', res => {
			if (
				res.aggregations &&
				res.aggregations.DropdownTerms &&
				res.aggregations.DropdownTerms.buckets.length > 0
			) {
				let dropdown_options = res.aggregations.DropdownTerms.buckets.map(
					item => item.key,
				);
				dropdown_options = dropdown_options.filter(
					item => item !== 'All',
				);
				cb(dropdown_options);
			}
		});
};

class NavbarContainer extends React.Component {
	constructor(props) {
		super(props);
		this.handleMouseHover = this.handleMouseHover.bind(this);
		this.state = {
			dropdownOpen: false,
			adminDropdownOpen: false,
			customerView: '',
			isMenuClicked: true,
			isHovering: false,
		};
		// Dropdown Menu
		this.toggle = this.toggle.bind(this);
		this.adminToggle = this.adminToggle.bind(this);
		this.changeValue = this.changeValue.bind(this);
	}

	handleMouseHover() {
		this.setState(this.toggleHoverState);
	}

	toggleHoverState(state) {
		return {
			isHovering: !state.isHovering,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.user &&
			Object.keys(nextProps.user) &&
			nextProps.user.contact_name !== this.props.user.contact_name
		) {
			this.updateUserData(nextProps);
		}

		if (
			nextProps.customer_domain &&
			nextProps.customer_domain !== this.props.customer_domain
		) {
			this.getMainReport(nextProps, nextProps.customer_domain);
		}
	}

	componentDidUpdate() {
		if (
			this.props.cwt_role === 'cwt' &&
			this.props.history.location.pathname !== '/upload'
		) {
			console.log('history: ', this.props.history);
			this.props.history.push('/upload');
		}
	}

	componentDidMount() {
		this.props.fetchUserInfo(auth.getContactId());
		this.updateUserData();
	}

	updateUserData = (props = this.props) => {
		const { user } = props;
		let contact = {
			first_name: '',
			last_name: '',
			email: '',
			photo_url: '',
			mobile: '',
		};
		if (user.contact_persons) {
			contact = user.contact_persons[0];
			const setStuff = customer_domain => {
				if (!window.location.pathname.startsWith('/reporting/')) {
					props.setCustomerDomain(customer_domain);
				}
				props.setUser(contact.email);

				this.getMainReport(props, customer_domain);
			};
			const setOptions = dropdown_options => {
				props.setDropdownOptions(dropdown_options);
			};
			setCustomerDomain(
				contact.email,
				setStuff,
				setOptions,
				this.props.setCWTRole,
			);
		}
	};

	getMainReport = (props, customer_domain) => {
		// get the main report URL
		esRef
			.search({
				type: '_doc',
				body: {
					query: {
						bool: {
							must: [
								{
									term: {
										'Tab.keyword': 'Main Dashboard',
									},
								},
								{
									term: {
										'Customer_name.keyword': customer_domain,
									},
								},
							],
						},
					},
				},
			})
			.on('data', res => {
				if (
					res &&
					res.hits &&
					res.hits.hits &&
					res.hits.hits.length > 0
				) {
					let mainReportURL = '';
					for (let i = 0; i < res.hits.hits.length; i += 1) {
						if (
							res.hits.hits[i]._source.Report_Name === 'Summary'
						) {
							mainReportURL = res.hits.hits[i]._source.embedurl;
						}
					}
					if (mainReportURL !== '') {
						props.setMainReport(mainReportURL);
					} else if (res.hits.hits[0]) {
						props.setMainReport(res.hits.hits[0]._source.embedurl);
					}
					const sideReports = {};
					for (let i = 0; i < res.hits.hits.length; i += 1) {
						if (
							res.hits.hits[i]._source.Report_Name !==
								'Summary' &&
							res.hits.hits[i]._source.embedurl
						) {
							sideReports[res.hits.hits[i]._source.Report_Name] =
								res.hits.hits[i]._source.embedurl;
						}
					}

					props.setSideReports(sideReports);
				}
			})
			.on('error', err => {
				console.error('Error while fetching main report info', err);
			});
	};

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		});
	}

	menuClick() {
		this.setState({
			isMenuClicked: !this.state.isMenuClicked,
		});
	}

	adminToggle() {
		this.setState({
			adminDropdownOpen: !this.state.adminDropdownOpen,
		});
	}

	changeValue(e) {
		this.setState({
			customerView: e.currentTarget.textContent,
		});
		this.props.setCustomerDomain(e.currentTarget.textContent);
	}

	render() {
		const { dropdown_options, user } = this.props;

		let contact = {
			first_name: '',
			last_name: '',
			email: '',
			photo_url: '',
			mobile: '',
			contact_person_id: '',
		};
		if (user.contact_persons) {
			contact = user.contact_persons[0];
		}

		return (
			<div>
				{!this.state.isMenuClicked && (
					<div className={'menuTop'}>
						<Icon
							onClick={() => {
								this.menuClick();
							}}
							style={{
								fontSize: '21px',
								position: 'relative',
								top: 13,
							}}
							type="menu"
						/>
					</div>
				)}

				{this.state.isMenuClicked && (
					<div className={'headerTop'}>
						<div className="d-flex">
							<div className="brand mr-auto">
								<Link
									className="d-flex align-items-center"
									to="/"
								>
									<img
										src={logo}
										alt="Avatar"
										className="img-fluid py-2 mx-1"
										// style={{ backgroundColor: '#fff' }}
									/>{' '}
								</Link>
								<Navbar
									firstname={contact.first_name}
									lastname={contact.last_name}
									email={contact.email}
									image={contact.photo_url}
									active={this.props.active}
									mobile={contact.mobile}
									subReport={this.props.reports}
									setSubReport={this.props.setSubReport}
									workspace={this.props.workspace}
									cwt_role={this.props.cwt_role}
								/>
							</div>
							{dropdown_options.length ? (
								<div className="d-flex align-items-center">
									<Dropdown
										isOpen={this.state.adminDropdownOpen}
										toggle={this.adminToggle}
									>
										<DropdownToggle
											caret
											className="dropdown-btn"
										>
											Set view: {this.state.customerView}
										</DropdownToggle>
										<DropdownMenu
											modifiers={{
												setMaxHeight: {
													enabled: true,
													order: 890,
													fn: data => {
														return {
															...data,
															styles: {
																...data.styles,
																overflow:
																	'auto',
																maxHeight: 400,
															},
														};
													},
												},
											}}
											right
										>
											{dropdown_options.map(item => (
												<DropdownItem
													key={item}
													onClick={this.changeValue}
												>
													{item}
												</DropdownItem>
											))}
										</DropdownMenu>
									</Dropdown>
									<div />
								</div>
							) : null}
							<div className="app-notification d-flex align-items-center">
								<div className="app-notification-icon">
									<a
										href="mailto:support@shopelect.com"
										title="support@shopelect.com"
									>
										{' '}
										<FaEnvelopeO />{' '}
									</a>
								</div>
								<div />
							</div>
							<div className="d-flex align-items-center">
								<Dropdown
									isOpen={this.state.dropdownOpen}
									toggle={this.toggle}
								>
									<div className="avatar">
										{/* <img
											src={contact.photo_url}
											alt="Avatar"
											width="55"
											className="img-fluid rounded-circle"
										/> */}
										<Avatar name={contact.first_name || "U"} size="50" round="50%" />
									</div>
									<DropdownToggle
										caret
										className="dropdown-btn"
									>
										{contact.first_name} {contact.last_name}
									</DropdownToggle>
									<DropdownMenu className="top-dropdown">
										{/* <DropdownItem disabled>
										{' '}
										User ID: {contact.contact_person_id}
									</DropdownItem> */}
										<DropdownItem>
											<Link to="/myaccount">
												My Account
											</Link>
										</DropdownItem>
										<DropdownItem>
											<span onClick={() => auth.logout()}>
												<Link to="/">Logout</Link>
											</span>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(NavbarContainer));
