import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
// import Intercom from 'react-intercom';
import { Tabs, Spin } from 'antd';
import 'antd/dist/antd.css';

import store from './store';
import history from './helpers/history';
import { setCustomerDomain, setWorkspace, setCWTRole } from './actions';
import Auth from './services/Auth';
import TopbarContainer from './containers/TopbarContainer';
import MainContainer from './components/styled/MainContainer';
import MainView from './components/styled/MainView';
import MyAccountContainer from './containers/MyAccountContainer.js?v3';
import LoginContainer from './containers/LoginContainer.js?v3';
import ReportTableContainer from './containers/ReportTableContainer';
import DMSContainer from './containers/DMSContainer';
import UploadFileContainer from './containers/UploadFileContainer';
import CredContainer from './containers/CredContainer';
import ReconReport from './containers/ReconReport';
import BoxFileManagerContainer from './containers/BoxFileManagerContainer';
import BoxFiles from './components/BoxFiles';
import Export from './components/ExportRedirect';
import BoxFilesPreview from './components/BoxFilePreview';
import ReportTab from './containers/ReportTab';
import Callback from './components/Callback';
import AirlineReports from './components/AirlineReport/AirlineReport.js?v5';
import Flex from './components/styled/Flex';
import { getCurrentWorkspace } from './apis/workspace';
import './App.css';
import * as Cookies from 'js-cookie';

const TabPane = Tabs.TabPane;

const auth = new Auth();

const requireAuth = () => {
	if (auth.loggedIn()) {
		return true;
	}
	return false;
};

export const airlineRoutes = ['/', '/file-preview/:fileId'];

const isAllowedPath = (path, filterCol) => {
	if (path === '/myaccount' || path.indexOf('/file-preview/') > -1) {
		return true;
	}

	let res = true;

	if (airlineRoutes.indexOf(path) === -1 && filterCol === 'Airline') {
		res = false;
	}

	return res;
};

const handleAuthentication = (nextState, replace) => {
	if (/access_token|id_token|error/.test(nextState.location.hash)) {
		auth.handleAuthentication();
	}
};

class App extends React.Component {
	state = {
		loggedin_user: '',
		customer_domain: localStorage.getItem('customer_domain') || '',
		dropdown_options:
			JSON.parse(localStorage.getItem('dropdown_options')) || [],
		main_report: '',
		zoho_workspace: '',
		side_reports: {},
		filterCol: null,
		isFetchingWorkspace: false,
	};

	componentDidMount() {
		store.dispatch(setCustomerDomain(this.state.customer_domain));
		if (
			Cookies.get('accessToken') &&
			Cookies.get('email') &&
			Cookies.get('expiresAt') &&
			Cookies.get('isSSOLogin')
		) {
			localStorage.setItem('access_token', Cookies.get('accessToken'));
			localStorage.setItem('email', Cookies.get('email'));
			localStorage.setItem('expires_at', Cookies.get('expiresAt'));
		}
	}

	setWorkspace = async domain => {
		try {
			this.setState({
				isFetchingWorkspace: true,
			});
			const workspace = await getCurrentWorkspace(domain);
			if (workspace) {
				this.setState({
					filterCol: workspace._source.Filter_Column,
				});
				console.log(workspace._source.Filter_Value);
				if (workspace._source.Filter_Value) {
					this.setState({
						zoho_workspace: workspace._source.Filter_Value,
					});
				}
				store.dispatch(setWorkspace(workspace._source));
			} else {
				this.setState({
					filterCol: null,
				});
				store.dispatch(setWorkspace({}));
			}

			this.setState({
				isFetchingWorkspace: false,
			});
		} catch (error) {
			this.setState({
				isFetchingWorkspace: false,
			});
			console.error('Fetch workspace error', error);
		}
	};

	setUser = loggedin_user => {
		this.setState({ loggedin_user });
	};

	setCustomerDomain = domain => {
		const customer_domain = domain;
		this.setState({ customer_domain });
		store.dispatch(setCustomerDomain(customer_domain));
		localStorage.setItem('customer_domain', customer_domain);
		this.setWorkspace(customer_domain);
	};

	setDropdownOptions = dropdown_options => {
		this.setState({ dropdown_options });
		localStorage.setItem(
			'dropdown_options',
			JSON.stringify(dropdown_options),
		);
	};

	setMainReport = main_report => {
		this.setState({ main_report });
	};

	setSideReports = side_reports => {
		this.setState({ side_reports });
	};

	render() {
		const { filterCol, isFetchingWorkspace } = this.state;
		return (
			<Provider store={store}>
				<div className="App" style={{'height':"100%","width":"100%"}}>
					{/* <Intercom appID="eq2eg25u" /> */}
					<div style={{'height':"100%","width":"100%"}}>
						<Router history={history}>
							<section className="App-holder" style={{'height':"100%","width":"100%"}}>
								<Route
									exact
									path="/login"
									component={LoginContainer}
								/>
								<Route
									exact
									path="/callback"
									render={props => {
										handleAuthentication(props);
										return <Callback {...props} />;
									}}
								/>
								<MainContainer style={{'height':"100%","width":"100%"}}> 
									<Route
										path="/"
										render={props =>
											requireAuth() ? (
												<TopbarContainer
													{...props}
													customer_domain={
														this.state
															.customer_domain
													}
													dropdown_options={
														this.state
															.dropdown_options
													}
													setUser={this.setUser}
													setMainReport={
														this.setMainReport
													}
													setSideReports={
														this.setSideReports
													}
													setCustomerDomain={
														this.setCustomerDomain
													}
													setDropdownOptions={
														this.setDropdownOptions
													}
													loggedin_user={
														this.state.loggedin_user
													}
												/>
											) : (
												<Redirect to="/login" />
											)
										}
									/>
									<MainView style={{'height':"100%","width":"100%"}}>
										{isFetchingWorkspace ? (
											<Flex
												alignItem="center"
												justifyContent="center"
												style={{
													paddingTop: 200,
												}}
											>
												<Spin />
											</Flex>
										) : (
											<div className="container-fluid">
												<Route
													exact
													path="/"
													render={() =>
														isAllowedPath(
															'/',
															filterCol,
														) ? (
															<Tabs
																defaultActiveKey="1"
																tabPosition="top"
															>
																<TabPane
																	tab="Summary"
																	key="1"
																	style={{
																		height:
																			'100vh'
																	}}
																>
																	<iframe
																		title="Summary"
																		className="iframeD"
																		onContextMenu={() => {
																			return false;
																		}}
																		frameBorder="0"
																		src={
																			this
																				.state
																				.main_report
																		}
																		style={{
																			width:
																				'inherit',
																			height:
																				'inherit',
																		}}
																	/>
																</TabPane>
																{Object.keys(
																	this.state
																		.side_reports,
																).map(
																	(e, i) => {
																		return (
																			<TabPane
																				tab={
																					e
																				}
																				key={
																					i +
																					2
																				}
																				style={{
																					height:
																						'100vh'
																				}}
																			>
																				<iframe
																					title="Summary"
																					className="iframeD"
																					onContextMenu={() => {
																						return false;
																					}}
																					frameBorder="0"
																					src={
																						this
																							.state
																							.side_reports[
																							e
																						]
																					}
																					style={{
																						width:
																							'inherit',
																						height:
																							'inherit',
																					}}
																				/>
																			</TabPane>
																		);
																	},
																)}
															</Tabs>
														) : (
															<Redirect to="/" />
														)
													}
												/>

												<Route
													exact
													path="/myaccount"
													render={() =>
														isAllowedPath(
															'/myaccount',
															filterCol,
														) ? (
															<MyAccountContainer />
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/reports/export/:workspace/:table"
													render={props =>
														isAllowedPath(
															'/reports/export/:workspace/:table',
															filterCol,
														) ? (
															<Export
																{...props}
																customer_domain={
																	this.state
																		.customer_domain
																}
																loggedin_user={
																	this.state
																		.loggedin_user
																}
																workspace={
																	this.state
																		.zoho_workspace
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/dms"
													render={props =>
														isAllowedPath(
															'/dms',
															filterCol,
														) ? (
															<DMSContainer
																customer_domain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/reporting"
													render={props =>
														isAllowedPath(
															'/reporting',
															filterCol,
														) ? (
															<ReportTableContainer
																customer_domain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/upload"
													render={props =>
														isAllowedPath(
															'/upload',
															filterCol,
														) ? (
															<UploadFileContainer
																customer_domain={
																	this.state
																		.customer_domain
																}
																loggedin_user={
																	this.state
																		.loggedin_user
																}
																workspace={
																	this.state
																		.zoho_workspace
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/box"
													render={props =>
														isAllowedPath(
															'/box',
															filterCol,
														) ? (
															<BoxFileManagerContainer
																customer_domain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													exact
													path="/reporting/:reportNumber"
													render={props =>
														isAllowedPath(
															'/reporting/:reportNumber',
															filterCol,
														) ? (
															<ReportTab
																{...props}
																customer_domain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												{/* <Route
													path="/"
													render={() =>
														isAllowedPath(
															'/',
															filterCol,
														) ? (
															<AirlineReports
																customerDomain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/> */}
												<Route
													path="/files"
													render={() =>
														isAllowedPath(
															'/files',
															filterCol,
														) ? (
															<BoxFiles
																customerDomain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													path="/creds"
													render={() =>
														isAllowedPath(
															'/creds',
															filterCol,
														) ? (
															<CredContainer
																customerDomain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													path="/recon"
													render={() =>
														isAllowedPath(
															'/recon',
															filterCol,
														) ? (
															<ReconReport
																customerDomain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
												<Route
													path="/file-preview/:fileId"
													render={props =>
														isAllowedPath(
															'/file-preview/:fileId',
															filterCol,
														) ? (
															<BoxFilesPreview
																{...props}
																customerDomain={
																	this.state
																		.customer_domain
																}
															/>
														) : (
															<Redirect to="/" />
														)
													}
												/>
											</div>
										)}
									</MainView>
								</MainContainer>
							</section>
						</Router>
					</div>
				</div>
			</Provider>
		);
	}
}

export default App;
