import React, { useState } from 'react';
import axios from 'axios';
import { Button, Input, Modal, Alert, Form, notification, Select } from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { isValidEmail } from '../../helpers/utils';
import api from '../../helpers/api';

const { Option } = Select;
const { Item } = Form;

const StyledItem = styled(Item)`
	margin-bottom: 5px;
`;

const cronMapper = {
	daily: '0 0 * * *',
	fortnight: '0 0 1,15 * *',
	month: '0 0 1 * *',
};

const ScheduleReport = ({
	query,
	customerDomain,
	defaultEmail,
	currentView,
	index,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [email, setEmail] = useState(defaultEmail);
	const [interval, setInterval] = useState(cronMapper.daily);

	function onClose() {
		setIsOpen(false);
	}

	function toggleModal() {
		setIsOpen(!isOpen);
	}

	function handleIntervalChange(value) {
		setInterval(value);
	}

	async function handleEmail() {
		if (isValidEmail(email)) {
			setIsDownloading(true);
			try {
				await axios.post(
					`${api().api}/scheduler/schedule/export`,
					{
						email,
						senderEmail: defaultEmail,
						currentView,
						indexName: index,
						query: {
							type: '_doc',
							body: {
								query,
							},
						},
						schedule: interval,
					},
					{
						headers: {
							'x-domain': customerDomain,
							'ngrok-skip-browser-warning': 40, 
						},
						timeout: 500000,
					},
				);

				notification.success({
					message: 'Reports scheduled successfully',
				});

				onClose();
				setIsDownloading(false);
			} catch (err) {
				console.error(err);
				notification.error({
					message: 'Error',
					description: 'Unable to schedule, please try again',
				});
				setIsDownloading(false);
			}
		} else {
			notification.error({
				message: 'Error',
				description: 'Invalid email',
			});
		}
	}

	function handleEmailChange(e) {
		setEmail(e.target.value);
	}

	return (
		<>
			<Button
				type="primary"
				icon="clock-circle"
				onClick={toggleModal}
				style={{ marginLeft: 5 }}
			>
				Schedule Reports
			</Button>
			<Modal
				title="Schedule Reports"
				visible={isOpen}
				onOk={handleEmail}
				onCancel={onClose}
				okText="Save"
				okButtonProps={{
					disabled: isDownloading,
					loading: isDownloading,
				}}
			>
				<StyledItem label="Email">
					<Input
						type="email"
						placeholder="Enter email"
						value={email}
						onChange={handleEmailChange}
					/>
				</StyledItem>

				<StyledItem label="Interval">
					<Select
						value={interval}
						onChange={handleIntervalChange}
						style={{ width: '100%' }}
					>
						<Option value={cronMapper.daily}>Every day</Option>
						<Option value={cronMapper.fortnight}>
							Every 15 days{' '}
						</Option>
						<Option value={cronMapper.month}>Every month</Option>
					</Select>
				</StyledItem>

				<Alert
					message="File link with all the records will be emailed to recipient entered above at the configured interval."
					type="info"
					showIcon
					style={{ marginTop: 10 }}
				/>
			</Modal>
		</>
	);
};

const mapStateToProps = state => ({
	defaultEmail: window.localStorage.getItem('email'),
	customerDomain: state.customerDomain,
});
export default connect(mapStateToProps)(ScheduleReport);