import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
	Table,
	Button,
	Popconfirm,
	message,
	Switch,
	Divider,
	Input,
	Modal,
	Alert,
	Select,
	Form,
} from 'antd';
import api from '../../helpers/api';
import styled from 'styled-components';
import { isValidEmail } from '../../helpers/utils';

const { Option } = Select;
const { Item } = Form;
const cron_parser = require('cron-parser');

const cronMapper = {
	daily: '0 0 * * *',
	fortnight: '0 0 1,15 * *',
	month: '0 0 1 * *',
};

const StyledItem = styled(Item)`
	margin-bottom: 5px;
`;

const wordWrap = text => <div style={{ wordWrap: 'break-word' }}>{text}</div>;

const deleteSchedule = (appbase_id, cron_job_id) => {
	message.success('Schedule deleted successfully');
	axios.delete(
		`${api().api}/scheduler/schedules/email/${appbase_id}/${cron_job_id}`,
	);
};

const disableSchedule = cron_job_id => {
	message.success('Schedule disabled successfully');
	axios.get(`${api().api}/scheduler/schedules/email/disable/${cron_job_id}`);
};

const enableSchedule = cron_job_id => {
	message.success('Schedule enabled successfully');
	axios.get(`${api().api}/scheduler/schedules/email/enable/${cron_job_id}`);
};

const editSchedule = (appbase_id, cron_id, email, schedule) => {
	axios.post(`${api().api}/scheduler/schedules/email/edit`, {
		appbase_id,
		cron_id,
		email,
		schedule,
	});
};

const Columns = (deleteRow, editRow) => [
	{
		title: 'Receiver Email',
		dataIndex: 'receiverEmail',
		key: 'receiverEmail',
		render: wordWrap,
	},
	{
		title: 'Scheduled By',
		dataIndex: 'senderEmail',
		key: 'senderEmail',
		render: wordWrap,
	},
	{
		title: 'Last Sent Date',
		dataIndex: 'lastSchedule',
		key: 'lastSchedule',
	},
	{
		title: 'Next Scheduled Date',
		key: 'nextSchedule',
		dataIndex: 'nextSchedule',
	},
	{
		title: 'View Name',
		key: 'currentView',
		dataIndex: 'currentView',
	},
	{
		title: 'Action',
		key: 'action',
		render: (text, record) => (
			<span style={{'white-space': 'nowrap'}}>
				<Button
					size="small"
					type="primary"
					shape="circle"
					icon="edit"
					onClick={e => editRow(record)}
				/>
				<Divider type="vertical" />
				<Popconfirm
					title="Are you sure delete this schedule?"
					onConfirm={e => {
						deleteSchedule(record.appbase_id, record.cron_job_id);
						deleteRow(record.appbase_id);
					}}
					onCancel={e => message.success('Delete action declined')}
					okText="Yes"
					cancelText="No"
				>
					<Button
						type="primary"
						size="small"
						shape="circle"
						icon="delete"
					/>
				</Popconfirm>
				<Divider type="vertical" />
				<Switch
					checkedChildren="On"
					size="small"
					shape="circle"
					unCheckedChildren="Off"
					onChange={enabled =>
						(enabled ? enableSchedule : disableSchedule)(
							record.cron_job_id,
						)
					}
					defaultChecked={record.enabled}
				/>
			</span>
		),
	},
];

const ScheduleReport = ({ record, updateRow, customerDomain }) => {
	const [isOpen, setIsOpen] = useState(true);
	const [email, setEmail] = useState(record.receiverEmail);
	const [interval, setInterval] = useState(cronMapper.daily);

	function onClose() {
		setIsOpen(false);
	}

	function handleIntervalChange(value) {
		setInterval(value);
	}

	function handleEmail() {
		if (!isValidEmail(email)) {
			message.error({
				message: 'Error',
				description: 'Invalid email',
			});
		} else {
			message.success({
				message: 'Schedule got updated successfully',
			});
			updateRow({
				...record,
				receiverEmail: email,
				lastSchedule: cron_parser
					.parseExpression(interval)
					.prev()
					.toString(),
				nextSchedule: cron_parser
					.parseExpression(interval)
					.next()
					.toString(),
			});
			editSchedule(
				record.appbase_id,
				record.cron_job_id,
				email,
				interval,
			);
			onClose();
		}
	}

	function handleEmailChange(e) {
		setEmail(e.target.value);
	}

	return (
		<>
			<Modal
				title="Edit Schedule"
				visible={isOpen}
				onOk={handleEmail}
				onCancel={onClose}
				okText="Save"
			>
				<StyledItem label="Email">
					<Input
						type="email"
						placeholder="Enter email"
						value={email}
						onChange={handleEmailChange}
					/>
				</StyledItem>

				<StyledItem label="Interval">
					<Select
						value={interval}
						onChange={handleIntervalChange}
						style={{ width: '100%' }}
					>
						<Option value={cronMapper.daily}>Every day</Option>
						<Option value={cronMapper.fortnight}>
							Every 15 days{' '}
						</Option>
						<Option value={cronMapper.month}>Every month</Option>
					</Select>
				</StyledItem>

				<Alert
					message="File link with all the records will be emailed to recipient entered above at the configured interval."
					type="info"
					showIcon
					style={{ marginTop: 10 }}
				/>
			</Modal>
		</>
	);
};

class ScheduledEmails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			editRow: null,
		};
		this.updateDeleteRow = this.updateDeleteRow.bind(this);
		this.updateEditState = this.updateEditState.bind(this);
		this.setNewRow = this.setNewRow.bind(this);
		this.columns = Columns(this.updateDeleteRow, this.updateEditState);
	}

	componentWillMount() {
		this.callSchedules();
		return null;
	}

	callSchedules() {
		axios
			.get(`${api().api}/scheduler/schedules/email`, {
				headers: {
					'x-domain': this.props.customerDomain,
				},
			})
			.then(response =>
				this.updateDataState(
					response.data.length !== 0 ? response.data : null,
				),
			)
			.catch(error => console.log(error));
	}

	updateDataState(data) {
		this.setState({ data });
	}

	updateEditState(editRow) {
		this.setState({ editRow });
	}

	updateDeleteRow(key) {
		const newData = this.state.data.filter(row => row.appbase_id !== key);
		this.updateDataState(newData.length !== 0 ? newData : null);
	}

	setNewRow(record) {
		this.setState({
			data: this.state.data.map(row =>
				row.appbase_id !== record.appbase_id ? row : record,
			),
			editRow: null,
		});
	}

	render() {
		const { data, editRow } = this.state;
		const viewTable =
			data !== null ? (
				<Table
					rowKey={record => record.appbase_id}
					columns={this.columns}
					dataSource={data}
					loading={data.length === 0}
				/>
			) : (
				<h3>Not yet schedule any emails.</h3>
			);
		const editModel =
			editRow !== null ? (
				<ScheduleReport
					record={editRow}
					updateRow={this.setNewRow}
					customerDomain={this.props.customerDomain}
				/>
			) : null;
		return (
			<div
				style={{
					textAlign: 'left',
					position: 'relative',
				}}
			>
				<h2>Scheduled Emails</h2>
				{editModel}
				{viewTable}
			</div>
		);
	}
}
const mapStateToProps = state => ({
	customerDomain: state.customerDomain,
});
export default connect(mapStateToProps)(ScheduledEmails);