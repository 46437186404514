import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { fetchAllDocTypes } from '../actions';

import PropTypes from 'prop-types';
import { List } from 'antd';
import './style.css';

class LeftColumn extends Component {
	constructor(props) {
		super(props);
		props.fetchAllDocTypes(this.props.customer_domain);
	}

	openReport = name => () => {
		const url = `${window.location.origin}/2a-recon/uploadReport?docType=${name}`;
		const win = window.open(url, '_blank');
		win.focus();
	};

	openGst2a = () => {
		const { workspace, email } = this.props;
		const url = `${window.location.origin}/2a-recon/excelJson/${
			workspace ? workspace + '/' : ''
		}${email}/`;
		const win = window.open(url, '_blank');
		win.focus();
	};

	render() {
		const { staticClick, listColor, doctype } = this.props;

		console.log('props', this.props);
		return (
			<div
				style={{
					height: 'calc(100vh - 100px)',
					overflowY: doctype.docs.length ? 'scroll' : 'auto',
				}}
			>
				<List
					header={
						<div
							style={{
								textAlign: 'left',
								fontWeight: 'bold',
								textTransform: 'uppercase',
							}}
						>
							Documents
						</div>
					}
					style={{ backgroundColor: '#eee' }}
					bordered
				>
					<List.Item
						style={{
							color: listColor[0],
							cursor: 'pointer',
							fontWeight:
								listColor[0] === '#767676' ? 'normal' : 'bold',
						}}
						onClick={staticClick.bind(this, 0)}
					>
						<div>Bookings</div>
					</List.Item>
					<List.Item
						style={{
							color: listColor[1],
							cursor: 'pointer',
							fontWeight:
								listColor[1] === '#767676' ? 'normal' : 'bold',
						}}
						onClick={staticClick.bind(this, 1)}
					>
						<div>Purchase Excel</div>
					</List.Item>
					<List.Item
						style={{
							color: listColor[2],
							cursor: 'pointer',
							fontWeight:
								listColor[2] === '#767676' ? 'normal' : 'bold',
						}}
						onClick={this.openGst2a}
					>
						<div>GSTR-2A</div>
					</List.Item>
					<List.Item
						style={{
							color: listColor[3],
							cursor: 'pointer',
							fontWeight:
								listColor[3] === '#767676' ? 'normal' : 'bold',
						}}
						onClick={staticClick.bind(this, 3)}
					>
						<div>BTA</div>
					</List.Item>
					<List.Item
						style={{
							color: listColor[4],
							cursor: 'pointer',
							fontWeight:
								listColor[4] === '#767676' ? 'normal' : 'bold',
						}}
						onClick={staticClick.bind(this, 4)}
					>
						<div>Other Invoices</div>
					</List.Item>
					<List.Item
						style={{
							color: listColor[5],
							cursor: 'pointer',
							fontWeight:
								listColor[5] === '#767676' ? 'normal' : 'bold',
						}}
						onClick={staticClick.bind(this, 5)}
					>
						<div>Travel Agency Invoices</div>
					</List.Item>
					<List.Item
						style={{
							color: listColor[6],
							cursor: 'pointer',
							fontWeight:
								listColor[6] === '#767676' ? 'normal' : 'bold',
						}}
						onClick={staticClick.bind(this, 6)}
					>
						<div>Airlines Invoice</div>
					</List.Item>
					<List.Item
						style={{
							color: listColor[7],
							cursor: 'pointer',
							fontWeight:
								listColor[7] === '#767676' ? 'normal' : 'bold',
						}}
						onClick={staticClick.bind(this, 7)}
					>
						<div>Hotel Invoices</div>
					</List.Item>
					{doctype.docs.length > 0 && (
						<List.Item
							style={{ cursor: 'auto', fontWeight: 'bold' }}
						>
							<div>Custom Documents &rarr;</div>
						</List.Item>
					)}
					{doctype.docs.map((doc, index) => (
						<List.Item
							style={{
								cursor: 'pointer',
								marginLeft: '10px',
							}}
							key={index}
						>
							<div
								style={{
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
								}}
								onClick={this.openReport(doc.value)}
								title={doc.label}
							>
								&diams;&nbsp; {doc.label}
							</div>
						</List.Item>
					))}
				</List>
			</div>
		);
	}
}

// Adding propType definitions for type checking.
LeftColumn.propTypes = {
	staticClick: PropTypes.func.isRequired,
	listColor: PropTypes.arrayOf(PropTypes.string),
	workspace: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	customer_domain: PropTypes.string.isRequired,
};

const mapStateToProps = ({ doctype }) => ({ doctype });
export default connect(mapStateToProps, { fetchAllDocTypes })(
	withRouter(LeftColumn),
);
