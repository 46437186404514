import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { ImageCard } from './ImageCard';
import { IMAGES_URL } from '../../../../constants/Images';

export class RegistrationDetail extends Component {
	render() {
		return (
			<div className="slider-content">
				{this.props.screenFlag ? (
					<Row className="marginTop-10">
						<Col className="Reg_text_h1">
							Finkraft- Services We Offer
						</Col>
						<br />
						<Col
							style={{ fontSize: 17 }}
							className="Reg_text_h2 marginTop-10"
						>
							<ul>
								<li>5 Way Reconciliation</li>
								<li>AI Bot for Extracting Invoice Data</li>
								<li>
									Trigger Email of Missing / Incorrect
									invoices to Travel agent & Airlines
								</li>
								<li>Provide Online Dashboard and Reports</li>
								<li>
									Checking of the invoice data as per the GST
									Checklist provided by Delloite/EY
								</li>
								<li>
									Give GSTR2 Excel as per GSP/ Web portal
									format
								</li>
								<li>
									Customised reports and CSV for integration
									with SAP/Oracle etc for accounting
								</li>
								<li>Document Management System</li>
							</ul>
						</Col>
						<br />
					</Row>
				) : (
					<Row>
						<Col className="Reg_text_h1">
							Top features of Finkraft
						</Col>
						<Col>
							<div style={{ width: 480 }}>
								<Row type="flex">
									<Col span={7}>
										<ImageCard
											value={{
												title: 'Auto Email Reminders',
												img_url:
													IMAGES_URL.NOTIFICATION,
											}}
										/>
									</Col>
									<Col span={7} offset={1}>
										<ImageCard
											value={{
												title: 'Rich dashboard',
												img_url: IMAGES_URL.LAYER,
											}}
										/>
									</Col>
									<Col span={7} offset={1}>
										<ImageCard
											value={{
												title: '5 Way Reconciliation',
												img_url: IMAGES_URL.MENU,
											}}
										/>
									</Col>
								</Row>
								<Row type="flex" style={{ marginTop: -20 }}>
									<Col span={7}>
										<ImageCard
											value={{
												title: 'Extracting Data',
												img_url: IMAGES_URL.BOOK,
											}}
										/>
									</Col>
									<Col span={7} offset={1}>
										<ImageCard
											value={{
												title: 'AI Bot',
												img_url: IMAGES_URL.WEB,
											}}
										/>
									</Col>
									<Col span={7} offset={1}>
										<ImageCard
											value={{
												title: ' Validation',
												img_url: IMAGES_URL.CHECK,
											}}
										/>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default RegistrationDetail;
