import auth0 from 'auth0-js';
import history from '../helpers/history';
import Api from '../helpers/api';
import * as Cookies from 'js-cookie';

var request = require("request");

const api = Api();

export default class Auth {
	auth0 = new auth0.WebAuth({
		domain: 'shopelect.auth0.com',
		clientID: 'Ppfj1JyyG8smHdYi2R1gPqSSCALD3F0L',
		redirectUri: api.callback,
		audience: 'https://shopelect.auth0.com/api/v2/',
		responseType: 'token',
	});

	sendMail(email) {
		this.auth0.passwordlessStart(
			{
				connection: 'email',
				send: 'code',
				email,
			},
			(err, res) => {
				console.log(res);
			},
		);
	}

	createAccount(_email, callback) {
		var options = { method: 'POST',
		url: 'https://shopelect.auth0.com/dbconnections/signup',
		headers: { 'content-type': 'application/json' },
		body: 
		{ 	client_id: 'Ppfj1JyyG8smHdYi2R1gPqSSCALD3F0L',
			email: _email,
			password: '#g911URiyxfN',
			connection: 'Username-Password-Authentication'
		},
		json: true };

		request(options, function (error, response, body) {
			options.url = 'https://shopelect.auth0.com/dbconnections/change_password';
			delete options.body.password;
			request(options, function (error, response, body) {
				callback(true);
			});
		});
	}

	sendSms(phoneNumber) {
		this.auth0.passwordlessStart(
			{
				connection: 'sms',
				send: 'code',
				phoneNumber: `+${phoneNumber}`,
			},
			(err, res) => {
				console.log(res);
			},
		);
	}

	basicAuth(email,password, callback) {
		this.auth0.login({
			realm: 'Username-Password-Authentication',
			email: email,
			password: password,
		  },
		  (err, res) => {
			console.log(err);
			callback(err)
		});
	}

	login(email, code, showError) {
		localStorage.setItem('email', email);
		this.auth0.passwordlessVerify(
			{
				connection: 'email',
				verificationCode: code,
				email,
			},
			(err, res) => {
				if (err.statusCode === 400) {
					this.basicAuth(email, code, (err1) => {
						if (err1.error === 'access_denied') {
							showError('Invalid code/password entered');
						}
					});
				}
				console.log(err, res);
			},
		);
	}

	loginWithSms(phoneNumber, code, showError) {
		localStorage.setItem('phoneNumber', phoneNumber);
		this.auth0.passwordlessVerify(
			{
				connection: 'sms',
				verificationCode: code,
				phoneNumber: `+${phoneNumber}`,
			},
			(err, res) => {
				if (err.statusCode === 400) {
					showError('Invalid code entered');
				}
				console.log(res);
			},
		);
	}

	handleAuthentication() {
		this.auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken) {
				this.setSession(authResult);
				history.replace('/');
			} else if (err) {
				history.replace('/');
				console.log(err);
			}
		});
	}

	setSession(authResult) {
		// Set the time that the access token will expire at
		const expiresAt = JSON.stringify(
			authResult.expiresIn * 1000 + new Date().getTime(),
		);
		localStorage.setItem('access_token', authResult.accessToken);
		// localStorage.setItem('id_token', authResult.idToken);
		localStorage.setItem('expires_at', expiresAt);
		// navigate to the home route
		history.replace('/');
	}

	setContactId(contactId) {
		localStorage.setItem('contactId', contactId);
	}

	getContactId() {
		return localStorage.getItem('contactId');
	}

	logout() {
		localStorage.clear();
    Cookies.remove('accessToken', {
      domain: '.finkraft.ai',
    });
    Cookies.remove('email', {
      domain: '.finkraft.ai',
    });
    Cookies.remove('expiresAt', {
      domain: '.finkraft.ai',
    });
    Cookies.remove('isSSOLogin', {
      domain: '.finkraft.ai',
    });
		history.replace('/');
	}

	getAccessToken() {
		const accessToken = localStorage.getItem('access_token');
		if (!accessToken) {
			throw new Error('No access token found');
		}
		return accessToken;
	}

	isAuthenticated() {
		// Check whether the current time is past the
		// access token's expiry time
		const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
		return new Date().getTime() < expiresAt;
	}

	loggedIn() {
		// Checks if there is a saved token and it's still valid
		const token = localStorage.getItem('access_token');
		return !!token && this.isAuthenticated();
	}
}
