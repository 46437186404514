import React, { Component } from 'react';
import Appbase from 'appbase-js';
import '../App.css';

const esRef = new Appbase({
	app: 'shopelect-airlines-reports',
	credentials: 'T8RJMNL6n:0a4d11cd-f77b-4410-8186-0897eb795846',
	url: 'https://elasticsearch-eaas-siddharthappbaseio-shopelect-cluster.searchbase.io',
});

class BoxFileManagerContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			customer_domain: props.customer_domain,
		};
	}

	componentDidMount() {
		this.getIframe(this.props.customer_domain);
	}

	getIframe = customer_domain => {
		if (!customer_domain) return;
		esRef
			.search({
				type: 'shopelect-airlines-reports',
				body: {
					query: {
						bool: {
							must: [
								{
									term: {
										'Customer_Domain.raw': customer_domain,
									},
								},
								{
									term: {
										'Tab.raw': 'Invoice',
									},
								},
							],
						},
					},
				},
			})
			.on('data', res => {
				if (res.hits.hits) {
					this.setState({
						url: res.hits.hits[0]._source.URLs,
					});
				}
			})
			.on('error', err => {
				console.error('Error while fetching URL', err);
			});
	};

	render() {
		return (
			<div>
				<iframe
					title="Box"
					src={this.state.url}
					width="100%"
					height="1000"
					frameborder="0"
					allowfullscreen
					webkitallowfullscreen
					msallowfullscreen
				/>
			</div>
		);
	}
}

export default BoxFileManagerContainer;
