import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App.js?v9';
// import { unregister } from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// if (process.env.NODE_ENV !== 'development') {
// 	unregister();
// }

document.oncontextmenu = function() {
	return false;
};

if (module.hot) {
	module.hot.accept('./App', () => {
		const Hot = require('./App').default; // eslint-disable-line global-require
		ReactDOM.render(<Hot />, document.getElementById('root'));
	});
}
