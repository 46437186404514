import React,{ useCallback, memo } from "react";
import styles from './textLinkRenderer.module.css';


const TextLinkRenderer = (params) => {
  const {value, handleClick, label} = params;

  const onLinkClick = useCallback((e) => {
    e.stopPropagation();
    handleClick(params);
  }, [handleClick, params])

  return (
    <div onClick={onLinkClick} title={value}>
      <div role="button" className={`link-primary text-truncate ${styles.textLink}`}>{label}</div>
    </div>
  )
}

export default memo(TextLinkRenderer);