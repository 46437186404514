export const tableQueryGenerator = (tableName, termValue, termColumn) =>
	termValue === 'Admin'
		? {
				query: {
					bool: {
						must: [
							{
								term: {
									'tableName.keyword': tableName,
								},
							},
						],
					},
				},
		  }
		: tableName
		? {
				query: {
					bool: {
						must: [
							{
								term: {
									[termColumn]: termValue,
								},
							},
							{
								term: {
									'tableName.keyword': tableName,
								},
							},
						],
					},
				},
		  }
		: {
				query: {
					bool: {
						must: [
							{
								term: {
									[termColumn]: termValue,
								},
							},
						],
					},
				},
		  };
