import React, { useState } from 'react';
import Stepper from '@keyvaluesystems/react-vertical-stepper';
import './stepper.css';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const initialStepsArr = [
	{
		label: 'Hey! Welcome',
		description: "Let's begin!",
		isCompleted: false,
	},
	{
		label: 'Add Business',
		description: 'Enter Creds to get started',
		isCompleted: false,
	},
	{
		label: 'All Set, Relax!',
		description: 'Fetching 2A, 2B and 3B',
		isCompleted: false,
	},
];

export default function StepperProgress(props) {
	const [currentStep, setCurrentStep] = useState(0);
	console.log(currentStep, 'currentStep');

	const handleStepClick = (step, index) => setCurrentStep(index);
	const handleProceed = () => setCurrentStep(currentStep + 1);

	return (
		<div className="step-container">
			<div className="stepper-body">
				<div className="siteBody">
					<div className="sideBar">
						<div className="stepSummary">
							<Stepper
								steps={initialStepsArr}
								currentStepIndex={currentStep}
								labelPosition="right"
								styles={{
									LineSeparator: (step, index) => ({
										height: '80px',
									}),
								}}
							/>
						</div>
					</div>
					<div className="mainSection">
						{currentStep === 0 && (
							<Step1 handleProceed={handleProceed} />
						)}
						{currentStep === 1 && (
							<Step2 handleProceed={handleProceed} customerDomain={props.customerDomain} />
						)}
						{currentStep === 2 && (
							<Step3 handleProceed={handleProceed} />
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
