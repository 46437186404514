import React from 'react';
import Appbase from 'appbase-js';
import { message } from 'antd';
import Cookies from 'universal-cookie';

const esRef = new Appbase({
	app: 'shopelect-dashbord-reports',
	credentials: 'T8RJMNL6n:0a4d11cd-f77b-4410-8186-0897eb795846',
	url:
		'https://elasticsearch-eaas-siddharthappbaseio-shopelect-cluster.searchbase.io',
});

const cookies = new Cookies();
class ReportTab extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url: null,
		};
	}

	componentDidMount() {
		this.getReport(this.props.customer_domain);
		this.interval = setInterval(() => {
			if(cookies.get('emailGen')){
				message.success(`An Email will be sent once the file is ready. Please check your inbox`,1);
				cookies.remove('emailGen')
			}
		}, 2000);
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.customer_domain &&
			nextProps.customer_domain !== this.props.customer_domain
		) {
			console.log('setting customer domain: ', nextProps.customer_domain);
			this.getReport(nextProps.customer_domain);
		}
	}

	getReport = customer_domain => {
		if (!customer_domain) return;
		esRef
			.search({
				body: {
					query: {
						bool: {
							must: [
								{
									term: {
										'Report_Number.keyword': this.props
											.match.params.reportNumber,
									},
								},
								{
									term: {
										'Customer_name.keyword': customer_domain,
									},
								},
							],
						},
					},
				},
			})
			.on('data', res => {
				if (res.hits.hits) {
					this.setState({
						url: res.hits.hits[0]._source.embedurl,
					});
				}
			})
			.on('error', err => {
				console.error('Error while fetching URL', err);
			});
	};

	render() {
		return (
			this.state.url && (
				<div className="iframe-container">
					<iframe
						title="Summary"
						className="iframeD"
						onContextMenu={() => {
							return false;
						}}
						frameBorder="0"
						width="100%"
						height="100%"
						src={this.state.url}
					/>
				</div>
			)
		);
	}
}

export default ReportTab;
