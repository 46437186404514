import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';

import Flex from '../styled/Flex';
import InvoiceStatus from '../InvoiceStatus';
import CommentsPopup from '../CommentsPopup';
import UploadInvoice from '../UploadInvoice';
import { convertToMax } from '../../helpers/utils';

class IdField extends Component {
	state = {
		isUploaded: Boolean(this.props.data.uploadedInvoice),
		fileId: (this.props.data.uploadedInvoice || {}).box_file_id || '',
	};

	handleChangeState = fileId => {
		if (fileId) {
			this.setState({
				isUploaded: true,
				fileId,
			});
		}
	};

	render() {
		const {
			rowIndex,
			value,
			stats,
			pageSize,
			data,
			currentView,
			logData,
		} = this.props;
		const currentPage = (stats.currentPage || 0) + 1;
		const { isUploaded, fileId } = this.state;
		return (
			<Flex wrap="nowrap" style={{ width: '100%' }} alignItems="center">
				<div>
					{convertToMax(
						pageSize * (currentPage - 1) + (rowIndex + 1),
						pageSize * (currentPage - 1) + pageSize,
					)}
				</div>
				<Flex
					wrap="nowrap"
					alignItems="center"
					style={{
						marginLeft: '10px',
					}}
				>
					<InvoiceStatus
						docId={value}
						defaultValue={data.Status}
						currentView={currentView}
						logData={logData}
					/>
					<CommentsPopup
						docId={value}
						comments={data.comments || []}
						currentView={currentView}
						logData={logData}
					/>
					{!isUploaded ? (
						<UploadInvoice
							data={data}
							docId={value}
							currentView={currentView}
							logData={logData}
							handleChangeState={this.handleChangeState}
						/>
					) : (
						<Link to={`/file-preview/${fileId}`} target="_blank">
							<Icon
								type="file-text"
								style={{
									cursor: 'pointer',
									marginLeft: 10,
									fontSize: 20,
									color: '#1890ff',
								}}
							/>
						</Link>
					)}
				</Flex>
			</Flex>
		);
	}
}

IdField.defaultProps = {
	data: {},
};

export default IdField;
