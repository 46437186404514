import React, { useState } from 'react';
import { Modal, Upload, message, Tooltip, Button, Icon } from 'antd';
import api from '../../helpers/api';

const UploadInvoice = ({ data }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	function closeAndReset() {
		setIsModalOpen(false);
	}

	const props = {
		name: 'file',
		multiple: true,
		action: `${api().api}/s3/upload`,
		data: {
			airline_name: data.Filter_Value || 'No_Vendor',
			AirlineName: data.Filter_Value || 'No_Vendor',
			customer_domain: 'Airline_API_Uploads',
			uploader_email: window.localStorage.getItem('email'),
			customer_folder_id: '70485021722',
		},
		onChange(info) {
			const status = info.file.status;
			if (status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (status === 'done') {
				message.success(
					`${info.file.name} file uploaded successfully.`,
				);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
	};
	return (
		<>
			<Tooltip title="Add new Invoice">
				<Button
					type="primary"
					icon="cloud-upload"
					style={{
						marginLeft: 5,
					}}
					onClick={() => {
						setIsModalOpen(!isModalOpen);
					}}
				>
					Upload Invoices
				</Button>
			</Tooltip>
			<Modal
				title="Upload Invoice"
				visible={isModalOpen}
				onCancel={closeAndReset}
				footer={null}
			>
				<Upload.Dragger {...props}>
					<p className="ant-upload-drag-icon">
						<Icon type="inbox" />
					</p>
					<p className="ant-upload-text">
						Click or drag file to this area to upload
					</p>
					<p className="ant-upload-hint">
						Support for a single or bulk upload.
					</p>
				</Upload.Dragger>
			</Modal>
		</>
	);
};

export default UploadInvoice;
