import React, { useState, useEffect, useRef, memo } from 'react';
import Tippy from '@tippyjs/react';
// import MessageModal from '../../messageModal';

import { COMPARED_COLUMNS, MATCHING_EXCLUDED_COLUMNS } from '../../../constants/columns';
import { MATCH_STATUSES } from '../../../constants/matchStatuses';
import { getGroupLevelEmailMessage } from './statusComponent.helpers';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleFilled';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleFilled';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleFilled';

import styles from './statusComponent.module.css';

const StatusComponent = (props) => {
  const [isMissing, setIsMissing] = useState(false);
  const [isMismatch, setIsMismatch] = useState(false);
  const [textMsg, setTextMsg] = useState('');
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const { data, column } = props;

  useEffect(() => {
    // const mistmatchColumns = [];
    // const missingColumns = [];
    // for (let idx in COMPARED_COLUMNS) {
    //   const col = COMPARED_COLUMNS[idx];
    //   const columnGroupKey = column.colDef.key;
    //   const gstDataField = `2A - ${col}`;
    //   const currentGroupField = `${columnGroupKey} - ${col}`;

    //   if (MATCHING_EXCLUDED_COLUMNS.indexOf(currentGroupField) === -1 && data) {
    //     if (data[currentGroupField] !== undefined) {
    //       if (data[gstDataField] !== data[currentGroupField]) {
    //         mistmatchColumns.push(currentGroupField);
    //       }
    //     }
    //     if (data[currentGroupField] === undefined) {
    //       missingColumns.push(currentGroupField);
    //     }
    //   }
    // }

    // if (mistmatchColumns.length) {
    //   setIsMismatch(true);
    //   const msgText = getGroupLevelEmailMessage(mistmatchColumns, data);
    //   setTextMsg(msgText);
    // } else if (missingColumns.length) {
    //   setIsMissing(true);
    //   const msgText = getGroupLevelEmailMessage(missingColumns, data);
    //   setTextMsg(msgText);
    // }

    if(props.value == 'Mismatch'){
      setIsMismatch(true)
    }
    else if(props.value == 'Missing'){
      setIsMissing(true)
    }
  }, [column, data]);

  const renderIcon = (icon, text) => {
    return (
      <div className={styles.statusBox}>
        {icon}
        <span style={{marginLeft:"5px", color:"#374151"}}>{text}</span>
      </div>
    )
  };

  return (
    <Tippy
      className="email-msg-modal"
      ref={tippyRef.current}
      // content={MessageModal(textMsg)}
      // content={"Hello World!"}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
    <div onClick={( visible ? hide : show)} className={`${styles.statusWrapper} ${isMismatch ? styles.mismatch : (isMissing ? styles.missing : '') }`}>
        { isMismatch 
          ? renderIcon(<CloseCircleOutlined/>, MATCH_STATUSES.MISMATCH)
          : (
            isMissing
            ? renderIcon(<ExclamationCircleOutlined/>, MATCH_STATUSES.MISSING)
            : renderIcon(<CheckCircleOutlined/>, MATCH_STATUSES.MATCHED)
          )
        }
      </div>
    </Tippy>
  )
}

export default memo(StatusComponent);