import React from 'react';
import styled from 'styled-components';
import { DataSearch } from '@appbaseio/reactivesearch';
import colors from '../DataTable/theme';

const StyledSearch = styled(DataSearch)`
	width: 100%;
	margin-top: 13px;
	input {
		border-radius: 3px;
	}

	svg {
		fill: ${colors.blackTheme} !important;
	}
`;

const GlobalSearch = ({ searchData, componentId, handleValueChange }) => (
	<StyledSearch
		componentId={componentId}
		dataField={searchData.fields}
		fieldWeights={searchData.weights}
		autosuggest={false}
		highlight
		debounce={300}
		filterLabel="Search"
		showClear
		onValueChange={value => {
			handleValueChange(value);
		}}
	/>
);

export default GlobalSearch;
