import React, { Component } from 'react';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Loader from 'react-loader-advanced';

import RightColumn from '../components/rightColumn';
import LeftColumn from '../components/leftColumn';

import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import '../App.css';

class UploadFileContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loader: false,
			listColor: [
				'#767676',
				'#767676',
				'#767676',
				'#767676',
				'#767676',
				'#767676',
				'#767676',
				'#767676',
			],
		};
	}

	setLoader = loader => {
		this.setState({ loader });
	};

	staticClick = (selected, e) => {
		let listColor = [
			'#767676',
			'#767676',
			'#767676',
			'#767676',
			'#767676',
			'#767676',
			'#767676',
			'#767676',
		];

		listColor.splice(selected, 1, '#4ea7cf');
		this.setState({
			listColor,
		});
	};

	render() {
		return (
			<Loader
				show={this.state.loader}
				message="Please wait. Upload may take a few minutes. DO NOT refresh or close the browser."
			>
				{/* <ToastContainer /> */}
				<Row gutter={[16, 8]}>
					<Col span={4}>
						<LeftColumn
							staticClick={this.staticClick}
							listColor={this.state.listColor}
							workspace={this.props.workspace}
							email={this.props.loggedin_user}
							customer_domain={this.props.customer_domain}
						/>
					</Col>
					<Col span={20}>
						<RightColumn
							setLoader={this.setLoader}
							customer_domain={this.props.customer_domain}
							loggedin_user={this.props.loggedin_user}
						/>
					</Col>
				</Row>
			</Loader>
		);
	}
}

UploadFileContainer.propTypes = {
	workspace: PropTypes.string.isRequired,
	loggedin_user: PropTypes.string.isRequired,
	customer_domain: PropTypes.any.isRequired,
};

export default UploadFileContainer;
