import React, { useState, useEffect } from 'react';
import {
	ReactiveComponent,
	ReactiveList,
	SelectedFilters,
} from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import _ from 'lodash';

import DataTableWrapper from '../DataTable/DataTableWrapper';
import Loader from '../DataTable/Loader';
import DataTable from '../DataTable';
import Flex from '../styled/Flex';
import SortFilter from '../DataTable/SortFilter';
import GlobalSearch from '../GlobalSearch';
import NoResultFound from '../DataTable/NoResultFound';
import ExportCSV from '../ExportCSV';
import EmailReport from '../EmailReport';
import ScheduleReport from '../ScheduleReport';
import UploadAirlineInvoice from '../UploadInvoiceAirline';

import { numberWithCommas, getSearchableColumns } from '../../helpers/utils';
import { META_FIELDS, OBJECT_FIELDS, INITIAL_FIELDS } from '../../constants';
import { generateAggsQuery } from '../../helpers/aggregator';
import { setAggregations } from '../../actions';
import { tableQueryGenerator } from '../../helpers/query';

const PAGE_SIZE = 15;

const invertSort = currentSort => (currentSort === 'asc' ? 'desc' : 'asc');

const DynamicTab = ({ workspace, onSetAggregations, currentTab }) => {
	const { tableName, tabName } = currentTab;
	const [initialized, setInitialized] = useState(false);
	const [columns, setColumns] = useState([]);
	const [sortColumn, setSortColumn] = useState('_doc');
	const [sort, setSort] = useState('desc');
	const [stats, setStats] = useState('stats');
	const [query, setQuery] = useState(null);
	const [reactiveKey, setReactiveKey] = useState(Date.now());
	const currentView = tabName;
	const [searchValue, setSearchValue] = useState('');

	const hasInvoiceNotReceived =
		[
			'Invoices Not Received As Per Booking-E3',
			'Invoice Not Received But In 2A-E2',
		].indexOf(currentTab.tableName) > -1;

	const termColumn =
		workspace.Filter_Column === 'Airline'
			? 'Airline.keyword'
			: 'Customer_Name.keyword';
	const termValue = workspace.Filter_Value;

	const isCustomer = workspace.Filter_Column === 'Customer_Name';
	const defaultQuery = tableQueryGenerator(tableName, termValue, termColumn);

	useEffect(() => {
		if (!initialized) {
			setReactiveKey(Date.now());
			setInitialized(true);
			onSetAggregations(null);
		}
	}, [reactiveKey]);

	function handleColumnChange(results) {
		if (Boolean(results.length) && !Boolean(columns.length)) {
			const cols = Object.keys(results[0])
				.filter(
					col =>
						META_FIELDS.indexOf(col) === -1 &&
						OBJECT_FIELDS.indexOf(col) === -1,
				)
				.sort();
			setColumns(cols);
		}
	}

	function handleSort(sortField) {
		if (sortField === sortColumn) {
			setSort(invertSort(sort));
		} else {
			setSortColumn(sortField);
			setSort('asc');
		}
	}

	function handleQueryChange(query) {
		setQuery(query);
	}

	function resetSort() {
		setSort('desc');
		setSortColumn(searchValue.trim() ? '_score' : '_doc');
	}

	const cols = [...new Set([...INITIAL_FIELDS, ...columns])];
	const visibleColumns = ['_id', ...cols];
	// get date, number and boolean fields for this table

	const sortingColumn =
		searchValue.trim() && sortColumn === '_doc' ? '_score' : sortColumn;

	return (
		<>
			<Flex alignItems="center" justifyContent="space-between">
				<Flex style={{ minWidth: '50%' }}>
					<SortFilter
						field={sortingColumn}
						order={sort}
						onResetSort={resetSort}
					/>
					<SelectedFilters />
				</Flex>
				{!_.isEmpty(workspace) && (
					<Flex>
						{isCustomer ? (
							<>
								<ExportCSV
									query={query ? query.query : null}
									index="shopelect-sync-data"
								/>
								<EmailReport
									currentView={currentView}
									query={query ? query.query : null}
									index="shopelect-sync-data"
								/>
								<ScheduleReport
									currentView={currentView}
									query={query ? query.query : null}
									index="shopelect-sync-data"
								/>
							</>
						) : (
							<>
								<UploadAirlineInvoice data={workspace} />
								<ExportCSV
									query={query ? query.query : null}
									index="shopelect-sync-data"
								/>
							</>
						)}
					</Flex>
				)}
			</Flex>
			<Flex>
				<GlobalSearch
					componentId={`globalSearch_${currentView}`}
					searchData={getSearchableColumns(columns)}
					handleValueChange={setSearchValue}
				/>
			</Flex>
			<ReactiveComponent
				customQuery={() => defaultQuery}
				componentId="defaultQuery"
			/>
			{cols.length > 4 && (
				<ReactiveComponent
					key={`aggs_${currentView}_${reactiveKey}`}
					defaultQuery={() => generateAggsQuery(cols)}
					react={{
						and: [
							'defaultQuery',
							`globalSearch_${currentView}`,
							...new Set(cols),
						],
					}}
					componentId="aggregator"
					onAllData={(hits, aggregations) => {
						onSetAggregations(aggregations);
					}}
				/>
			)}
			{hasInvoiceNotReceived && (
				<ReactiveComponent
					customQuery={() => ({
						query: {
							bool: {
								must: [
									{
										term: {
											'Invoice_Y_N.keyword': `Invoice Not Received`,
										},
									},
								],
							},
						},
					})}
					showFilter
					filterLabel="Invoice Not Received Filter"
					componentId={`InvoiceNotReceivedFilter_${
						currentTab.tabName
					}`}
					key={`InvoiceNotReceivedFilter_${currentTab.tabName}`}
					value="Invoice Not Received"
				/>
			)}
			<DataTableWrapper
				visibleColumns={visibleColumns}
				handleSort={handleSort}
				sortColumn={sortingColumn}
				sortDir={sort}
				defaultQuery={defaultQuery}
			>
				{({ height, width, headerRef }) => (
					<div style={{ position: 'relative' }}>
						<ReactiveList
							key={`result_${currentView}_${reactiveKey}`}
							componentId={`result_${currentView}_${reactiveKey}`}
							dataField={sortingColumn}
							sortBy={sort}
							size={PAGE_SIZE}
							loader={<Loader />}
							defaultQuery={() => defaultQuery}
							react={{
								and: [
									'defaultQuery',
									`globalSearch_${currentView}`,
									...new Set(cols),
									`InvoiceNotReceivedFilter_${
										currentTab.tabName
									}`,
								],
							}}
							onData={({ results }) => {
								handleColumnChange(results);
							}}
							pagination
							renderResultStats={stats => (
								<Flex
									justifyContent="center"
									alignItems="center"
									style={{
										display: 'block',
										position: 'absolute',
										left: '0',
										height: '32px',
										fontSize: '14px',
										padding: '0 15px',
										lineHeight: '1.5',
										textAlign: 'center',
										bottom: -45,
									}}
								>
									Showing{' '}
									<b>
										{numberWithCommas(
											stats.displayedResults,
										)}
									</b>{' '}
									of total{' '}
									<b>
										{numberWithCommas(stats.totalResults)}
									</b>
								</Flex>
							)}
							renderAllData={({ results }) => (
								<DataTable
									key={results.length ? results[0]._id : '0'}
									data={results}
									columns={cols}
									height={height}
									width={width}
									headerRef={headerRef}
									stats={stats}
									pageSize={PAGE_SIZE}
									currentView={currentView}
								/>
							)}
							innerClass={{
								pagination: 'custom-pagination',
							}}
							onResultStats={stats => {
								setStats(stats);
							}}
							renderNoResults={() => <NoResultFound />}
							renderError={() => <NoResultFound />}
							onQueryChange={(prevQuery, nextQuery) => {
								handleQueryChange(nextQuery);
							}}
						/>
					</div>
				)}
			</DataTableWrapper>
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	workspace: state.workspace,
});

const mapDispatchToProps = dispatch => ({
	onSetAggregations: aggs => {
		dispatch(setAggregations(aggs));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DynamicTab);
