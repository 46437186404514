import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';

const HOST = 'https://elasticsearch-eaas-siddharthappbaseio-shopelect-cluster.searchbase.io';
let appname;
let type;

let jsonData = [];
let credentials = 'username:password';

// This should be updated by the onQueryChange method on the result component
let currentQuery;

/*
 * Exports data as a CSV file based on the provided ES app and query info.
 */
const exportData = (l_appname, l_type, l_credentials, l_currentQuery) => {
	appname = l_appname;
	type = l_type;
	credentials = l_credentials;
	currentQuery = l_currentQuery;
	console.log(appname, type, credentials, currentQuery);
	jsonData = [];
	const activeQuery = currentQuery ? currentQuery : defaultQuery;
	scrollApi({
		activeQuery,
	});
};

const getScrollApiData = data => {
	const hits = data.hits.hits;
	jsonData = jsonData.concat(hits);
	let str = null;
	/**
	 * Checking if the current data length is less then the total hits from the ES results,
	 * If yes calling the scrollApi function again to fetch the remaining data.
	 *
	 * */
	if (jsonData.length < data.hits.total) {
		const scrollObj = {
			scroll_id: data._scroll_id,
		};
		scrollApi({
			activeQuery: scrollObj,
			scroll: true,
			scroll_id: data._scroll_id,
		});
	} else {
		str = JSON.stringify(jsonData, null, 4);
		jsonData = [];
	}
	return str;
};

const defaultQuery = () => ({
	query: {
		match_all: {},
	},
});

/**
 * Param info { activeQuery, scroll, scroll_id }
 */
const scrollApi = info => {
	scrollQuery(info.activeQuery, info.scroll, info.scroll_id).then(res => {
		console.log('response: ', res);
		const data = getScrollApiData(res);
		if (data) {
			try {
				let exportData = JSON.parse(data);
				exportData = exportData.map(value => {
					const item = Object.assign(value._source);
					return flatten(item);
				});
				console.log('creating a csv file now');

				// Unparsing the data using papaparse package,
				// A Custom implementation can also be accepted
				const newData = unparse(exportData);
				console.log('unparsed data, ', newData);
				const file = new File([newData], 'data.csv', {
					type: 'text/comma-separated-values;charset=utf-8',
				});
				// Downloading the file using file-saver package
				saveAs(file);
			} catch (e) {
				console.error(e);
			}
		}
	});
};

/**
 * A function to convert multilevel object to single level object and use key value pairs as Column and row pairs using recursion
 */
const flatten = data => {
	const result = {};

	function recurse(cur, prop = '') {
		if (Object(cur) !== cur) {
			result[prop] = cur;
		} else if (Array.isArray(cur)) {
			const l = cur.length;
			for (let i = 0; i < l; i += 1) {
				recurse(cur[i], `${prop}[${i}]`);
			}
			if (l === 0) {
				result[prop] = [];
			}
		} else {
			let isEmpty = true;
			Object.keys(cur).forEach(p => {
				isEmpty = false;
				recurse(cur[p], prop ? `${prop}.${p}` : p);
			});
			if (isEmpty && prop) {
				result[prop] = {};
			}
		}
	}

	recurse(data);
	return result;
};

/**
 * Setting up the query,
 * If scrollId is defined then it will continue the last scroll call and fetch the next chunk of data.
 */
const scrollQuery = (queryBody, scroll, scrollId) => {
	const createUrl = `${HOST}/${appname}/${type}/_search?scroll=5m`;
	const scrollUrl = `${HOST}/${appname}/_search/scroll?scroll=5m&scroll_id=${scrollId}`;
	if (scroll) {
		return applyQuery(scrollUrl, queryBody);
	} else {
		return applyQuery(
			createUrl,
			Object.assign(
				{
					size: 1000,
				},
				queryBody,
			),
		);
	}
};

// Calling the ES using fetch for the given query
const applyQuery = (url, queryBody) =>
	fetch(url, {
		method: 'POST',
		headers: {
			Authorization: `Basic ${btoa(credentials)}`,
			'content-type': 'application/json',
		},
		// credentials: 'include',
		body: JSON.stringify(queryBody),
	}).then(data => data.json());

export default exportData;
