import React from 'react';
import Auth from '../services/Auth';
import Select from 'react-select';
import Table from 'react-table';
import { Button } from 'reactstrap';
import axios from 'axios';

import {
	ReactiveBase,
	MultiDropdownList,
	DateRange,
	ReactiveList,
	ToggleButton,
	ReactiveComponent,
} from '@appbaseio/reactivesearch';
import exportData from '../services/Scroll.js';
import Api from '../helpers/api';

import '../App.css';
import 'react-table/react-table.css';

import Appbase from 'appbase-js';

const api = Api();
const auth = new Auth();

const appbaseRef = new Appbase({
	url: 'https://elasticsearch-eaas-siddharthappbaseio-shopelect-cluster.searchbase.io',
	app: 'shopelect-airlines-analysis',
	credentials: 'T8RJMNL6n:0a4d11cd-f77b-4410-8186-0897eb795846',
});

const JOINS = {
	tables: {
		Booking: ['PNR', 'Ticket_Number', 'Agency_Invoice_Number'],
		Invoice: ['PNR', 'Ticket_Number', 'Invoice_Number'],
		'2A-Invoice': ['Invoice_Number'],
		'2A-Refund': ['Invoice_Number'],
		Payments: ['Ticket_Number', 'Agency_Invoice_Number'],
		R2: ['Ticket_Number', 'PNR', 'Invoice_Number'],
		agencyInvoice: ['PNR', 'Ticket_Number', 'Agency_Invoice_Number'],
	},
	sum_by: ['Total_Amount', 'Booking_GST', 'Taxable'],
	count_by: ['Ticket_Number', 'PNR', 'Invoice_Number'],
};

class DMSContainer extends React.Component {
	constructor() {
		super();
		this.state = {
			available: true,
			showModal: false,
			join: 'no-join',
			table1: 'Booking',
			table2: 'Invoice',
			tableOp: 'Sum by Invoice Amount',
			disabled: true,
			showDemo: false,
			showFilters: false,
			joinsResults: [],
			joinQuery: null,
			appliedQuery: null,
			invoiceLinks: [],
			activeCell: {
				rowIndex: null,
				columnLabel: null,
				rowLabel: null,
			},
		};
	}

	componentDidMount() {
		if (this.props.userType) {
			this.props.fetchAllReports(
				auth.getContactId(),
				this.props.userType,
			);
		}
		this.getAnalysisData();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.userType !== nextProps.userType) {
			this.props.fetchAllReports(auth.getContactId(), nextProps.userType);
		}
	}

	appendQuery = (prevQuery, nextQuery) => {
		console.log('updating query..');
		this.setState({
			appliedQuery: nextQuery,
		});
		const updatedQuery = { ...nextQuery };
		const mustPath = updatedQuery.query.bool.must[0].bool.must;
		let mustArray = mustPath;
		if (Array.isArray(mustPath)) {
			mustArray = mustPath.reduce((acc, cur) => {
				if (cur.terms && cur.terms['Table.keyword']) {
					return acc;
				}
				return [...acc, cur];
			}, []);
		} else if (mustPath.term && mustPath.term['Table.keyword']) {
			mustArray = [];
		}
		updatedQuery.query.bool.must[0].bool.must = mustArray;
		this.setState(
			{
				joinQuery: updatedQuery,
			},
			this.getAnalysisData,
		);
	};

	toggleFilters = () => {
		this.setState(({ showFilters }) => ({
			showFilters: !showFilters,
		}));
	};

	toggleModal = state => {
		this.setState(state => ({
			showModal: !state.showModal,
		}));
	};

	onJoinChange = join => {
		this.setState({
			join,
		});
	};

	onTable1Change = e => {
		this.setState({
			table1: e.value,
		});
		if (e.value === this.state.table2) {
			const possibleValues = ['Booking', 'Invoice', '2A'];
			this.setState({
				table2:
					possibleValues[(possibleValues.indexOf(e.value) + 1) % 3],
			});
		}
	};

	onTable2Change = e => {
		if (e.value !== this.state.table1) {
			this.setState({
				table2: e.value,
			});
		}
	};

	handleTableOption = e => {
		this.setState({
			tableOp: e.value,
		});
	};

	downloadData = () => {
		const app = 'shopelect-airlines-analysis';
		const type = 'shopelect-airlines-analysis';
		// read-only credentials
		const credentials = 'CHCMt1QLC:fa6e6fe5-e971-4dd1-8567-287d77a166ef';
		const { appliedQuery } = this.state;
		console.log('applied query is: ', appliedQuery);
		exportData(app, type, credentials, appliedQuery);
	};

	downloadInvoices = () => {
		const file_paths = [];
		for (let i = 0; i < this.state.invoiceLinks.length; i++) {
			if (this.state.invoiceLinks[i].Invoice_Link) {
				file_paths.push(this.state.invoiceLinks[i].Invoice_Link);
			}
		}
		const filePaths = {
			file_paths: file_paths,
		};

		axios({
			method: 'POST',
			url: `${api.api}/file/downloadzip`,
			data: filePaths,
		}).then(res => {
			if (res.data.status) {
				window.open(`${api.api}${res.data.resultPath}`);
			}
		});
	};

	/* This function runs the joins queries and 
	stores the responses (one at a time in the JOINS_RESULTS
	array. */
	getAnalysisData = async () => {
		const queries = [];
		let promises = [];
		const { joinQuery } = this.state;
		for (const baseTable in JOINS.tables) {
			let queryBody = {};
			if (JOINS.tables.hasOwnProperty(baseTable)) {
				// set the query
				queryBody.query = {
					bool: {
						must: [
							{
								term: {
									'Table.keyword': baseTable,
								},
							},
							joinQuery ? joinQuery.query : {},
						],
					},
				};
				queryBody.aggs = {};
				for (const correlationTable in JOINS.tables) {
					if (JOINS.tables.hasOwnProperty(correlationTable)) {
						if (baseTable !== correlationTable) {
							// set the aggregation
							const joinFields = JOINS.tables[baseTable].filter(
								value =>
									-1 !==
									JOINS.tables[correlationTable].indexOf(
										value,
									),
							);
							for (const joinField of joinFields) {
								queryBody.aggs[
									`${baseTable}_${correlationTable}_${joinField}`
								] = {
									terms: {
										field: `${joinField}.keyword`,
										exclude: '',
									},
									aggs: {
										inner: {
											terms: {
												field: `${baseTable}_${joinField}.${correlationTable}.${joinField}.keyword`,
											},
										},
										sum_by_amount: {
											sum: {
												field: 'Total_Amount',
											},
										},
										sum_by_taxable: {
											sum: {
												field: 'Taxable',
											},
										},
										sum_by_GST: {
											sum: {
												field: 'Booking_GST',
											},
										},
										count_by_Ticket_Number: {
											value_count: {
												field: 'Ticket_Number.keyword',
											},
										},
										count_by_PNR: {
											value_count: {
												field: 'PNR.keyword',
											},
										},
										count_by_Invoice_Number: {
											value_count: {
												field: 'Invoice_Number.keyword',
											},
										},
									},
								};
							}
						}
					}
				}
				queries.push(queryBody);
				promises = promises.concat(this.getQueryData(queryBody));
				// const Label = res.hits.hits[0]._source.Table;
				// const Base = res.hits.total;
			}
		}
		const results = await Promise.all(promises);
		const joinsResults = results
			.filter(res => res.hits.hits.length)
			.map(res => ({
				...res,
				Label: res.hits.hits[0]._source.Table,
				Base: res.hits.total,
			}));
		console.log('jr', joinsResults);
		this.setState({
			joinsResults,
		});
	};

	getQueryData = queryBody =>
		new Promise((resolve, reject) =>
			appbaseRef
				.search({
					body: queryBody,
				})
				.on('data', function(res) {
					resolve(res);
				}),
		);

	columnMap = column =>
		column === 'agencyInvoice' ? 'Agency Invoice' : column;

	getAggValue = (aggregations, label, fields) => {
		const { tableOp } = this.state;
		const tableOpMap = {
			'Sum by Invoice Amount': 'sum_by_amount',
			'Sum by Taxable Amount': 'sum_by_taxable',
			'Sum by Booking GST': 'sum_by_GST',
			'Count by Ticket Number': 'count_by_Ticket_Number',
			'Count by Invoice Number': 'count_by_Invoice_Number',
			'Count by PNR': 'count_by_PNR',
		};
		const mappedOp = tableOpMap[tableOp];
		const fieldMap = {
			Booking: 'Booking_PNR',
			Invoice: 'Invoice_PNR',
			'2A-Invoice': '2A-Invoice_Invoice_Number',
			'2A-Refund': '2A-Refund_Invoice_Number',
			Payments: 'Payments_Ticket_Number',
			R2: 'R2_Ticket_Number',
			'Agency Invoice': 'agencyInvoice_Ticket_Number',
		};
		const fieldValue = field => {
			const aggField = aggregations[`${label}_${fieldMap[field]}`];
			if (aggField) {
				return aggField.buckets.reduce(
					(acc, cur) => acc + cur[mappedOp].value,
					0,
				);
			}
			return '-';
		};
		return fields.reduce(
			(acc, field) => ({ ...acc, [field]: fieldValue(field) }),
			{},
		);
	};

	renderTable = () => {
		const { joinsResults } = this.state;
		console.log(joinsResults);
		if (!joinsResults.length) {
			return <div>Loading...</div>;
		}
		const fields = [
			'Base',
			'Booking',
			'Invoice',
			'2A-Invoice',
			'2A-Refund',
			'Payments',
			'R2',
			'Agency Invoice',
		];
		const data = joinsResults.map(data => {
			const Base = data.Base;
			const Label = this.columnMap(data.Label);
			return {
				[this.columnMap(data.Label)]: 'NA',
				...this.getAggValue(
					data.aggregations,
					Label,
					fields.filter(field => field !== Label),
				),
				Base,
				Label,
			};
		});
		const columns = [
			{ Header: '', accessor: 'Label' },
			...fields.map(field => {
				if (field === 'Base') {
					return {
						Header: field,
						accessor: field,
					};
				}
				return {
					Header: field,
					accessor: field,
					getProps: (state, rowInfo, column) => {
						if (rowInfo && rowInfo.row[column.id] === 'NA') {
							return {
								style: { background: 'lightgray', padding: 0 },
							};
						}
						return {
							style: { background: 'lightblue', padding: 0 },
						};
					},
					Cell: row => (
						<div
							onClick={stuff => {
								console.log(
									'row info: ',
									row,
									row.row.Label,
									row.column.Header,
									row.index,
								);
								this.setState({
									activeCell: {
										rowIndex: row.index,
										rowLabel: row.row.Label,
										columnLabel: row.column.Header,
									},
								});
							}}
							className={'table-cell'}
							style={{
								backgroundColor:
									this.state.activeCell.rowIndex ===
										row.index &&
									this.state.activeCell.columnLabel ===
										row.column.Header
										? 'green'
										: 'inherit',
							}}
						>
							{row.value}
						</div>
					),
				};
			}),
		];
		return (
			<Table
				showPagination={false}
				data={data}
				columns={columns}
				defaultPageSize={7}
			/>
		);
	};

	render() {
		const { tableOp, showDemo, activeCell, showFilters } = this.state;
		const tableOptions = [
			'Sum by Invoice Amount',
			'Sum by Taxable Amount',
			'Sum by Booking GST',
			'Count by Ticket Number',
			'Count by Invoice Number',
			'Count by PNR',
		];
		return (
			<div style={{ textAlign: 'center' }}>
				<div
					style={{
						display: 'flex',
						width: '100%',
						marginBottom: '1rem',
						flexDirection: 'column',
					}}
				>
					<div
						style={{
							display: 'flex',
							margin: '20px 0',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<h1 style={{ border: 'none', margin: 0, padding: 0 }}>
							Document Management System
						</h1>
						<Select
							value={tableOp}
							options={tableOptions.map(option => ({
								label: option,
								value: option,
							}))}
							searchable={false}
							clearable={false}
							name="table_op"
							onChange={this.handleTableOption}
							style={{ width: 250, height: 'auto' }}
						/>
					</div>
					{this.renderTable()}
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
						}}
					>
						{showDemo && (
							<span
								style={{
									color: 'dodgerblue',
									position: 'absolute',
									left: 'calc(50% - 70px)',
								}}
							>
								This is demo data
							</span>
						)}
					</div>
					{activeCell.rowLabel && (
						<div
							style={{
								marginTop: '10px',
							}}
						>
							Active cell is: {activeCell.rowLabel},{' '}
							{activeCell.columnLabel}
						</div>
					)}
				</div>
				<ReactiveBase
					app="shopelect-airlines-analysis"
					credentials="CHCMt1QLC:fa6e6fe5-e971-4dd1-8567-287d77a166ef"
					type="shopelect-airlines-analysis"
				>
					<div>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<ReactiveComponent
								componentId="activeCell"
								defaultSelected={this.props.customer_domain}
								customQuery={(value, props) => {
									return {
										query: {
											term: {
												'Table.keyword': `${
													activeCell.rowLabel
												}`,
											},
										},
									};
								}}
							/>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-around',
								margin: '50px 0',
							}}
						>
							<Button onClick={this.toggleFilters}>
								Toggle Filters
							</Button>
							<Button onClick={this.downloadData}>
								Export Data
							</Button>
							<Button onClick={this.downloadInvoices}>
								Download Invoices
							</Button>
						</div>
						<div
							style={{
								display: showFilters ? 'grid' : 'none',
								textAlign: 'left',
								gridTemplateColumns:
									'repeat(auto-fit, minmax(300px, 1fr))',
								border: '1px solid #ddd',
								borderRadius: 4,
								padding: 25,
								marginBottom: 25,
								gridGap: 25,
							}}
						>
							<ToggleButton
								title="Line of Business"
								componentId="lob"
								dataField="Line_Of_Business.keyword"
								data={[
									{
										label: 'Air',
										value: 'Air',
									},
									{
										label: 'Hotel',
										value: 'Hotel',
									},
								]}
							/>
							<MultiDropdownList
								title="Vendor"
								componentId="vendor"
								dataField="Airline.keyword"
							/>
							<ToggleButton
								title="SOTO Status"
								componentId="soto"
								dataField="SOTO_Status.keyword"
								data={[
									{
										label: 'Non',
										value: 'NON SOTO',
									},
									{
										label: 'SOTO',
										value: 'SOTO',
									},
								]}
							/>
							<ToggleButton
								title="Transaction Type"
								dataField="Transaction_Type.keyword"
								componentId="ttype"
								data={[
									{
										label: 'Invoice',
										value: 'Invoice',
									},
									{
										label: 'Refund',
										value: 'Refund',
									},
								]}
							/>
							<DateRange
								title="Invoice Date"
								dataField="Invoice_Date"
								componentId="invoicedate"
								customQuery={(value, props) => {
									if (value) {
										let start = value.start;
										start = start
											.split('-')
											.reverse()
											.join('-');
										let end = value.end;
										end = end
											.split('-')
											.reverse()
											.join('-');
										return {
											query: {
												range: {
													Booking_Date: {
														gte: start,
														lte: end,
													},
												},
											},
										};
									}
								}}
							/>
							<DateRange
								title="Booking Date"
								dataField="Booking_Date"
								componentId="bookingdate"
								customQuery={(value, props) => {
									if (value) {
										let start = value.start;
										start = start
											.split('-')
											.reverse()
											.join('-');
										let end = value.end;
										end = end
											.split('-')
											.reverse()
											.join('-');
										return {
											query: {
												range: {
													Booking_Date: {
														gte: start,
														lte: end,
													},
												},
											},
										};
									}
								}}
							/>
							<MultiDropdownList
								title="Agency Name"
								componentId="agencyname"
								dataField="Agency_Name.keyword"
							/>
							<MultiDropdownList
								title="Return Period"
								componentId="returnperiod"
								dataField="Return_Period.keyword"
							/>
						</div>
						<ReactiveList
							componentId="dmsResults"
							dataField="Date"
							showResultStats={false}
							size={30}
							innerClass={{
								poweredBy: 'result-powered-by',
							}}
							defaultQuery={() => ({
								query: activeCell.rowLabel
									? {
											terms: {
												'Table.keyword': [
													activeCell.rowLabel,
													activeCell.columnLabel,
												],
											},
									  }
									: { match_all: {} },
							})}
							react={{
								and: [
									'lob',
									'vendor',
									'soto',
									'ttype',
									'invoicedate',
									'bookingdate',
									'agencyname',
									'returnperiod',
								],
							}}
							onQueryChange={this.appendQuery}
							onData={({ results }) => {
								console.log('res onAllData: ', results);
								if (
									this.state.invoiceLinks.length !==
									results.length
								) {
									this.setState({
										invoiceLinks: results,
									});
								}
							}}
							renderAllData={({ results }) => (
								<Table
									data={results}
									defaultPageSize={30}
									resizable={false}
									columns={[
										{
											accessor: 'Invoice_Date',
											Header: 'Invoice Date',
											minWidth: 150,
										},
										{
											accessor: 'Booking_Date',
											Header: 'Booking Date',
											minWidth: 150,
										},
										{
											accessor: 'Vendor',
											Header: 'Vendor',
											minWidth: 150,
										},
										{
											accessor: 'Total_Amount',
											Header: 'Total Amount',
											minWidth: 150,
										},
										{
											accessor: 'Taxable',
											Header: 'Taxable',
											minWidth: 150,
										},
										{
											accessor: 'Booking_GST',
											Header: 'Booking GST',
											minWidth: 150,
										},
										{
											accessor: 'Ticket_Number',
											Header: 'Ticket Number',
											minWidth: 150,
										},
										{
											accessor: 'PNR',
											Header: 'PNR',
											minWidth: 150,
										},
										{
											accessor: 'Invoice_Number',
											Header: 'Invoice Number',
											minWidth: 200,
										},
										{
											accessor: 'Line_Of_Business',
											Header: 'Line Of Business',
											minWidth: 150,
										},
										{
											accessor: 'SOTO_Status',
											Header: 'SOTO Status',
											minWidth: 150,
										},
										{
											accessor: 'Transaction_Type',
											Header: 'Transaction Type',
											minWidth: 150,
										},
										{
											accessor: 'Invoice_Link',
											Header: 'Invoice Link',
											minWidth: 150,
											Cell: row => (
												<a
													href={row.value}
													style={{
														color: 'blue',
													}}
												>
													{row.value}
												</a>
											),
										},
									]}
									getTdProps={() => ({
										style: {
											border: 'none',
										},
									})}
									getTheadThProps={() => ({
										style: {
											border: 'none',
										},
									})}
									getTheadProps={() => ({
										style: {
											boxShadow: 'none',
											marginBottom: 10,
											fontWeight: 'bold',
										},
									})}
									style={{ border: 'none' }}
									showPagination={false}
								/>
							)}
						/>
					</div>
				</ReactiveBase>
			</div>
		);
	}
}

export default DMSContainer;
