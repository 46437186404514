import {
	DATE_FIELDS,
	NUMBER_FIELDS,
	BOOLEAN_FIELDS,
	OBJECT_FIELDS,
	META_FIELDS,
} from '../constants';

export const convertToMax = (number, max) => {
	const numberLength = number.toString().length;
	const maxLength = max.toString().length;
	if (numberLength !== maxLength) {
		return '0'.repeat(maxLength - numberLength) + number.toString();
	}

	return number;
};

export const numberWithCommas = x => {
	if (!x) {
		return '';
	}
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getStringColumns = fields =>
	fields.filter(
		item =>
			META_FIELDS.indexOf(item) === -1 &&
			DATE_FIELDS.indexOf(item) === -1 &&
			NUMBER_FIELDS.indexOf(item) === -1 &&
			BOOLEAN_FIELDS.indexOf(item) === -1 &&
			OBJECT_FIELDS.indexOf(item) === -1,
	);

export const getSearchableColumns = fields => {
	// const filteredFields = [
	// 	...getStringColumns(fields),
	// 	`comments.author`,
	// 	`comments.content`,
	// ];
	const filteredFields = [
		'Airline',
		'Booking_Y_N',
		'Status',
		'comments.author',
		'comments.content',
		'Booking_Y_N',
		'Customer_GSTIN',
		'Customer_Name',
		'Customer_PAN',
		'HSN',
		'Invoice_Number',
		'ProductDescription',
		'Supplier_GSTIN',
		'Agency_Name',
		'Ticket_Or_PNR',
	];
	const fieldList = [];
	const weights = [];
	filteredFields.forEach(item => {
		fieldList.push(item);
		weights.push(3);
		fieldList.push(`${item}.keyword`);
		weights.push(3);
		fieldList.push(`${item}.search`);
		weights.push(1);
	});
	return { fields: fieldList, weights };
};

export const isValidEmail = email => {
	// eslint-disable-next-line
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

function floorFigure(figure, decimals) {
	if (!decimals) decimals = 2;
	var d = Math.pow(10, decimals);
	return (parseInt(figure * d) / d).toFixed(decimals);
}

export const numDifferentiation = value => {
	var val = Math.abs(value);
	if (val >= 10000000) {
		val = floorFigure(val / 10000000) + ' Cr';
	} else if (val >= 100000) {
		val = floorFigure(val / 100000) + ' Lac';
	} else if (val >= 1000) val = floorFigure(val / 1000) + ' K';

	return val;
};
