import React from 'react';
import {
	ReactiveBase,
	DataSearch,
	ResultList,
} from '@appbaseio/reactivesearch';
import { List, Spin } from 'antd';
import styled from 'styled-components';

const StyledSearch = styled(DataSearch)`
	input {
		border-radius: 3px;
	}
`;

class ReportTableContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			reportName: '',
			reportDescription: '',
			reportNumber: '',
			reportURL: '',
		};
	}

	render() {
		return (
			<div style={{ marginTop: '-80px', textAlign: 'left' }}>
				<ReactiveBase
					app="shopelect-dashbord-reports"
					credentials="T8RJMNL6n:0a4d11cd-f77b-4410-8186-0897eb795846"
					url="https://elasticsearch-eaas-siddharthappbaseio-shopelect-cluster.searchbase.io"
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginTop: '10px',
						}}
					>
						<StyledSearch
							componentId="reportSearch"
							dataField={[
								'Report_Name',
								// 'Report_Name.keyword',
								// 'Report_Name.search',
								'Report_Number',
								// 'Report_Number.keyword',
								// 'Report_Number.search',
							]}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Search for reports"
							// highlight
							style={{ flexGrow: 1 }}
						/>
					</div>
					<div style={{ position: 'relative' }}>
						<ResultList
							style={{
								marginTop: '1rem',
							}}
							componentId="resultList"
							dataField="_score"
							defaultQuery={() => ({
								query: {
									bool: {
										must: [
											{
												term: {
													'Tab.keyword': 'Reporting',
												},
											},
											{
												term: {
													'Customer_name.keyword': this
														.props.customer_domain,
												},
											},
											{
												bool: {
													must_not: [
														{
															term: {
																'embedurl.keyword':
																	'',
															},
														},
													],
												},
											},
										],
									},
								},
							})}
							size={30}
							innerClass={{
								poweredBy: 'result-powered-by',
							}}
							renderAllData={({ results }) => (
								<List
									bordered
									dataSource={results}
									itemLayout="horizontal"
									renderItem={item => (
										<List.Item
											actions={[
												<a
													href={`/reporting/${item.Report_Number}`}
													target="_blank"
													rel="noopener noreferrer"
													style={{
														color: 'dodgerblue',
													}}
												>
													View Report
												</a>,
											]}
										>
											<List.Item.Meta
												title={
													<span
														dangerouslySetInnerHTML={{
															__html: `${item.Report_Number} ${item.Report_Name}`,
														}}
													/>
												}
												description={
													item.Report_Description
												}
											/>
										</List.Item>
									)}
								/>
							)}
							react={{
								and: ['reportSearch'],
							}}
							renderNoResults={() => null}
							loader={
								<div
									style={{
										position: 'absolute',
										left: '50%',
										top: '40%',
									}}
								>
									<Spin />
								</div>
							}
						/>
					</div>
				</ReactiveBase>
			</div>
		);
	}
}

export default ReportTableContainer;
