import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
	DateRange,
	ReactiveComponent,
	DynamicRangeSlider,
} from '@appbaseio/reactivesearch';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import Flex from '../styled/Flex';

import { numDifferentiation, numberWithCommas } from '../../helpers/utils';
import { tableQueryGenerator } from '../../helpers/query';

const MONTH_ARRAY = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

const TABLE_CLASS = [
	'color-yellow-opac-35',
	'color-green-opac-35',
	'color-blue-opac-35',
];

const Bullets = styled.div`
	height: 20px;
	width: 20px;
	border-radius: 50%;
	margin-right: 5px;
`;

const Column = styled.div`
	width: 25%;
	border-right: 1px solid #dfdfdf;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledDate = styled(DateRange)`
	div {
		border-radius: 3px;
	}

	input {
		height: 27px !important;
		font-size: 0.8rem !important;
	}
`;

const StyledRangeInput = styled(DynamicRangeSlider)`
	div {
		border-radius: 3px;
	}

	input {
		height: 32px !important;
		font-size: 0.8rem !important;
	}
`;

const getDate = record => {
	if (record['start_date']['value']) {
		return `${record['start_date']['value_as_string']}-${
			record['end_date']['value_as_string']
		}`;
	}

	return '-';
};

const SummaryNotReceivedIn2A6A = ({ aggregations, isLoading }) => {
	if (aggregations && !isLoading) {
		return (
			<Flex
				flexDirection="row"
				className={TABLE_CLASS[0]}
				style={{ minHeight: 65, maxHeight: 65 }}
			>
				<Column>{getDate(aggregations)}</Column>
				<Column>{aggregations['invoice_count']['value']}</Column>
				<Column>{aggregations['customer_count']['value']}</Column>
				<Column style={{ borderRight: 0 }}>
					&#8377;{' '}
					{numDifferentiation(
						aggregations['total_amount']['value'] || 0,
					)}
				</Column>
			</Flex>
		);
	}

	return (
		<Flex flexDirection="row" className={TABLE_CLASS[0]}>
			<Column>-</Column>
			<Column>-</Column>
			<Column>-</Column>
			<Column style={{ borderRight: 0 }}>-</Column>
		</Flex>
	);
};

const SummaryNotReceivedAsPerBooking = ({ aggregations, isLoading }) => {
	if (aggregations && !isLoading) {
		return (
			<Flex
				flexDirection="row"
				className={TABLE_CLASS[1]}
				style={{ minHeight: 65, maxHeight: 65 }}
			>
				<Column>{getDate(aggregations)}</Column>
				<Column>{aggregations['invoice_count']['value']}</Column>
				<Column>{aggregations['customer_count']['value']}</Column>
				<Column style={{ borderRight: 0 }}>
					&#8377;{' '}
					{numDifferentiation(
						aggregations['total_amount']['value'] || 0,
					)}
				</Column>
			</Flex>
		);
	}

	return (
		<Flex flexDirection="row" className={TABLE_CLASS[1]}>
			<Column>-</Column>
			<Column>-</Column>
			<Column>-</Column>
			<Column style={{ borderRight: 0 }}>-</Column>
		</Flex>
	);
};

const SummaryReceivedButNotIn2A = ({ aggregations, isLoading }) => {
	if (aggregations && !isLoading) {
		return (
			<Flex
				flexDirection="row"
				className={TABLE_CLASS[2]}
				style={{ minHeight: 65, maxHeight: 65 }}
			>
				<Column>{getDate(aggregations)}</Column>
				<Column>{aggregations['invoice_count']['value']}</Column>
				<Column>{aggregations['customer_count']['value']}</Column>
				<Column style={{ borderRight: 0 }}>
					&#8377;{' '}
					{numDifferentiation(
						aggregations['total_amount']['value'] || 0,
					)}
				</Column>
			</Flex>
		);
	}

	return (
		<Flex flexDirection="row" className={TABLE_CLASS[2]}>
			<Column>-</Column>
			<Column>-</Column>
			<Column>-</Column>
			<Column style={{ borderRight: 0 }}>-</Column>
		</Flex>
	);
};

const Summary = ({ customerDomain, workspace, history }) => {
	const [isInitialized, setIsInitialized] = useState(false);

	useEffect(() => {
		if (!isInitialized) {
			setIsInitialized(true);
		}
	});

	const termColumn =
		workspace.Filter_Column === 'Airline'
			? 'Airline.keyword'
			: 'Customer_Name.keyword';
	const isAirline = workspace.Filter_Column === 'Airline';
	const termValue = workspace.Filter_Value;
	const customerLabel =
		workspace && workspace.Filter_Column === 'Airline'
			? 'Customers'
			: 'Vendors';

	return (
		<div>
			<Flex
				flexDirection="column"
				style={{
					borderRadius: 3,
					border: '1px solid #dfdfdf',
					fontSize: 12,
				}}
			>
				<Flex
					flexDirection="row"
					style={{ fontWeight: 'bold', background: '#fff' }}
				>
					<Column>Date</Column>
					<Column>Invoices</Column>
					<Column>{customerLabel}</Column>
					<Column style={{ borderRight: 0 }}>Total Amount</Column>
				</Flex>
				<ReactiveComponent
					componentId="AmountNullFilter"
					customQuery={() => ({
						query: {
							bool: {
								must_not: {
									exists: {
										field: 'Transaction_Amount',
									},
								},
							},
						},
					})}
				/>
				<ReactiveComponent
					componentId="SummaryNotReceivedIn2A6A"
					defaultQuery={() => ({
						...tableQueryGenerator(
							'Invoice Not Received But In 2A-E2',
							termValue,
							termColumn,
						),
						size: 0,
						aggs: {
							start_date: {
								min: {
									field: 'Invoice_Date',
									format: 'dd-MM-yy',
								},
							},
							end_date: {
								max: {
									field: 'Invoice_Date',
									format: 'dd-MM-yy',
								},
							},
							invoice_count: {
								value_count: {
									field: 'Invoice_Number.keyword',
								},
							},
							customer_count: {
								cardinality: {
									field: isAirline
										? 'Customer_Name.keyword'
										: 'Airline.keyword',
								},
							},
							total_amount: {
								sum: {
									field: 'Transaction_Amount',
								},
							},
						},
					})}
					react={{
						and: ['SummaryInvoiceDateFilter', 'SummaryRangeInput'],
					}}
				>
					<SummaryNotReceivedIn2A6A />
				</ReactiveComponent>
				<ReactiveComponent
					componentId="SummaryNotReceivedAsPerBooking"
					defaultQuery={() => ({
						...tableQueryGenerator(
							'Invoices Not Received As Per Booking-E3',
							termValue,
							termColumn,
						),
						size: 0,
						aggs: {
							start_date: {
								min: {
									field: 'Booking_Date',
									format: 'dd-MM-yy',
								},
							},
							end_date: {
								max: {
									field: 'Booking_Date',
									format: 'dd-MM-yy',
								},
							},
							invoice_count: {
								value_count: {
									field: 'Ticket_Or_PNR.keyword',
								},
							},
							customer_count: {
								cardinality: {
									field: isAirline
										? 'Customer_Name.keyword'
										: 'Airline.keyword',
								},
							},
							total_amount: {
								sum: {
									field: 'Transaction_Amount',
								},
							},
						},
					})}
					react={{
						and: ['SummaryBookingDateFilter', 'SummaryRangeInput'],
					}}
				>
					<SummaryNotReceivedAsPerBooking />
				</ReactiveComponent>
				<ReactiveComponent
					componentId="SummaryReceivedButNotIn2A"
					defaultQuery={() => ({
						...tableQueryGenerator(
							'Invoice Received But Not In 2A-E1',
							termValue,
							termColumn,
						),
						size: 0,
						aggs: {
							start_date: {
								min: {
									field: 'Invoice_Date',
									format: 'dd-MM-yy',
								},
							},
							end_date: {
								max: {
									field: 'Invoice_Date',
									format: 'dd-MM-yy',
								},
							},
							invoice_count: {
								value_count: {
									field: 'Invoice_Number.keyword',
								},
							},
							customer_count: {
								cardinality: {
									field: isAirline
										? 'Customer_Name.keyword'
										: 'Airline.keyword',
								},
							},
							total_amount: {
								sum: {
									field: 'Transaction_Amount',
								},
							},
						},
					})}
					react={{
						and: ['SummaryInvoiceDateFilter', 'SummaryRangeInput'],
					}}
				>
					<SummaryReceivedButNotIn2A />
				</ReactiveComponent>
			</Flex>
			<br />
			<div>
				<Flex style={{ marginBottom: 5 }}>
					<Bullets className={TABLE_CLASS[0]} />
					<span>Invoices Not Received But in 2A/6A</span>
				</Flex>
				<Flex style={{ marginBottom: 5 }}>
					<Bullets className={TABLE_CLASS[1]} />
					<span>Invoices Not Received as Per Booking</span>
				</Flex>
				<Flex style={{ marginBottom: 5 }}>
					<Bullets className={TABLE_CLASS[2]} />
					<span>Invoices Received but not in 2A</span>
				</Flex>
			</div>
			<br />
			<StyledDate
				componentId="SummaryInvoiceDateFilter"
				dataField="Invoice_Date"
				title="Invoice Date Filter"
				customQuery={(value, props) => {
					let query = null;
					if (value) {
						query = [
							{
								range: {
									[props.dataField]: {
										format: 'epoch_millis',
										gte: moment(value.start).valueOf(),
										lte: moment(value.end).valueOf(),
									},
								},
							},
						];
					}
					return query ? { query: { bool: { must: query } } } : null;
				}}
			/>
			<br />
			<StyledDate
				componentId="SummaryBookingDateFilter"
				dataField="Booking_Date"
				title="Booking Date Filter"
				customQuery={(value, props) => {
					let query = null;
					if (value) {
						query = [
							{
								range: {
									[props.dataField]: {
										format: 'epoch_millis',
										gte: moment(value.start).valueOf(),
										lte: moment(value.end).valueOf(),
									},
								},
							},
						];
					}
					return query ? { query: { bool: { must: query } } } : null;
				}}
			/>

			<br />
			{termValue !== 'Admin' && (
				<ReactiveComponent
					componentId="summaryDefaultQuery"
					customQuery={() =>
						tableQueryGenerator(null, termValue, termColumn)
					}
				/>
			)}

			<StyledRangeInput
				componentId="SummaryRangeInput"
				dataField="Transaction_Amount"
				title="Transaction Amount Filter"
				style={{
					background: '#fff',
					padding: 10,
					borderRadius: 3,
					marginTop: 10,
					border: '1px solid #dfdfdf',
				}}
				tooltipTrigger="hover"
				react={{
					and: ['summaryDefaultQuery'],
				}}
				rangeLabels={(min, max) => ({
					start: `₹ ${numberWithCommas(min)}`,
					end: `₹ ${numberWithCommas(max)}`,
				})}
				showHistogram={false}
				customQuery={(value, props) => {
					return {
						query: {
							bool: {
								should: [
									{
										range: {
											Transaction_Amount: {
												gte: value[0],
												lte: value[1],
												boost: 2,
											},
										},
									},
									{
										bool: {
											must_not: {
												exists: {
													field: 'Transaction_Amount',
												},
											},
										},
									},
								],
							},
						},
					};
				}}
			/>
		</div>
	);
};

const mapStateToProps = state => ({
	customerDomain: state.customerDomain,
	workspace: state.workspace,
});

export default withRouter(connect(mapStateToProps)(Summary));
