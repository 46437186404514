import React, { PureComponent } from 'react';

import StyledCell from './StyledCell';
import Flex from '../styled/Flex';

import colors from './theme';

class IdHeaderField extends PureComponent {
	render() {
		return (
			<StyledCell
				style={{
					background: colors.tableHead,
					width: 275,
					fontWeight: 'bold',
					zIndex: '101 !important',
					left: 0,
					top: 0,
					position: 'sticky',
				}}
				height="60px"
			>
				<Flex
					style={{
						width: '100%',
					}}
					alignItems="center"
					justifyContent="left"
					wrap="nowrap"
				>
					<div>Status, Comments</div>
				</Flex>
			</StyledCell>
		);
	}
}

export default IdHeaderField;
