import React, { Component } from 'react';
import FaSortIcon from 'react-icons/lib/fa/sort';
import FaCaretUp from 'react-icons/lib/fa/caret-up';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import _ from 'lodash';

import StyledCell from './StyledCell';
import TermAggregation from './TermAggregation';
import Flex from '../styled/Flex';
import overflowStyles from './overflow.style';

import colors from './theme';
import { getStringColumns, numberWithCommas } from '../../helpers/utils';
import { NUMBER_FIELDS } from '../../constants';

class DataColumnHeader extends Component {
	render() {
		const {
			col,
			visibleColumns,
			sortColumn,
			defaultQuery,
			handleSort,
			sortDir,
			aggregations,
		} = this.props;

		return (
			<StyledCell
				style={{ background: colors.tableHead, fontWeight: 'bold' }}
				height="60"
			>
				<Flex flexDirection="column" style={{ width: '100%' }}>
					<Flex
						justifyContent="space-between"
						alignItems="center"
						wrap="nowrap"
						style={{
							width: '100%',
						}}
					>
						<Flex
							alignItems="center"
							wrap="nowrap"
							style={{ cursor: 'pointer' }}
						>
							<Tooltip title={col} trigger="click">
								<span
									style={{
										maxWidth: 120,
										...overflowStyles,
									}}
								>
									{col}
								</span>
							</Tooltip>
						</Flex>

						<Flex alignItems="center">
							<TermAggregation
								field={col}
								visibleColumns={visibleColumns}
								defaultQuery={defaultQuery}
							/>
							<button
								type="button"
								onClick={() => {
									if (
										getStringColumns(
											visibleColumns,
										).indexOf(col) > -1
									) {
										handleSort(`${col}.keyword`);
									} else {
										handleSort(col);
									}
								}}
								style={{
									outline: 0,
									height: '15px',
									width: '15px',
									border: 0,
									cursor: 'pointer',
									background: 'none',
								}}
							>
								{sortColumn.split('.')[0] === col ? (
									<>
										<>
											{sortDir === 'asc' && <FaCaretUp />}
										</>
										<>
											{sortDir === 'desc' && (
												<FaCaretDown />
											)}
										</>
									</>
								) : (
									<FaSortIcon />
								)}
							</button>
						</Flex>
					</Flex>
					<Flex alignItems="center">
						{aggregations &&
							!_.isEmpty(aggregations) &&
							aggregations[col] &&
							aggregations[col].value && (
								<Tooltip
									title={(aggregations[col] || {}).value || 0}
									trigger="click"
								>
									<Flex
										justifyContent="space-between"
										style={{ marginTop: 4, width: '100%' }}
									>
										<i
											style={{
												cursor: 'pointer',
												fontWeight: 'normal',
												maxWidth: '50%',
												...overflowStyles,
											}}
										>
											{NUMBER_FIELDS.indexOf(col) > -1
												? 'Sum'
												: 'Unique terms'}
										</i>
										<i
											style={{
												cursor: 'pointer',
												fontWeight: 'normal',
												...overflowStyles,
											}}
										>
											{numberWithCommas(
												aggregations[col].value,
											)}
										</i>
									</Flex>
								</Tooltip>
							)}
					</Flex>
				</Flex>
			</StyledCell>
		);
	}
}

const mapStateToProps = state => ({
	aggregations: state.aggregations,
});
export default connect(mapStateToProps)(DataColumnHeader);
