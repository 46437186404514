import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ReactiveList, ReactiveComponent } from '@appbaseio/reactivesearch';
import { Collapse, Spin } from 'antd';

import Flex from '../styled/Flex';

const ErrorLogs = ({ workspace }) => {
	const query = {
		query: {
			term: {
				_index: 'sync-errors',
			},
		},
	};

	return (
		<div
			style={{
				textAlign: 'left',
				position: 'relative',
			}}
		>
			<Flex alignItems="center" justifyContent="space-between">
				<Flex style={{ minWidth: '60%' }}>
					<h2>Error Logs</h2>
				</Flex>
			</Flex>
			<ReactiveComponent
				customQuery={() => query}
				componentId="baseQuery"
			/>
			<ReactiveList
				componentId="ActivityResult"
				dataField="_doc"
				size={10}
				pagination
				innerClass={{
					pagination: 'custom-pagination',
				}}
				react={{
					and: ['baseQuery'],
				}}
				loader={
					<div
						style={{
							position: 'absolute',
							left: '50%',
							top: '40%',
						}}
					>
						<Spin />
					</div>
				}
				renderAllData={({ results }) => (
					<Collapse>
						{results.map(item => (
							<Collapse.Panel
								key={item._id}
								header={
									<>
										<div>{item.error}</div>
										<div>{item.errorMessage}</div>
									</>
								}
							>
								<pre>{JSON.stringify(item.data, null, 2)}</pre>
							</Collapse.Panel>
						))}
					</Collapse>
				)}
			/>
		</div>
	);
};

const mapStateToProps = state => ({
	workspace: state.workspace,
});
export default connect(mapStateToProps)(ErrorLogs);
