import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Drawer, Button, Table, Spin } from 'antd';
import './rowView.css';

export default function RowView({ visible, onClose, rowData, rowDataLoading }) {
	const [pivotedData, setPivotedData] = useState({});

	const pivotData = () => {
		if (rowData && rowData.filingStatus && rowData.filingStatus[0]) {
			const data = {};
			rowData.filingStatus[0].forEach(item => {
				const rtntype = item.rtntype;
				if (!data[rtntype]) {
					data[rtntype] = [];
				}
				data[rtntype].push(item);
			});
			setPivotedData(data);
		}
	};
	useEffect(() => {
		pivotData();
	}, [rowData]);

	const filingColumns = [
		{
			title: 'ARN',
			dataIndex: 'arn',
			key: 'arn',
		},
		{
			title: 'DOF',
			dataIndex: 'dof',
			key: 'dof',
		},
		{
			title: 'FY',
			dataIndex: 'fy',
			key: 'fy',
		},
		{
			title: 'MOF',
			dataIndex: 'mof',
			key: 'mof',
		},
		{
			title: 'RTN',
			dataIndex: 'rtntype',
			key: 'rtntype',
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'TAXP',
			dataIndex: 'taxp',
			key: 'taxp',
		},
	];

	const hsnColumns = [
		{
			title: 'SACCD',
			dataIndex: 'saccd',
			key: 'saccd',
		},
		{
			title: 'SDES',
			dataIndex: 'sdes',
			key: 'sdes',
		},
	];

	return (
		<div
			style={{
				display: 'block',
				width: 700,
				padding: 30,
				background: '#a8a29e',
			}}
		>
			<>
				{rowData != {} && (
					<Drawer
						// title={title}
						placement="right"
						closable={false}
						visible={visible}
						onClose={onClose}
					>
						{rowDataLoading && rowData == {} && (
							<p>no records found</p>
						)}
						{rowData != {} && !rowDataLoading ? (
							<div className="rowData">
								<>
									{rowData.status && (
										<div className="topInfo">
											<div className="status">
												<p>
													GSTIN :
													<span
														style={{
															fontWeight: '800',
															color: 'black',
															fontSize: '16px',
														}}
													>
														{rowData.gstin}
													</span>
												</p>
												<p>
													Trade Name :{' '}
													{rowData && rowData.status && (
														<span
															style={{
																fontWeight:
																	'800',
																color: 'black',
																fontSize:
																	'16px',
															}}
														>
															{
																rowData.status
																	.tradeName
															}
														</span>
													)}
												</p>
											</div>
											<div className="status">
												<p>
													Auth Status :{' '}
													{rowData &&
														rowData.status &&
														(rowData.status
															.authStatus ==
														'A' ? (
															<span className="active">
																Active
															</span>
														) : (
															<span className="inactive">
																InActive
															</span>
														))}
												</p>
												<p>
													EINV Status :{' '}
													{rowData &&
														rowData.status &&
														(rowData.status
															.einvStatus ==
														'Y' ? (
															<span className="active">
																Active
															</span>
														) : (
															<span className="inactive">
																InActive
															</span>
														))}
												</p>
											</div>
										</div>
									)}
									<div className="table">
										<>
											{rowData && rowData.filingStatus && (
												<>
													<h1
														style={{
															color: 'teal',
														}}
													>
														Filing Status
													</h1>
													{Object.keys(
														pivotedData,
													).map(rtntype => (
														<div key={rtntype}>
															<h2>{rtntype}</h2>
															<Table
																dataSource={
																	pivotedData[
																		rtntype
																	]
																}
																columns={
																	filingColumns
																}
																pagination={
																	false
																}
																style={{
																	cursor:
																		'pointer',
																	background:
																		'white',
																}}
															/>
														</div>
													))}
												</>
											)}
											{rowData && rowData.hsn && (
												<>
													<h1
														style={{
															color: 'teal',
															marginTop: '50px',
														}}
													>
														HSN
													</h1>
													<Table
														dataSource={rowData.hsn}
														columns={hsnColumns}
														pagination={false}
														style={{
															cursor: 'pointer',
															background: 'white',
														}}
													/>
												</>
											)}
										</>
									</div>
								</>
							</div>
						) : (
							<Spin size="large" />
						)}
					</Drawer>
				)}
			</>
		</div>
	);
}
