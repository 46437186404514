export const META_FIELDS = [
	'_id',
	'_index',
	'_type',
	'tableName',
	'workspaceName',
	'highlight',
	'workspaceFolderId',
	'Airline.keyword',
	'Customer_Name.keyword',
	'_score',
	'_doc',
	'Tab_Name',
];

export const DATE_FIELDS = ['Invoice_Date', 'Booking_Date'];

export const NUMBER_FIELDS = [
	'GST_Amount',
	'Total_GST',
	'Total GST',
	'TaxableValue',
	'Taxable_Amount',
	'Transaction_Amount_INR',
	'Transaction_Amount',
];

export const BOOLEAN_FIELDS = [];

export const INVOICE_STATUS = ['Wrong Ticket', 'SOTO', 'SSR Not Updated'];

export const OBJECT_FIELDS = ['Comments'];

export const INITIAL_FIELDS = [
	'Airline',
	'Customer_Name',
	'Invoice_Number',
	'Invoice_Date',
	'Transaction_Amount',
];
export const INITIAL_FIELDS_NOT_AS_PER_BOOKING = [
	'Airline',
	'Customer_Name',
	'Ticket_Or_PNR',
	'Invoice_Date',
	'Transaction_Amount',
];
