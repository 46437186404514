import MatcherComponent from "../components/cellRenderers/matcherComponent";
import StatusComponent from "../components/cellRenderers/statusComponent";
import TextLinkComponent from "../components/cellRenderers/textLinkRenderer";
import { getGroupedColumnStatus, openVendorScoreDashboard, togglePdfSidePanel, getInvoiceAmountDiff, getInvoiceAmountDiffPercent } from './ReconColumnHelper';
import { MATCH_STATUSES } from "../constants/matchStatuses";

export const columnDefs = [
    {
        headerName: 'Vendor',
        children: [
            {
                field: 'Registered Vendor Name', key: 'Registered Vendor Name', cellRenderer: TextLinkComponent, enableRowGroup: true,
                cellRendererParams: (params) => {
                    return {
                        label: params.value,
                        handleClick: openVendorScoreDashboard
                    }
                }
            },
            { field: 'Registered Vendor Name', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Vendor GSTIN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Vendor Type', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Vendor State', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Eligibility Status', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Eligibility Date', columnGroupShow: 'open', enableRowGroup: true },
        ]
    },

    {
        headerName: 'Customer',
        children: [
            {
                field: 'Registered Customer Name', key: 'Registered Customer Name', enableRowGroup: true,
                cellRendererParams: (params) => {
                    return {
                        label: params.value
                    }
                }
            },
            { field: 'Registered Customer Name', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Customer GSTIN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'GST Credentials Status', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Customer State', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Workspace', columnGroupShow: 'open', enableRowGroup: true },
        ]
    },
    {
        headerName: '2B',
        children: [
            {
                field: '2B Match Status', key: '2B Match Status', cellRenderer: StatusComponent, enableRowGroup: true,
                filterParams: {
                    values: [
                        MATCH_STATUSES.MISMATCH,
                        MATCH_STATUSES.MISSING,
                        MATCH_STATUSES.MATCHED
                    ],
                }
            },
            { field: '2B - Vendor GSTIN', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Customer GSTIN', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Filing Period', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Invoice No', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Invoice Dt', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Note No', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Note Dt', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Doc Type', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Invoice Type', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - POS', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Items', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Taxable Amt', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Rate', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - CGST', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - SGST', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - IGST', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Total GST', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Total Amt', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - Source Type', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - IRN No', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - IRN Dt', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - R1 Filing Dt', columnGroupShow: 'open', enableRowGroup: true },
            { field: '2B - R1 Filing Period', columnGroupShow: 'open', enableRowGroup: true },
        ]
    },
    {
        headerName: 'Invoice',
        children: [
            {
                field: 'Invoice Match Status', key: 'Invoice Match Status', cellRenderer: StatusComponent, enableRowGroup: true,
                filterParams: {
                    values: [
                        MATCH_STATUSES.MISMATCH,
                        MATCH_STATUSES.MISSING,
                        MATCH_STATUSES.MATCHED
                    ],
                }
            },
            {
                field: 'invoice_link', key: 'invoice_link', columnGroupShow: 'open', enableRowGroup: true,
                cellRenderer: TextLinkComponent,
                cellRendererParams: () => {
                    return {
                        label: 'View Invoice',
                        handleClick: togglePdfSidePanel
                    }
                },
            },
            { field: 'Invoice - Customer GSTIN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Customer Name', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Customer Email', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Customer PIN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Customer Address', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Vendor GSTIN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Vendor Name', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Vendor Email', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Vendor Phone', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Vendor PIN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Vendor Address', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Invoice Type', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Invoice No', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Invoice Dt', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - HSN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Items', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Rate', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Taxable Amt', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - CGST', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - SGST', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - IGST', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Total GST', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - Total Amt', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - IRN No', columnGroupShow: 'open', enableRowGroup: true },
        ]
    },
    {
        headerName: 'IRN',
        children: [
            {
                field: 'IRN Match Status', key: 'IRN Match Status', cellRenderer: StatusComponent, enableRowGroup: true,
                filterParams: {
                    values: [
                        MATCH_STATUSES.MISMATCH,
                        MATCH_STATUSES.MISSING,
                        MATCH_STATUSES.MATCHED
                    ],
                }
            },
            { field: 'IRN - Customer GSTIN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'IRN - Vendor GSTIN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'IRN - Invoice No', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'IRN - Invoice Dt', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'IRN - Invoice Type', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'IRN - Items', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'IRN - Total Amt', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'IRN - IRN Dt', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Invoice - IRN No', columnGroupShow: 'open', enableRowGroup: true }
        ]
    },
    {
        headerName: 'Validation',
        children: [
            {
                field: 'Remarks', key: 'Remarks', enableRowGroup: true,
                cellRendererParams: (params) => {
                    return {
                        label: params.value
                    }
                }
            },
            { field: 'Match Status', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Eligible invoice In 2A but E-invoice not generated', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Can Claim ITC? 996311', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Registered Vendor Name Difference?', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Is 2B Delayed?', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Is Vendor & Customer State Different?', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Is IRN Genration delayed?', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Is Vendor GSTIN Diff in 2B & IRN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Is Customer GSTIN Diff in 2B & IRN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Is GST Amt Diff in 2B & IRN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Is Invoice Dt Diff in 2B & IRN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Is Invoice No Diff in 2B & IRN', columnGroupShow: 'open', enableRowGroup: true },
            { field: 'Is Invoice Number Different in 2B & Invoice?', columnGroupShow: 'open', enableRowGroup: true },
        ]
    }
]