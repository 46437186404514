import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ReactiveList, ReactiveComponent } from '@appbaseio/reactivesearch';
import { List, Spin } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Flex from '../styled/Flex';
import ExportCSV from '../ExportCSV';
import EmailReport from '../EmailReport';
import ScheduleReport from '../ScheduleReport';

const Activity = ({ workspace }) => {
	const query = {
		query: {
			term: {
				_index: 'shopelect-activity-log',
			},
		},
	};

	const currentView = 'Activity Logs';
	const termColumn =
		workspace.Filter_Column === 'Airline'
			? 'Airline.keyword'
			: 'Customer_Name.keyword';
	const termValue = workspace.Filter_Value;

	const isCustomer = workspace.Filter_Column === 'Customer_Name';
	const defaultQuery =
		termValue === 'Admin'
			? query
			: {
					query: {
						bool: {
							must: [
								{
									term: {
										[`meta.${termColumn}`]: termValue,
									},
								},
								{
									term: {
										_index: 'shopelect-activity-log',
									},
								},
							],
						},
					},
			  };
	return (
		<div
			style={{
				textAlign: 'left',
				position: 'relative',
			}}
		>
			<Flex alignItems="center" justifyContent="space-between">
				<Flex style={{ minWidth: '40%' }}>
					<h2>Activity Logs</h2>
				</Flex>
				<Flex>
					{isCustomer ? (
						<>
							<ExportCSV
								query={query ? query.query : null}
								index="shopelect-activity-log"
							/>
							<EmailReport
								currentView={currentView}
								query={query ? query.query : null}
								index="shopelect-activity-log"
							/>
							<ScheduleReport
								currentView={currentView}
								query={query ? query.query : null}
								index="shopelect-activity-log"
							/>
						</>
					) : (
						<>
							<ExportCSV
								query={query ? query.query : null}
								index="shopelect-activity-log"
							/>
						</>
					)}
				</Flex>
			</Flex>
			<ReactiveComponent
				customQuery={() => defaultQuery}
				componentId="baseQuery"
			/>
			<ReactiveList
				componentId="ActivityResult"
				dataField="createdAt"
				sortBy="desc"
				size={10}
				pagination
				innerClass={{
					pagination: 'custom-pagination',
				}}
				react={{
					and: ['baseQuery'],
				}}
				loader={
					<div
						style={{
							position: 'absolute',
							left: '50%',
							top: '40%',
						}}
					>
						<Spin />
					</div>
				}
				renderAllData={({ results }) => (
					<List
						bordered
						dataSource={results}
						itemLayout="horizontal"
						renderItem={item => (
							<List.Item key={item._id}>
								<List.Item.Meta
									title={item.context}
									description={
										<div>
											{item.context === 'Status' && (
												<Flex justifyContent="space-between">
													<div>
														{' '}
														Status for Invoice{' '}
														{item.dataId} is{' '}
														<i>{item.status}</i>
													</div>
													<div>
														{moment(
															item.createdAt,
														).format(
															'DD-MM-YYYY HH:mm a',
														)}
													</div>
												</Flex>
											)}

											{item.context === 'Comment' && (
												<Flex justifyContent="space-between">
													<div>
														{' '}
														Comment for Invoice{' '}
														{item.dataId} -{' '}
														<i>
															{
																item.comment
																	.content
															}
														</i>
													</div>
													<div>
														{moment(
															item.createdAt,
														).format(
															'DD-MM-YYYY HH:mm a',
														)}
													</div>
												</Flex>
											)}

											{item.context ===
												'Invoice Upload' && (
												<Flex justifyContent="space-between">
													<div>
														{' '}
														File upload for Invoice{' '}
														{item.dataId} is &nbsp;
														<i>
															<Link
																to={`/file-preview/${
																	item.files
																		.fileId
																}`}
																target="_blank"
															>
																{
																	item.files
																		.original_filename
																}
															</Link>
														</i>
													</div>
													<div>
														{moment(
															item.createdAt,
														).format(
															'DD-MM-YYYY HH:mm a',
														)}
													</div>
												</Flex>
											)}

											<div>
												<b>By</b>: {item.email}
											</div>
											<div>
												<b>
													Organization of uploader:{' '}
												</b>{' '}
												{termColumn === 'Airline'
													? (item.meta || {}).Airline
													: (item.meta || {})
															.Customer_Name}
											</div>
											<div>
												<b>Visible to: </b>{' '}
												{
													(item.meta || {})
														.Customer_Name
												}
												,&nbsp;
												{termColumn === 'Airline'
													? (item.meta || {})
															.Customer_Name
													: (item.meta || {}).Airline}
											</div>
										</div>
									}
								/>
							</List.Item>
						)}
					/>
				)}
			/>
		</div>
	);
};

const mapStateToProps = state => ({
	workspace: state.workspace,
});
export default connect(mapStateToProps)(Activity);
