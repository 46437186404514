import Api from '../helpers/api';
import { formatData } from '../helpers';
import Auth from '../services/Auth';
import Q from 'q';

import _ from 'lodash';

import admins from '../helpers/admins';

const api = Api();
const auth = new Auth();

export const getHeaders = () => {
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${auth.getAccessToken()}`,
		'ngrok-skip-browser-warning': 40, 
	};
	return headers;
};

export const FETCH_ALL_PURCHASE_ORDERS = 'FETCH_ALL_PURCHASE_ORDERS';
export const FETCH_ALL_PURCHASE_ORDERS_SUCCESS =
	'FETCH_ALL_PURCHASE_ORDERS_SUCCESS';

export const FETCH_PURCHASE_ORDER = 'FETCH_PURCHASE_ORDER';
export const RESET_PURCHASE_ORDER = 'RESET_PURCHASE_ORDER';
export const FETCH_PURCHASE_ORDER_SUCCESS = 'FETCH_PURCHASE_ORDER_SUCCESS';

export const FETCH_ALL_CONTACTS_SUCCESS = 'FETCH_ALL_CONTACTS_SUCCESS';

export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';

export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER';
export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';

export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_REQUEST_COMPLETED =
	'FETCH_CONTACTS_REQUEST_COMPLETED';
export const CREATE_CONTACTS_SUCCESS = 'CREATE_CONTACTS_SUCCESS';

export const SET_CONTACT_ID = 'SET_CONTACT_ID';

export const SET_SUB_REPORT = 'SET_SUB_REPORT';

export const FETCH_DOC_TYPES = 'FETCH_DOC_TYPES';

const columnNames = {
	docTypeName: 'Document Type Name',
	tableName: 'Table Name',
	workspaceName: 'Workspace Name',
	docTypeId: 'Document Type ID',
};
export const SET_CUSTOMER_DOMAIN = 'SET_CUSTOMER_DOMAIN';

export const SET_WORKSPACE = 'SET_WORKSPACE';

export const SET_AGGREGATIONS = 'SET_AGGREGATIONS';

export const SET_CWT_ROLE = 'SET_CWT_ROLE';

export function setSubReport(subReport) {
	return {
		type: SET_SUB_REPORT,
		subReport,
	};
}

export function fetchAllPurchaseOrdersSuccess(purchaseorders) {
	return {
		type: FETCH_ALL_PURCHASE_ORDERS_SUCCESS,
		purchaseorders,
	};
}

export function fetchAllPurchaseOrders(vendorId, userType) {
	return dispatch =>
		fetch(
			`${
				api.api
			}/purchaseorders?vendor_id=${vendorId}&user_type=${userType}`,
			{
				headers: getHeaders(),
			},
		)
			.then(res => res.json())
			.then(json => dispatch(fetchAllPurchaseOrdersSuccess(json)))
			.catch(error => {
				throw error;
			});
}

export function fetchAllContactsSuccess(contacts) {
	return {
		type: FETCH_ALL_CONTACTS_SUCCESS,
		contacts,
	};
}

export function fetchAllContacts() {
	return dispatch =>
		fetch(`${api.api}/contacts`, {
			headers: getHeaders(),
		})
			.then(res => res.json())
			.then(json => dispatch(fetchAllContactsSuccess(json)))
			.catch(error => {
				throw error;
			});
}

export function fetchPurchaseOrderSuccess(purchaseOrder) {
	return {
		type: FETCH_PURCHASE_ORDER_SUCCESS,
		purchaseOrder,
	};
}

export function fetchPurchaseOrder(orderId) {
	return dispatch =>
		fetch(`${api.api}/purchaseorder/${orderId}`, {
			headers: getHeaders(),
		})
			.then(res => res.json())
			.then(json => dispatch(fetchPurchaseOrderSuccess(json)))
			.catch(error => {
				throw error;
			});
}

export function resetPurchaseOrder() {
	return {
		type: RESET_PURCHASE_ORDER,
	};
}

export function updatePurchaseOrder(purchaseorder) {
	return {
		type: UPDATE_PURCHASE_ORDER,
		purchaseorder,
	};
}

export function fetchUserInfoSuccess(user) {
	return {
		type: FETCH_USER_INFO_SUCCESS,
		user,
	};
}

export const isPaid = (plan = 'free') =>
	plan.toString().trim() !== '' && plan.toString().toLowerCase() !== 'free';

export function fetchUserInfo(contactId) {
	return dispatch =>
		fetch(`${api.api}/contacts/${contactId}`, {
			headers: getHeaders(),
			method: 'GET',
		})
			.then(res => res.json())
			.then(json => {
				console.log('res is: ', json);
				fetch(`${api.api}/subscription?customer_id=${contactId}`, {
						headers: getHeaders(),
					})
					.then(res => res.json())
					.then(data => {
						console.log(
							'subscription response: ',
							data.subscriptions,
						);
						const isAdmin = admins.indexOf(json.contact.email) !== -1;
						dispatch(
							fetchUserInfoSuccess({
								...json,
								...{
									contact: {
										...json.contact,
										isAdmin,
										sub: data.subscriptions,
										cf_onboarding: new Set(_.get(json, 'contact.cf_onboarding', '').split(',').map(s => s.trim().toLowerCase()))
									},
								},
							}),
						);
					});
			})
			.catch(error => {
				throw error;
			});
}

export const saveUser = user => dispatch => dispatch(fetchUserInfoSuccess({ contact: user }));

export const updateUserInfo = body => {
	const id = auth.getContactId();
	return fetch(`${api.api}/contacts/${id}`, {
		method: 'PUT',
		headers: getHeaders(),
		body: JSON.stringify(body)
	}).then(res => res.json());
}

export function fetchAllDocTypes(domain) {
	return dispatch => {
		const api = new Api();
		return fetch(`${api.api}/doctype?enp=true&domain=${domain}`, {
			headers: getHeaders(),
		})
			.then(res => res.json())
			.then(json => {
				if (_.isEmpty(json.docs)) {
					throw Error('Docs not found.');
				}
				dispatch(fetchDocTypesSuccess(json.docs));
			})
			.catch(error => {
				throw error;
			});
	}
}

export function fetchDocTypesSuccess(docs) {
	let payload = [];
	try {
		payload = formatData(docs.column_order, docs.rows).map(doc => ({
			value: doc[columnNames.docTypeName],
			label: doc[columnNames.docTypeName],
		}));
	} catch (err) {
		console.log(err);
	}
	return {
		type: FETCH_DOC_TYPES,
		payload,
	};
}

export function fetchContactsSuccess(contacts) {
	return {
		type: FETCH_CONTACTS_SUCCESS,
		contacts,
	};
}

export function fetchContactsRequest() {
	return {
		type: FETCH_CONTACTS_REQUEST,
	};
}

export function fetchContactsRequestCompleted() {
	return {
		type: FETCH_CONTACTS_REQUEST_COMPLETED,
	};
}

export function fetchContacts(contactId) {
	return dispatch => {
		dispatch(fetchContactsRequest());
		return fetch(`${api.api}/contacts/list?contactId=${contactId}`, {
			headers: getHeaders(),
			method: 'GET',
		})
			.then(res => res.json())
			.then(json => {
				dispatch(fetchContactsSuccess(json.contacts));
			})
			.catch(error => {
				dispatch(fetchContactsRequest());
				console.log(error);
				throw error;
			});
	};
}

export function setContactId(contactId) {
	return {
		type: SET_CONTACT_ID,
		contactId,
	};
}

export function fetchReportsSuccess(reports) {
	return {
		type: FETCH_REPORTS_SUCCESS,
		reports,
	};
}

export function fetchAllReports(vendorId, userType) {
	let promises = [];
	promises.push(
		fetch(
			`${
				api.api
			}/purchaseorders?vendor_id=${vendorId}&user_type=${userType}`,
			{
				headers: getHeaders(),
			},
		).then(res => {
			return res.json();
		}),
	);
	promises.push(
		fetch(`${api.api}/bills?vendor_id=${vendorId}&user_type=${userType}`, {
			headers: getHeaders(),
		}).then(res => {
			return res.json();
		}),
	);

	return dispatch =>
		Q.all(promises)
			.then(json => {
				const reports = [];
				const purchaseOrders = json[0].purchaseorders;
				const bills = json[1].bills;

				purchaseOrders.forEach(order => {
					reports.push({
						purchaseorder_number: order.purchaseorder_number,
						vendor_name: order.vendor_name,
						date: order.date,
						status: 'Purchase Order ' + order.status,
						enddate: order.delivery_date,
					});
				});

				bills.forEach(bill => {
					reports.push({
						purchaseorder_number: bill.bill_number,
						vendor_name: bill.vendor_name,
						date: bill.date,
						status: 'Bill ' + bill.status,
						enddate: bill.date,
					});
				});
				dispatch(fetchReportsSuccess(reports));
			})
			.catch(error => {
				throw error;
			});
}

export function setCustomerDomain(domain) {
	return {
		type: SET_CUSTOMER_DOMAIN,
		domain,
	};
}

export function setWorkspace(workspace) {
	return {
		type: SET_WORKSPACE,
		workspace,
	};
}

export function setCWTRole(cwt_role) {
	return {
		type: SET_CWT_ROLE,
		cwt_role,
	};
}

export function setAggregations(aggregations) {
	return {
		type: SET_AGGREGATIONS,
		aggregations,
	};
}
