import React, { Component } from 'react';

import DataGrid from './DataGrid';

class DataTable extends Component {
	render() {
		const {
			columns,
			data,
			height,
			width,
			headerRef,
			stats,
			pageSize,
			currentView,
		} = this.props;

		return Boolean(data.length) ? (
			<DataGrid
				data={data}
				visibleColumns={columns}
				height={height}
				width={width}
				headerRef={headerRef}
				stats={stats}
				pageSize={pageSize}
				currentView={currentView}
			/>
		) : null;
	}
}

export default DataTable;
