import { NUMBER_FIELDS, META_FIELDS } from '../constants';
import { getStringColumns } from './utils';

export const generateAggsQuery = columns => {
	const stringFields = getStringColumns(columns);
	const filteredColumns = columns.filter(
		item =>
			META_FIELDS.indexOf(item) === -1 &&
			(stringFields.indexOf(item) > -1 ||
				NUMBER_FIELDS.indexOf(item) > -1),
	);

	const query = {
		size: 0,
		aggs: {},
	};

	filteredColumns.forEach(item => {
		// aggs for string field
		if (stringFields.indexOf(item) > -1) {
			query.aggs[item] = {
				cardinality: {
					field: `${item}.keyword`,
				},
			};
		} else {
			// aggs for number field
			query.aggs[item] = {
				sum: {
					field: item,
				},
			};
		}
	});

	return query;
};

export const getTableQuery = (termColumn, termValue) => {
	const query = {
		size: 0,
		aggs: {
			tables: {
				terms: {
					field: `Tab_Name.keyword`,
					size: 1000,
				},
			},
		},
		query: {
			bool: {
				must: [
					{
						term: {
							[termColumn]: termValue,
						},
					},
				],
				must_not: [
					{
						terms: {
							'tableName.keyword': [
								'Filter view',
								'Invoice Received But Not In 2A-E1',
								'Invoice Not Received But In 2A-E2',
								'Invoices Not Received As Per Booking-E3',
							],
						},
					},
				],
			},
		},
	};

	return query;
};
