import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './stepper.css';
import {
	loadCaptchaEngine,
	LoadCanvasTemplate,
	validateCaptcha,
} from 'react-simple-captcha';
import * as XLSX from 'xlsx';
import bilingIllustration from '../../assets/billing_illustration.avif';
import SampleCSVDownloadLink from '../../assets/samplecreds.xlsx';


const Step2 = ({ handleProceed, customerDomain }) => {
	const headers = {
		'Content-Type': 'application/json',
		'ngrok-skip-browser-warning': 40,
	};
	const main_url = 'https://credentials.finkraft.ai/'
	const [captchaValue, setCaptchaValue] = useState('');
	const [formData, setFormData] = useState({
		gstin:'',
		username: '',
		password: '',
	});
	const [errors, setErrors] = useState({});
	const [captchaMsg, setCaptchaMsg] = useState('');
	const [isBulkUpload, setIsBulkUpload] = useState(false);
	const [tableRows, setTableRows] = useState([]);
	const [values, setValues] = useState([]);
	const [apiValues, setApiValues] = useState([]);

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = (event) => {
			const binaryData = event.target.result;
			const workbook = XLSX.read(binaryData, { type: 'binary' });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

			if (parsedData.length > 0) {
				const headers = parsedData[0];
				const isValidFormat =
					headers.length === 3 && headers[0] === 'gstin' && headers[1] === 'username' && headers[2] === 'password';

				if (isValidFormat) {
					setIsBulkUpload(!isBulkUpload)
					const transformedData = parsedData.slice(1).map((row) => ({
						customer_domain: customerDomain,
						gstin: row[0],
						username: row[1],
						password: row[2],
					}));

					setTableRows(headers);
					setValues(parsedData.slice(1));
					setApiValues(transformedData);
				} else {
					alert('Invalid XLSX format. Make sure the headers are in the correct format.')
					alert('Invalid XLSX format. Make sure the headers are in the correct format.');
				}
			} else {
				alert('Empty XLSX file. Please upload a valid file with data.');
			}
		};

		reader.readAsBinaryString(file);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const newErrors = {};
		if (formData.username.trim() === '') {
			newErrors.username = 'Username is required';
		}
		if (formData.password.trim() === '') {
			newErrors.password = 'Password is required';
		}
		if (formData.gstin.trim() === '') {
			newErrors.gstin = 'GSTIN is required';
		}

		if (Object.keys(newErrors).length === 0) {
			console.log('Form data:', formData);
		} else {
			setErrors(newErrors);
		}
	};


	const handleUpdateClick = () => {
		if(apiValues.length>0){
			fetch(main_url + 'credentials', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(apiValues)
			})
				.then(response => {
					if (response.ok) {
						return response.json();
					}
					throw new Error('Network response was not ok.');
				})
				.then(data => {
					handleProceed()
					// window.location.reload()
					// fetch(main_url + 'credentials/' + props.customerDomain, {
					// 	method: 'GET',
					// 	headers: headers, // Pass the custom headers here
					// })
					// 	.then((response) => response.json())
					// 	.then((data) => {
					// 		// Check if data length is greater than 0 before setting the state
					// 		if (data.length > 0) {
					// 			toast.info('Creds updated Successfully');
					// 			setCreds_data(data);
					// 		}
					// 	})
					// 	.catch((error) => {
					// 		toast.error('Error fetching creds_data:', error);
					// 	});
				})
				.catch(error => {
					console.log("error",error)
					alert('Error Uploading: Dublicate Data');
				});
		}else{
			const newErrors = {};
			if (formData.username.trim() === '') {
				newErrors.username = 'Username is required';
			}
			if (formData.password.trim() === '') {
				newErrors.password = 'Password is required';
			}

			if (Object.keys(newErrors).length === 0) {
				let formValues =[{customer_domain:customerDomain,gstin:formData.gstin.trim(),username:formData.username.trim(),password:formData.password.trim()}]
				fetch(main_url + 'credentials', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(formValues)
				})
					.then(response => {
						if (response.ok) {
							return response.json();
						}
						throw new Error('Network response was not ok.');
					})
					.then(data => {
						handleProceed()
					})
					.catch(error => {
						console.log("error",error)
						alert('Error Uploading: Dublicate Data');
					});
			} else {
				setErrors(newErrors);
			}
		}
	};

	return (
		<div className="step-container-info">
			<div className="step">
				<h1>It's time to add your business and watch it thrive!</h1>
				<p>Do you have multiple sets of credentials? Use our bulk upload!</p>
				<div className="upload-section">
					<div className="upload-buttons">
						<label htmlFor="file-upload" className="gst-button" style={{ width: "180px" }}>
							Upload Bulk Creds
						</label>
						<input
							id="file-upload"
							type="file"
							accept=".xlsx"
							style={{ display: 'none' }}
							onChange={handleFileChange}
						/>
						<Button size="small" href={SampleCSVDownloadLink} download="samplecreds.xlsx" style={{'width':"180px",'color':"white",'backgroundColor':"#212529"}}>
							Download Sample .xlsx
						</Button>
					</div>
				</div>
				<div class="separator">
				<div class="line"></div>
				{!isBulkUpload && (
				<h2>OR</h2>
				)}
				{isBulkUpload && values.length > 0 && (
					<h2>BULK CREDS</h2>
					)}
				<div class="line"></div>
				</div>
				<div className="login-form-container">
					<form onSubmit={handleSubmit}>
						{!isBulkUpload && (
							<div className="table-container">
								<div className="form-group">
									<label htmlFor="gstin">Gstin</label>
									<input type="text" id="gstin" name="gstin" value={formData.gstin} onChange={handleChange} />
									{errors.gstin && <div className="error" style={{color:"red"}}>{errors.gstin}</div>}
								</div>
								<div className="form-group">
									<label htmlFor="username">Username</label>
									<input type="text" id="username" name="username" value={formData.username} onChange={handleChange} />
									{errors.username && <div className="error" style={{color:"red"}}>{errors.username}</div>}
								</div>
								<div className="form-group">
									<label htmlFor="password">Password</label>
									<input type="password" id="password" name="password" value={formData.password} onChange={handleChange} />
									{errors.password && <div className="error" style={{color:"red"}}>{errors.password}</div>}
								</div>
							</div>
						)}
						{isBulkUpload && values.length > 0 && (
							<div className="table-container">
								<table>
									<thead>
										<tr>
											{tableRows.map((header, index) => (
												<th key={index}>{header}</th>
											))}
										</tr>
									</thead>
									<tbody>
										{values.map((row, index) => (
											<tr key={index}>
												{Object.values(row).map((cell, i) => (
													<td key={i}>{cell}</td>
												))}
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
						<button type="submit" className="gst-button" style={{"marginTop":"20px"} } onClick={handleUpdateClick}>
							Submit
						</button>
					</form>
				</div>
			</div>

			<div class="illustration-container" >
				<img
					alt="img"
					src={bilingIllustration}
					style={{ width: 'inherit', height: 'inherit' }}
				/>
			</div>
		</div>
	);
};

export default Step2;