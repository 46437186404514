import React from 'react';
import posed from 'react-pose';
import { Link } from 'react-router-dom';
// react icons
import FaDashboard from 'react-icons/lib/fa/dashboard';
// import FaCalculator from 'react-icons/lib/fa/calculator';
import FaDatabase from 'react-icons/lib/fa/database';
// import FaTicket from 'react-icons/lib/fa/ticket';
import FaFilePdfO from 'react-icons/lib/fa/file-pdf-o';
import FaFileText from 'react-icons/lib/fa/file-text';
import FaLock  from 'react-icons/lib/fa/lock';
import Upload  from 'react-icons/lib/fa/upload';
import FaArrowsAltH from "react-icons/lib/fa/arrows-alt";

// styled components
import NavContainer from './styled/NavContainer';
import NavListItem from './styled/NavListItem';
// import Column from './styled/Column';

// Auth
// import Auth from '../services/Auth';
// const auth = new Auth();
const Box = posed.div();

const Navbar = ({
	active,
	subReport,
	setSubReport,
	workspace,
	cwt_role,
}) => {
	return (
	<NavContainer>
		{cwt_role === 'cwt' ? (
			<Link to="/upload" onClick={() => setSubReport([])}>
				<NavListItem active={active === '/upload'}>
					<FaFileText />
					&nbsp;&nbsp;Upload
					</NavListItem>
			</Link>
		) :
			(
			<>
			{(
				<>
					<Link to="/" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/'}>
							<FaDashboard />
							&nbsp;&nbsp;Dashboard
						</NavListItem>
					</Link>
					<Link to="/reporting" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/reporting'}>
							<FaFilePdfO />
							&nbsp;&nbsp;Reporting
						</NavListItem>
					</Link>
					<Link to="/upload" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/upload'}>
							<Upload />
							&nbsp;&nbsp;Upload
						</NavListItem>
					</Link>
					<Link to="/files" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/files'}>
							<FaFileText />
							&nbsp;&nbsp;Invoices
						</NavListItem>
					</Link>
					<Link to="/creds" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/creds'}>
							<FaLock />
							&nbsp;&nbsp;Credentials
						</NavListItem>
					</Link>
					<Link to="/recon" onClick={() => setSubReport([])}>
						<NavListItem active={active === '/recon'}>
							<FaArrowsAltH />
							&nbsp;&nbsp;Reconcile
						</NavListItem>
					</Link>
					{!Array.isArray(subReport) && (
						<NavListItem active>
							<div style={{ paddingLeft: 25, color: "black" }}>{subReport}</div>
						</NavListItem>
					)}
				</>
			)}
			{/* <Link to="/reports" onClick={() => setSubReport([])}>
				<NavListItem active={active.indexOf('/reports') > -1}>
					<FaDatabase />
					&nbsp;&nbsp;Data
				</NavListItem>
			</Link> */}
			</>
		)}
	</NavContainer>
	)
};

export default Navbar;
