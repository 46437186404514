import React, { Component, createRef } from 'react';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import { Grid, ScrollSync } from 'react-virtualized';
import FaCaretRight from 'react-icons/lib/fa/caret-right';
import FaCaretLeft from 'react-icons/lib/fa/caret-left';

import StyledCell from './StyledCell';
import IdField from './IdField';
import Flex from '../styled/Flex';
import colors from './theme';

const OVERSCAN_COUNT = 3;
const HEIGHT_BUFFER = 63;
const CELL_STYLE = {
	borderRight: `0.5px solid ${colors.tableBorderColor}`,
	borderBottom: `1px solid ${colors.tableBorderColor}`,
	fontSize: '12px',
	padding: '10px',
};

const ID_WIDTH = 275;

class DataGrid extends Component {
	dataGridRef = createRef();

	dataScrollRef = createRef();

	state = {
		updateKey: Date.now(),
	};

	componentDidMount() {
		this.handleScrollLeft();
	}

	componentDidUpdate(prevProps) {
		this.handleScrollLeft();

		if (!_.isEqual(prevProps.data, this.props.data)) {
			this.setUpdateKey();
		}
	}

	setUpdateKey = () => {
		this.setState(prevState => ({
			updateKey: prevState.updateKey + 1,
		}));
	};

	handleScrollLeft = () => {
		const { headerRef } = this.props;
		if (
			headerRef &&
			headerRef.current &&
			headerRef.current.scrollLeft > 0
		) {
			this.dataScrollRef.current.scrollLeft(headerRef.current.scrollLeft);
		}
	};

	handleScroll = ({ target }) => {
		const { scrollTop, scrollLeft } = target;
		const { headerRef } = this.props;

		if (headerRef && headerRef.current) {
			headerRef.current.scrollLeft = scrollLeft;
		}
		// $FlowFixMe
		this.dataGridRef.current.handleScrollEvent({
			scrollTop,
			scrollLeft,
		});
	};

	_renderLeftSideCell = ({ key, rowIndex, style }) => {
		const { data, stats, pageSize, currentView } = this.props;

		return (
			<StyledCell
				key={key}
				style={{
					...style,
					background: colors.tableHead,
				}}
				width={ID_WIDTH}
			>
				<IdField
					rowIndex={rowIndex}
					data={data[rowIndex]}
					value={data[rowIndex]._id}
					stats={stats}
					pageSize={pageSize}
					currentView={currentView}
					logData={{
						Airline: data[rowIndex].Airline,
						Customer_Name: data[rowIndex].Customer_Name,
					}}
				/>
			</StyledCell>
		);
	};

	_renderDataCell = ({ columnIndex, key, rowIndex, style }) => {
		const { visibleColumns, data } = this.props;
		const column = visibleColumns[columnIndex];
		return (
			<StyledCell
				style={{
					...style,
					...CELL_STYLE,
				}}
				key={key}
				dangerouslySetInnerHTML={{
					__html: _.get(data[rowIndex], column),
				}}
			/>
		);
	};

	render() {
		const { data, height, width, visibleColumns } = this.props;
		const { updateKey } = this.state;
		const columns = visibleColumns;

		return (
			<div
				key={updateKey}
				style={{
					position: 'relative',
				}}
			>
				<ScrollSync>
					{({ onScroll, scrollTop, scrollLeft, scrollWidth }) => (
						<Flex wrap="nowrap">
							<FaCaretLeft
								type="left"
								style={{
									position: 'absolute',
									left: -20,
									top: -30,
									zIndex: 105,
									fontSize: '20px',
									cursor: 'pointer',
								}}
								onClick={() => {
									if (scrollLeft > 0) {
										if (scrollLeft - 100 < 0) {
											this.dataScrollRef.current.scrollLeft(
												scrollLeft -
													(scrollWidth - scrollLeft),
											);
										} else {
											this.dataScrollRef.current.scrollLeft(
												scrollLeft - 100,
											);
										}
									}
								}}
							/>
							<FaCaretRight
								type="right"
								style={{
									position: 'absolute',
									right: -20,
									top: -30,
									zIndex: 100,
									fontSize: '20px',
									cursor: 'pointer',
								}}
								onClick={() => {
									if (scrollLeft + 100 < scrollWidth) {
										this.dataScrollRef.current.scrollLeft(
											scrollLeft + 100,
										);
									} else {
										this.dataScrollRef.current.scrollLeft(
											scrollLeft +
												(scrollWidth - scrollLeft),
										);
									}
								}}
							/>
							<Grid
								overscanColumnCount={0}
								overscanRowCount={OVERSCAN_COUNT}
								cellRenderer={this._renderLeftSideCell}
								columnWidth={ID_WIDTH}
								columnCount={1}
								height={height - HEIGHT_BUFFER}
								rowHeight={45}
								style={{
									zIndex: '101 !important',
									backgroundColor: colors.tableHead,
									outline: 0,
									overflow: 'hidden !important',
								}}
								className="virtualized_id_col"
								rowCount={data.length}
								scrollTop={scrollTop}
								width={ID_WIDTH + 1}
							/>
							<Scrollbars
								ref={this.dataScrollRef}
								onScroll={this.handleScroll}
								style={{
									height: height - HEIGHT_BUFFER,
									width: width - ID_WIDTH,
								}}
							>
								<Grid
									overscanColumnCount={OVERSCAN_COUNT}
									overscanRowCount={OVERSCAN_COUNT}
									cellRenderer={this._renderDataCell}
									columnWidth={200}
									columnCount={columns.length}
									height={height - HEIGHT_BUFFER}
									rowHeight={45}
									rowCount={data.length}
									onScroll={onScroll}
									width={width - ID_WIDTH}
									style={{
										outline: 0,
										overflowX: false,
										overflowY: false,
									}}
									ref={this.dataGridRef}
								/>
							</Scrollbars>
						</Flex>
					)}
				</ScrollSync>
			</div>
		);
	}
}

export default DataGrid;
