import React, { useState } from 'react';
import { Select, notification } from 'antd';
import { connect } from 'react-redux';
import axios from 'axios';
import uuid4 from 'uuid/v4';
import moment from 'moment';

import { INVOICE_STATUS } from '../../constants';
import api from '../../helpers/api';
import Auth from '../../services/Auth';

const { Option } = Select;

const InvoiceStatus = ({
	defaultValue,
	docId,
	customerDomain,
	workspace,
	currentView,
	logData,
}) => {
	const [status, setStatus] = useState(defaultValue);
	const [isUpdating, setIsUpdating] = useState(false);
	const auth = new Auth();
	async function handleChange(value) {
		setIsUpdating(true);
		try {
			await axios.post(
				`${
					api().api
				}/airline-reports/shopelect-ui-updates/_doc/${docId}/_update`,
				{
					doc: {
						Status: value,
					},
					doc_as_upsert: true,
				},
				{
					headers: {
						'x-autho-token': auth.getAccessToken(),
						'x-domain': customerDomain,
					},
				},
			);
			setStatus(value);
			await axios.put(
				`${
					api().api
				}/airline-reports/shopelect-activity-log/_doc/${uuid4()}`,
				{
					dataId: docId,
					status: value,
					context: 'Status',
					table: currentView,
					email: window.localStorage.getItem('email'),
					workspace,
					meta: logData,
					createdAt: moment(),
				},
				{
					headers: {
						'x-autho-token': auth.getAccessToken(),
						'x-domain': customerDomain,
					},
				},
			);
			setIsUpdating(false);
		} catch (err) {
			console.error(err);
			notification.error({
				message: 'Error',
				description: 'Unable to update status',
			});
			setIsUpdating(false);
		}
	}
	return (
		<>
			<Select
				value={
					status ? status.replace(/<\/?[^>]+(>|$)/g, '') : undefined
				}
				onChange={handleChange}
				placeholder="Select Status"
				style={{ width: '150px' }}
				disabled={isUpdating}
				loading={isUpdating}
			>
				{INVOICE_STATUS.map(item => (
					<Option key={item} value={item}>
						{item.replace(/<\/?[^>]+(>|$)/g, '')}
					</Option>
				))}
			</Select>
		</>
	);
};
const mapStateToProps = state => ({
	customerDomain: state.customerDomain,
	workspace: state.workspace,
});
export default connect(mapStateToProps)(InvoiceStatus);
