import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Icon, Upload, message, Tooltip } from 'antd';
import api from '../../helpers/api';

const UploadInvoice = ({
	data,
	docId,
	logData,
	workspace,
	currentView,
	handleChangeState,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	function closeAndReset() {
		setIsModalOpen(false);
	}

	const props = {
		name: 'file',
		multiple: false,
		action: `${api().api}/s3/upload`,
		data: {
			airline_name: data.Airline || 'No_Vendor',
			AirlineName: data.Airline || 'No_Vendor',
			customer_domain: data.Customer_Name,
			uploader_email: window.localStorage.getItem('email'),
			customer_folder_id: data.workspaceFolderId,
			doc_id: docId,
			log_info: JSON.stringify({
				workspace,
				table: currentView,
			}),
		},
		onChange(info) {
			const status = info.file.status;
			if (status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (status === 'done') {
				// info.file.response.data[0].fileId response from api
				console.log('file Id', info.file.response.data[0].fileId);
				handleChangeState(info.file.response.data[0].fileId);
				message.success(
					`${info.file.name} file uploaded successfully.`,
				);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
	};
	return (
		<>
			<Tooltip title="Add new Invoice">
				<Icon
					type="cloud-upload"
					style={{
						cursor: 'pointer',
						marginLeft: 10,
						fontSize: 20,
						color: '#1890ff',
					}}
					onClick={() => {
						setIsModalOpen(!isModalOpen);
					}}
				/>
			</Tooltip>
			<Modal
				title="Upload Invoice"
				visible={isModalOpen}
				onCancel={closeAndReset}
				footer={null}
			>
				<Upload.Dragger {...props}>
					<p className="ant-upload-drag-icon">
						<Icon type="inbox" />
					</p>
					<p className="ant-upload-text">
						Click or drag file to this area to upload
					</p>
					<p className="ant-upload-hint">
						Support for a single or bulk upload.
					</p>
				</Upload.Dragger>
			</Modal>
		</>
	);
};

const mapStateToProps = state => ({
	workspace: state.workspace,
});

export default connect(mapStateToProps)(UploadInvoice);
