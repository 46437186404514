const api = (staging = false) => {
	const protocol = 'https:';
	if (process.env.NODE_ENV === 'development') {
		return {
			api: `${protocol}//middleware.finkraft.ai`,
			callback: `${window.location.origin}/callback`,
		};
	}
	if (staging) {
		return {
			api: `${protocol}//middleware.finkraft.ai`,
			callback: `${window.location.origin}/callback`,
		};
	}
	return {
		api: `${protocol}//middleware.finkraft.ai`,
		callback: `${window.location.origin}/callback`,
	};
};

export default api;

// updated file
