import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Table, Modal, Button } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.css';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { SpinnerRoundFilled } from 'spinners-react';
import axios from 'axios';
import RowView from '../components/RowView';
import StepperProgress from '../components/Stepper';
import { Icon, Spin } from 'antd';
import Flex from '../components/styled/Flex';

// Rest of your component code remains unchanged

const headers = {
	'Content-Type': 'application/json',
	'ngrok-skip-browser-warning': 40,
};

const main_url = 'https://credentials.finkraft.ai/'

const CredContainer = props => {
	const [creds_data, setCreds_data] = useState([]);
	useEffect(() => {
		// Fetch creds_data using an API call
		setIsTableLoading(true);

		fetch(main_url + 'credentials/' + props.customerDomain, {
			method: 'GET',
			headers: headers, // Pass the custom headers here
		})
			.then(response => response.json())
			.then(data => {
				setIsTableLoading(false);

				// Check if data length is greater than 0 before setting the state
				if (data.length > 0) {
					setCreds_data(data);
					setShowTable(true);
				} else if (data.length === 0) {
					showModal();
				}
			})
			.catch(error => {
				console.error('Error fetching creds_data:', error);
			});
	}, [props.customerDomain]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [csvData, setCsvData] = useState(null);
	const [csvFileInfo, setCsvFileInfo] = useState(null);
	const [isRowClicked, setIsRowClicked] = useState(false);
	const [isTableView, setIsTableView] = useState(true);
	const [visible, setVisible] = useState(false);
	const [rowData, setRowData] = useState({});
	const [rowDataLoading, setRowDataLoading] = useState(false);

	const [showTable, setShowTable] = useState(false);
	const [isTableLoading, setIsTableLoading] = useState(true);

	const showModal = () => {
		setIsModalVisible(true);
		setIsTableView(false);
		setVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleFileChange = e => {
		const file = e.target.files[0];
		Papa.parse(file, {
			complete: result => {
				const headers = result.data[0];
				const isValidFormat =
					headers.length === 2 &&
					headers[0] === 'username' &&
					headers[1] === 'password';
				if (isValidFormat) {
					const parsedData = result.data.slice(1); // Exclude headers row
					const transformedData = parsedData.map(row => {
						return {
							customer_domain: props.customerDomain,
							username: row[0],
							password: row[1],
						};
					});

					console.log('Transformed Data:', transformedData);
					// Now you can use transformedData for further processing or set it in your state variable
					setCsvData(transformedData);
					setCsvFileInfo({ name: file.name, size: file.size });
				} else {
					toast.error('Invalid CSV format');
				}
			},
		});
	};

	const columns = [
		{
			title: <span style={{ fontWeight: 'bold' }}>Username</span>,
			dataIndex: 'username',
			key: 'username',
		},
		{
			title: 'Password',
			dataIndex: 'password',
			key: 'password',
			render: (text, record) => <span>********</span>,
		},
	];

	const creds_columns = [
		{
			title: 'GST',
			dataIndex: 'gst',
			key: 'gst',
			render: (text, record) => {
				return (
					<span style={{fontWeight: 'bold', color: '#2d373c' }}>
						{record.gst}
					</span>
				);
			}
		},
		{
			title: 'Username',
			dataIndex: 'username',
			key: 'username',
			render: (text, record) => {
				return (
					<span style={{ color: '#2d373c' }}>{record.username}</span>
				);
			},
		},
		{
			title: 'Business Name',
			dataIndex: 'business_name',
			key: 'business_name',
		},
		{
			title: 'Eligibility Date',
			dataIndex: 'eligibilitydate',
			key: 'eligibilitydate',
		},
		{
			title: 'Agg Turn-Over',
			dataIndex: 'aggturnover',
			key: 'aggturnover',
		},
		{
			title: 'Reg Type',
			dataIndex: 'regtype',
			key: 'regtype',
		},
		{
			title: 'Auth Status',
			dataIndex: 'authstatus',
			key: 'authstatus',
			render: (text, record) => {
				let icon = null;
				let statusText = '';
				switch (record.authstatus) {
					case 'ACTIVE':
						icon = <CheckCircleOutlined style={{ color: 'green' }} />;
						statusText = 'Active';
						break;
					case 'INACTIVE':
						icon = <CloseCircleOutlined style={{ color: 'red' }} />;
						statusText = 'Inactive';
						break;
					default:
						icon = null;
						statusText = 'Unknown';
						break;
				}

				return (
					<span style={{fontWeight: 'bold', color: '#2d373c' }}>
						{icon} {statusText}
					</span>
				);
			}
		},
		{
			title: 'E-invoice Status',
			dataIndex: 'einvoicestatus',
			key: 'einvoicestatus',
			render: (text, record) => {
				let icon = null;
				let statusText = '';
				switch (record.einvoicestatus) {
					case 'ACTIVE':
						icon = <CheckCircleOutlined style={{ color: 'green' }} />;
						statusText = 'Active';
						break;
					case 'INACTIVE':
						icon = <CloseCircleOutlined style={{ color: 'red' }} />;
						statusText = 'Inactive';
						break;
					default:
						icon = null;
						statusText = 'Unknown';
						break;
				}

				return (
					<span style={{fontWeight: 'bold', color: '#2d373c' }}>
						{icon} {statusText}
					</span>
				);
			}
		},
		{
			title: 'Integration Status',
			dataIndex: 'status',
			key: 'status',
			render: (text, record) => {
				let icon = null;
				let statusText = '';
				let textColor = '';
				let bg = '';
				switch (record.status) {
					case 'PENDING':
						icon = (
							<SpinnerRoundFilled
								size={25}
								thickness={100}
								speed={100}
								color="#2FA3E6"
							/>
						);
						statusText = 'Pending';
						bg = '#bae6fd';
						textColor = '#0c4a6e';
						break;
					case 'ACTIVE':
						statusText = 'Active';
						bg = '#bbf7d0';
						textColor = '#14532d';
						break;
					case 'ACTIVE':
						statusText = 'In Progress';
						bg = '#bae6fd';
						textColor = '#0c4a6e';
						break;
					case 'INVALID':
						statusText = 'Invalid';
						bg = '#fecaca';
						textColor = '#7f1d1d';
						break;
					case 'LOCKED':
						statusText = 'Locked';
						bg = '#fecaca';
						textColor = '#7f1d1d';
						break;
					case 'OTP':
						statusText = 'OTP';
						bg = '#fecaca';
						textColor = '#7f1d1d';
						break;
					default:
						icon = null;
						statusText = 'Unknown';
						bg = '#fef08a';
						textColor = '#713f12';
						break;
				}

				return (
					<p style={{ display: 'flex' }}>
						<span
							style={{
								backgroundColor: bg,
								padding: '5px 15px',
								color: textColor,
								fontSize: '14px',
								borderRadius: '15px',
								display: 'flex',
								width: '130px',
								justifyContent: 'center',
							}}
						>
							{icon} {statusText}
						</span>
					</p>
				);
			},
		},
	];

	const handleUpdateClick = () => {
		fetch(main_url + 'credentials', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(csvData),
		})
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				throw new Error('Network response was not ok.');
			})
			.then(data => {
				setIsModalVisible(false);
				fetch(main_url + 'credentials/' + props.customerDomain, {
					method: 'GET',
					headers: headers, // Pass the custom headers here
				})
					.then(response => response.json())
					.then(data => {
						// Check if data length is greater than 0 before setting the state
						if (data.length > 0) {
							toast.info('Creds updated Successfully');
							setCreds_data(data);
						}
					})
					.catch(error => {
						toast.error('Error fetching creds_data:', error);
					});
			})
			.catch(error => {
				// Handle errors here
				toast.error('Error Uploading: Dublicate Data', error);
			});
	};

	const handleRowData = async record => {
		setRowDataLoading(true);
		setVisible(true);
		try {
			const response = await axios.get(
				`${main_url}gst/info/${record.gst}`,
				{
					headers: {
						Accept: 'application/json',
						'ngrok-skip-browser-warning': 40,
					},
				},
			);
			if (
				response.data &&
				response.status == 200 &&
				response.data.gstin === record.gst
			) {
				setRowData(response.data);
				setRowDataLoading(false);
			} else {
				setRowData({});
			}
		} catch (err) {
			console.log(err);
			setRowData({});
			setRowDataLoading(false);
		}
	};

	return (
		<>
			{isTableLoading && (
				<div style={{ width: '100vw' }}>
					<Flex
						justifyContent="center"
						alignItems="center"
						style={{ height: '80vh' }}
					>
						<Spin />
					</Flex>
				</div>
			)}
			{!isTableLoading && (
				<div style={{
					height: "100%"
				}}>
					{showTable && isTableView && (
						<div style={{ width: '100%' }}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '30px',
								}}
							>
								<h2
									style={{
										fontSize: '24px',
										fontWeight: 'bold',
										color: '#2d373c',
										padding: 0,
										margin: 0,
									}}
								>
									{' '}
									GST CREDENTIALS
								</h2>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										position: 'absolute',
										right: '35px',
									}}
								>
									<button
										className="upload-btn"
										onClick={showModal}
									>
										<UploadOutlined
											style={{
												margin: '0 10px',
												fontSize: '18px',
											}}
										/>
										Upload Credentials
									</button>
								</div>
							</div>
							<div
								style={{
									padding: '20px',
									margin: '15px',
									display: 'flex',
									maxHeight: '75vh',
								}}
							>
								<div
									style={{
										maxHeight: '700px',
										overflowY: 'auto',
										backgroundColor: 'white',
									}}
								>
									<Table
										onRow={(record, rowIndex) => {
											return {
												onClick: event => {
													handleRowData(record);
												},
											};
										}}
										dataSource={creds_data}
										columns={creds_columns}
										pagination={false}
										style={{ cursor: 'pointer' }}
									/>
								</div>
							</div>
						</div>
					)}
					{visible && (
						<RowView
							visible={visible}
							onClose={() => setVisible(false)}
							rowData={rowData}
							rowDataLoading={rowDataLoading}
						/>
					)}
					{isModalVisible && <StepperProgress 
					customerDomain={
						props.customerDomain
					}/>}
					{/* <ToastContainer /> */}
				</div>
			)}
		</>
	);
};

export default CredContainer;
