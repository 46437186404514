import styled from 'styled-components';
import colors from './theme';

export default styled.div`
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: ${props => props.width || '200px'};
	font-size: 12px;
	padding: 10px;
	border-bottom: 1px solid ${colors.tableBorderColor};
	border-right: 1px solid ${colors.tableBorderColor};
	flex: 0 0 auto;
	min-height: ${props => props.height || '45px'};
	max-height: ${props => props.height || '45px'};
`;
