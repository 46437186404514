import React, { Component } from 'react';
import '../../../public/css/app.css';
import { AppStyle } from '../../../constants/AppStyle';
import { CarouselView } from './carousel/CarouselView';
import Auth from '../../../services/Auth';
import Api from '../../../helpers/api';
import { IMAGES_URL } from '../../../constants/Images';
import { Row, Col, Button, Input, Form, message } from 'antd';
import finkraftLogo from '../../../assets/finkraftLogo.svg';

const FormItem = Form.Item;

const StylesReg = {
	LOGO_HEAD: {
		// backgroundImage: `url(${IMAGES_URL.LOGIN_LOGO})`,
		backgroundSize: '100% 100%',
	},
};
export const getHeaders = () => {
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'ngrok-skip-browser-warning': 40, 
	};
	return headers;
};

const auth = new Auth();
const api = Api();

const Styles = {
	GRADIENT: [
		{ background: `linear-gradient(135deg, #0053FF 0%, #00D7FF 100%)` },
		{ background: `linear-gradient(315deg, #1ABC9C 0%, #00D7FF 100%` },
		{ background: `linear-gradient(135deg, #0053FF 0%, #00D7FF 100%)` },
		{ background: `linear-gradient(315deg, #1ABC9C 0%, #00D7FF 100%` },
	],
};

export class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			verified: false,
			email: '',
			mobile: '',
			code: '',
			authFlow: '',
			setCarouselPosition: 0,
			flag: true,
			emailStatus: false,
			id: '',
			otp: null,
			id_placeHolder: '',
			type: '',
			otpOrPass: 'Enter OTP',
			otpEntered: false,
			loading: false,
			phone_number: { value: '' },
		};
		this.CarouselPosition = this.CarouselPosition.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setAuthFlow = this.setAuthFlow.bind(this);
		this.getID = this.getID.bind(this);
		this.cardClicked = this.cardClicked.bind(this);
		this.requestOTP = this.requestOTP.bind(this);
	}

	cardClicked(value) {
		this.setState({ flag: !this.state.flag });
		if (value === 'email') {
			this.setState({
				id: 'Email Address',
				id_placeHolder: 'example@abc.xyz',
				type: 'phone number',
			});
		} else {
			this.setState({
				id: 'Phone Number',
				id_placeHolder: '9876543210',
				type: 'Email',
			});
		}
	}

	requestOTP(basic) {
		if (this.validateEmail(this.state.email.toLowerCase())) {
			this.handleSubmit(basic);
		} else {
			message.error('Please enter a valid email address');
		}
	}

	getID(value, type) {
		if (type === 'email') {
			this.setState({ email: value });
		} else {
			this.setState({ mobile: value });
		}
	}

	switchLoginType() {
		if (this.state.type === 'phone number') {
			this.setState({
				id: 'Phone Number',
				authFlow: 'phone',
				id_placeHolder: '9876543210',
				type: 'Email',
				email: '',
				mobile: '',
				otp: null,
				otpEntered: false,
				loading: false,
			});
		} else {
			this.setState({
				id: 'Email Address',
				authFlow: 'email',
				id_placeHolder: 'example@abc.xyz',
				type: 'phone number',
				email: '',
				mobile: '',
				otp: null,
				otpEntered: false,
				loading: false,
			});
		}
	}

	setAuthFlow(flow) {
		this.setState({
			authFlow: flow,
		});
	}

	showError(e) {
		message.error(e);
	}

	handleChange(e) {
		const { name, value } = e.target;
		if (name === 'mobile' && !value.match(/^$|^\d+$/)) {
			message.error(
				'Mobile number should be valid and contain the country code',
			);
		} else {
			this.setState({
				[name]: value,
			});
		}
	}

	validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	handleSubmit(basic) {
		if (!this.state.verified) {
			if (this.state.authFlow === 'email') {
				fetch(
					`${api.api}/login?email=${this.state.email.toLowerCase()}`,{
						headers: getHeaders()
					}
				)
					.then(res => res.json())
					.then(json => {
						if (json.success) {
							if (!basic) {
								message.success(json.message);
								auth.sendMail(this.state.email);
								this.setState({
									otpEntered: true,
									loading: true,
								});
								this.setState({
									otpOrPass: 'Enter OTP',
									verified: true,
									otpEntered: true,
									loading: true,
								});
							} else {
								this.setState({
									otpOrPass: 'Enter Password',
									verified: true,
									otpEntered: true,
									loading: true,
								});
							}
							auth.setContactId(json.contactId);
						} else {
							message.error(json.message);
						}
					});
			} else {
				fetch(`${api.api}/login?mobile=${this.state.mobile}`, {
					headers: getHeaders()
				})
					.then(res => res.json())
					.then(json => {
						if (json.success) {
							this.setState({
								verified: true,
							});
							message.success(json.message);
							auth.sendSms(this.state.mobile);
							auth.setContactId(json.contactId);
						} else {
							message.error(json.message);
						}
					});
			}
		} else {
			if (this.state.authFlow === 'email') {
				auth.login(this.state.email, this.state.otp, this.showError);
			} else {
				auth.loginWithSms(
					this.state.mobile,
					this.state.otp,
					this.showError,
				);
			}
		}
	}

	CarouselPosition(to) {
		this.setState({ setCarouselPosition: to });
	}

	render() {
		const { email, phone_number } = this.state;
		return (
			<div
				className="login-container center-flex"
				style={{
					...Styles.GRADIENT[this.state.setCarouselPosition],
					...AppStyle.FULL_CONTAINER(0),
				}}
			>
				<div className="column">
					<CarouselView CarouselPosition={this.CarouselPosition} />
				</div>
				<div
					style={{
						position: 'absolute',
						width: '100%',
						right: '8.33%',
					}}
				>
					<div className="white-card">
						<Row className="login-logo">
							<div
								className="horizontal-center"
								style={StylesReg.LOGO_HEAD}
							>
								<img
									className="logo-lg marginTop-10"
									// src={IMAGES_URL.LOGO_SM}
									src={finkraftLogo}
									alt="finkraft-logo"
								/>
							</div>
						</Row>
						{this.state.flag ? (
							<div className="marginTop-20">
								<Row className="login-text1">
									<label>Login Via</label>
								</Row>
								<Row className="center-flex" type="flex">
									<Col>
										<Row
											className="img-card"
											onClick={() => {
												this.cardClicked('email');
												this.setAuthFlow('email');
											}}
										>
											<Col>
												<img
													src={IMAGES_URL.MESSAGE}
													className="img-card-icon"
												/>
											</Col>
											<Col className="img-card-text">
												Email
											</Col>
										</Row>
									</Col>
									{/* <Col offset={1}>
                                        <Row style={{ backgroundColor: 'gray', opacity: 0.8 }} className="img-card" onClick={() => { message.info('This feature will be available soon.'); }}>
                                            <Col><img src={IMAGES_URL.PHONE} className="img-card-icon" /></Col>
                                            <Col className="img-card-text">Phone Number </Col>
                                        </Row>
                                    </Col> */}
								</Row>
							</div>
						) : (
							<div className="marginTop padding-24">
								<Row>
									<Col>{this.state.id}</Col>
									{this.state.id === 'Email Address' ? (
										<Col className="marginTop-10">
											<FormItem
												validateStatus={
													email.validateStatus
												}
												help={email.errorMsg}
											>
												<Input
													placeholder={
														this.state
															.id_placeHolder
													}
													value={this.state.email}
													onChange={e => {
														this.getID(
															e.target.value,
															'email',
														);
													}}
												/>
											</FormItem>
										</Col>
									) : (
										<Col className="marginTop-10">
											<FormItem
												validateStatus={
													phone_number.validateStatus
												}
												help={phone_number.errorMsg}
											>
												<Input
													placeholder={
														this.state
															.id_placeHolder
													}
													addonBefore={'+91'}
													value={this.state.mobile}
													onChange={e =>
														this.getID(
															e.target.value,
															'mobile',
														)
													}
												/>
											</FormItem>
										</Col>
									)}

									{this.state.otpEntered && (
										<div>
											<Col className="marginTop-10">
												<Input
													placeholder={
														this.state.otpOrPass
													}
													type="password"
													value={this.state.otp}
													onChange={e =>
														this.setState({
															otp: e.target.value,
														})
													}
												/>{' '}
											</Col>
											<Col>
												<Button
													type="primary"
													disabled={
														this.state.email ||
														(phone_number.value &&
															this.state.otp)
															? false
															: true
													}
													className="requeest-otp-btn"
													onClick={() =>
														this.handleSubmit()
													}
												>
													Login
												</Button>
											</Col>
										</div>
									)}

									{!this.state.otpEntered && (
										<Col>
											<a
												onClick={() =>
													this.requestOTP(true)
												}
											>
												<u>Login with Password</u>
											</a>
											<Button
												type="primary"
												loading={this.state.loading}
												disabled={this.state.verified}
												className="requeest-otp-btn"
												onClick={() =>
													this.requestOTP()
												}
											>
												{this.state.loading
													? ''
													: 'REQUEST OTP'}
											</Button>
										</Col>
									)}
									{/* <Col className="register-here" onClick={() => { this.switchLoginType(); }} >Login via {this.state.type} instead</Col> */}
								</Row>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default LoginPage;
