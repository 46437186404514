import React, { useState, useEffect, useRef, memo } from 'react';
import Tippy from '@tippyjs/react';

import MessageModal from '../../messageModal';
import styles from './matcherComponent.module.css';

const MatcherComponent = (props) => {
  const [isMissing, setIsMissing] = useState(false);
  const [isMismatch, setIsMismatch] = useState(false);
  const [difference, setDifference] = useState(0);
  const [textMsg, setTextMsg] = useState('');
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const { value, data, column } = props;
  const sourceColumn = column.colId.split(' - ')[1];
  const sourceData = data && data[`2A - ${sourceColumn}`];

  useEffect(() => {
    if (value === undefined) {
      setIsMissing(true);
    }

    if (value !== undefined && sourceData !== value) {
      setIsMismatch(true);
    }

    if (Number(value)) {
      const diff = value - sourceData;
      setDifference(diff.toFixed(2));
    }

    const emailMessage = `Dear ${data['Vendor - Vendor Name']}, \n\nThere seems to be a mismatch in the Invoice no ${data['2A - Invoice No']} dated ${data['2A - Invoice dt']} of Rs. ${difference}. Please correct this data for this invoice. \n\nRegards,`;
    setTextMsg(emailMessage);
  }, [value, sourceData, data, difference]);

  return (
    <Tippy
      ef={tippyRef.current}
      content={"Hello World"}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="right"
    >
      <div onClick={visible ? hide : show} className={styles.bgWrapper} style={{ backgroundColor: isMissing ? '#ff000080' : '' }}>
        {value}
        {isMismatch &&
          <span className={`${styles.diffWrap} ${difference > 0 ? styles.positiveDiff : styles.negativeDiff}`}>
            <i className={difference > 0 ? 'bi-arrow-up-short' : 'bi-arrow-down-short'}></i>
            {difference}
          </span>
        }
      </div>
    </Tippy>
  );
};

export default memo(MatcherComponent);
