import React,{useEffect,useState} from "react";
import { ArrowRightOutlined, StarFilled } from '@ant-design/icons';
import './stepper.css';
import bilingIllustration from '../../assets/billing_illustration.avif';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

const Step3 = ({ handleProceed }) => {
    const [countdown, setCountdown] = useState(5); // Initial countdown time in seconds

    // Update countdown every second and reload the page when countdown reaches 0
    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        if (countdown < 0) {
            clearInterval(interval);
            window.location.reload(); // Reload the page
            // You can also redirect to a specific URL using: window.location.href = 'your-url';
        }

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, [countdown]);
    return (
        <div className="step-container-info">
            <div className="step">
                <h1>Kudos!! You Have Successfully Integrated Your GST Credentials</h1>
                <div>
                    <div className="step-points">
                        <p style={{fontSize:"18px",fontWeight:"600"}}>  <LoadingOutlined style={{ color: '#e2ac08', marginRight:"10px" }} />Fetching Data</p>
                    </div>
                </div>
            </div>
            <div class="illustration-container">
                <img
                    alt="img"
                    src={bilingIllustration}
                    style={{ width: 'inherit', height: 'inherit' }}
                />
            </div>
        </div>
    )
}
export default Step3;