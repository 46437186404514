import React, { useState } from 'react';
import axios from 'axios';
import { Button, Input, Modal, Alert, Form, notification } from 'antd';
import { connect } from 'react-redux';

import { isValidEmail } from '../../helpers/utils';
import api from '../../helpers/api';

const EmailReport = ({
	index,
	query,
	customerDomain,
	defaultEmail,
	currentView,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [email, setEmail] = useState(defaultEmail);

	function onClose() {
		setIsDownloading(false);
		setEmail(defaultEmail);
		setIsOpen(false);
	}

	function toggleModal() {
		setIsOpen(!isOpen);
	}

	async function handleEmail() {
		if (isValidEmail(email)) {
			setIsDownloading(true);
			try {
				await axios.post(
					`${api().api}/export/csv/email`,
					{
						email,
						senderEmail: defaultEmail,
						currentView,
						indexName: index,
						query: {
							type: '_doc',
							body: {
								query,
							},
						},
					},
					{
						headers: {
							'x-domain': customerDomain,
						},
						timeout: 500000,
					},
				);

				notification.success({
					message: 'Email sent successfully',
				});

				onClose();
				setIsDownloading(false);
			} catch (err) {
				console.error(err);
				notification.error({
					message: 'Error',
					description: 'Unable to send email',
				});
				setIsDownloading(false);
			}
		} else {
			notification.error({
				message: 'Error',
				description: 'Invalid email',
			});
		}
	}

	function handleEmailChange(e) {
		setEmail(e.target.value);
	}

	return (
		<>
			<Button
				type="primary"
				icon="mail"
				onClick={toggleModal}
				style={{ marginLeft: 5 }}
			>
				Email
			</Button>
			<Modal
				title="Email Current Report"
				visible={isOpen}
				onOk={handleEmail}
				onCancel={onClose}
				okText="Send email"
				okButtonProps={{
					disabled: isDownloading,
					loading: isDownloading,
				}}
			>
				<Form.Item label="Email">
					<Input
						type="email"
						placeholder="Enter email"
						value={email}
						onChange={handleEmailChange}
					/>
				</Form.Item>
				<Alert
					message="File link with all the records will be emailed to recipient entered above. This might take us a few minutes to process."
					type="info"
					showIcon
					style={{ marginTop: 10 }}
				/>
			</Modal>
		</>
	);
};

const mapStateToProps = state => ({
	defaultEmail: window.localStorage.getItem('email'),
	customerDomain: state.customerDomain,
});
export default connect(mapStateToProps)(EmailReport);
