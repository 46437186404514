import React, { Fragment } from 'react';
import styled from 'styled-components';
import FaSortAsc from 'react-icons/lib/fa/sort-alpha-asc';
import FaSortDesc from 'react-icons/lib/fa/sort-alpha-desc';

import Flex from '../styled/Flex';

import colors from './theme';
import overflowStyles from './overflow.style';

const Container = styled(Flex)`
	background: ${colors.background};
	border-radius: 3px;
	max-width: 200;
	font-size: 13px;
	cursor: pointer;
	margin: 2px 3px;
	padding: 5px 8px;
	font-size: 0.85rem;
	position: relative;
	:hover {
		background-color: ${colors.hoverBackground};
		span {
			text-decoration: line-through;
		}
	}
`;

const SortFiler = ({ field, order, onResetSort }) => (
	<Fragment>
		{field !== '_doc' && field !== '_score' && (
			<Container alignItems="center" className={overflowStyles}>
				{order === 'asc' ? <FaSortAsc /> : <FaSortDesc />}
				<span
					style={{
						padding: '0 8px',
						maxWidth: '80%',
					}}
					className={overflowStyles}
				>
					{field.toString().split('.')[0]}
				</span>
				<button
					type="button"
					style={{
						outline: 0,
						border: 0,
						cursor: 'pointer',
						background: 'none',
					}}
					onClick={onResetSort}
				>
					✕
				</button>
			</Container>
		)}
	</Fragment>
);

export default SortFiler;
