import React, { useState } from 'react';
import axios from 'axios';
import { Button, notification } from 'antd';
import { connect } from 'react-redux';

import api from '../../helpers/api';

const ExportCSV = ({ query, customerDomain, index }) => {
	const [isDownloading, setIsDownloading] = useState(false);
	async function handleExport() {
		setIsDownloading(true);
		try {
			const res = await axios.post(
				`${api().api}/export/csv`,
				{
					indexName: index,
					query: {
						type: '_doc',
						body: {
							query,
						},
					},
				},
				{
					headers: {
						'x-domain': customerDomain,
					},
					timeout: 500000,
				},
			);

			const { downloadUrl } = res.data;

			if (downloadUrl) {
				const a = document.createElement('a');
				a.href = downloadUrl; // eslint-disable-line
				a.setAttribute('target', '_blank');
				a.click();
			}

			setIsDownloading(false);
		} catch (err) {
			console.error(err);
			notification.error({
				message: 'Error',
				description: 'Unable to download file',
			});
			setIsDownloading(false);
		}
	}

	return (
		<>
			<Button
				type="primary"
				icon="download"
				loading={isDownloading}
				disabled={isDownloading || !query}
				onClick={handleExport}
				style={{
					marginLeft: 5,
				}}
			>
				Export
			</Button>
		</>
	);
};

const mapStateToProps = state => ({
	customerDomain: state.customerDomain,
});

export default connect(mapStateToProps)(ExportCSV);
