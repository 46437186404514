import styled from 'styled-components';

import theme from '../../helpers/theme';

const NavListItem = styled.div`
	font-size: 16px;
	padding: 0.5em;
	padding: 15px 20px;
	cursor: pointer;
	display: flex;
	height: 100%;
	align-items: center;
	color: ${props => (props.active ? theme.color.navhover : "#374151")};
	margin: 0 5px;
	font-weight: 600;
	a {
		padding: 1em 0.5em;
		text-decoration: none;
		color: ${props =>
			props.active ? theme.color.navhover : theme.color.bg};

		&:visited {
			color: ${props =>
				props.active ? theme.color.navhover : theme.color.bg};
		}
	}
	&:hover {
		background-color: #22d3ee;
		border-radius: 10px;
		color: ${theme.color.bg};

		a {
			color: ${theme.color.bg};
		}
	}
`;

export default NavListItem;
