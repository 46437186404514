import React, { useState, useEffect } from 'react';
import { ReactiveBase } from '@appbaseio/reactivesearch';
import { Icon, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import DynamicTab from './DynamicTab';
import Activity from './Activity';
import Summary from '../Summary';
import Auth from '../../services/Auth';
import Flex from '../styled/Flex';
import ErrorLog from './ErrorLog';
import ScheduledEmails from './ScheduledEmails';

import api from '../../helpers/api';
import { getTableQuery } from '../../helpers/aggregator';

const ES_APP = 'shopelect-sync-data'; // TODO change for prod
const fixedColors = [
	'rgba(255, 175, 0, 0.35)',
	'rgba(26, 188, 156, 0.35)',
	'rgba(13, 102, 178, 0.35)',
];
const FIXED_TABLES = [
	{
		tableName: 'Invoice Not Received But In 2A-E2',
		tabName: 'Invoices Not Received But in 2A/6A',
		tabClass: 'color-yellow-opac-35',
	},
	{
		tableName: 'Invoices Not Received As Per Booking-E3',
		tabName: 'Invoices Not Received as Per Booking',
		tabClass: 'color-green-opac-35',
	},
	{
		tableName: 'Invoice Received But Not In 2A-E1',
		tabName: 'Invoices Received but not in 2A',
		tabClass: 'color-blue-opac-35',
	},
];

const OTHER_TABS = [
	{
		tableName: 'Activity Logs',
		tabName: 'Activity Logs',
	},
	{
		tableName: 'Scheduled Emails',
		tabName: 'Scheduled Emails',
	},
];

const SideBar = styled.div`
	width: ${({ isOpen }) => (isOpen ? '400px' : '80px')};
	height: calc(100%);
	overflow-y: auto;
	padding: 15px 0;
	position: fixed;
	left: 0;
	right: 0;
	background: #f0f0f0;
	border-right: 1px solid #f0f0f0;
`;

const Content = styled.div`
	width: ${({ isOpen }) =>
		isOpen ? 'calc(100% - 400px);' : 'calc(100% - 80px);'};
	position: relative;
	left: ${({ isOpen }) => (isOpen ? '400px' : '80px')};
	padding: 10px 30px;
	background-color: #fefefe;
`;

const SideBarTrigger = styled.div`
	position: fixed;
	bottom: 0;
	z-index: 100;
	height: 48px;
	color: #fff;
	line-height: 48px;
	text-align: center;
	background: #2d373c;
	transition: all 0.2s;
	padding: 20px;
	width: ${({ isOpen }) => (isOpen ? '400px' : '80px')};
	border: 0;
	outline: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ClosedSideBarContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const ClosedSideBarItem = styled.div`
	height: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	cursor: pointer;
	font-size: 24px;
`;

const Menu = styled.div`
	white-space: nowrap;
	display: flex;
	overflow-y: hidden;
	flex-wrap: nowrap;
	height: 37px;
	position: absolute;
	top: 0;
	left: 0;
`;

const MenuItem = styled.div`
	padding: 10px;
	display: inline-block;
	margin: 0 2px;
	border-bottom: ${props =>
		props.active
			? '2px solid dodgerblue'
			: `2px solid ${fixedColors[props.index] || '#fff'}`};
	cursor: pointer;
	font-size: small;
	-webkit-background-clip: padding-box; /* for Safari */
	background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
`;

const AirlineReport = ({ history, customerDomain, workspace }) => {
	const auth = new Auth();
	const [isSideBarOpen, setSideBarState] = useState(true);
	const [initialized, setInitialized] = useState(false);
	const [isFetchingTables, setIsFetchingTables] = useState(true);
	const [tabs, setTabs] = useState([]);
	const [activeTab, setActiveTab] = useState(FIXED_TABLES[0].tabName);

	let termColumn = '';
	let termValue = '';
	let isCustomer = true;
	if (!_.isEmpty(workspace)) {
		termColumn =
			workspace.Filter_Column === 'Airline'
				? 'Airline.keyword'
				: 'Customer_Name.keyword';
		termValue = workspace.Filter_Value;

		isCustomer = workspace.Filter_Column === 'Customer_Name';
	}

	useEffect(() => {
		if (!initialized) {
			getTables();
			setInitialized(true);
		}
	}, []);
	function toggleSideBar() {
		setSideBarState(!isSideBarOpen);
	}

	function toggleIsFetchingTables() {
		setIsFetchingTables(!isFetchingTables);
	}

	async function getTables() {
		if (!_.isEmpty(workspace)) {
			if (termValue !== 'Admin') {
				const body = getTableQuery(termColumn, termValue);
				try {
					const apiRes = await axios.post(
						`${api().api}/airline-reports/${ES_APP}/_doc/_search`,
						body,
						{
							headers: {
								'x-autho-token': auth.getAccessToken(),
								'x-domain': customerDomain,
								'ngrok-skip-browser-warning': 40, 
							},
						},
					);
					toggleIsFetchingTables();
					const {
						data: {
							aggregations: {
								tables: { buckets },
							},
						},
					} = apiRes;
					const tabList = buckets.map(item => item.key);
					const tabMap = [
						...FIXED_TABLES,
						...tabList.map(item => {
							const [tabName, tableName] = item.split('~~');
							return {
								tableName,
								tabName,
							};
						}),
					];

					setTabs([...tabMap, ...OTHER_TABS]);
				} catch (err) {
					toggleIsFetchingTables();
					console.error('FETCH TABLES ERROR', err);
				}
			} else {
				// TODO Add error logs here for admin
				setTabs([
					...FIXED_TABLES,
					...OTHER_TABS,
					{
						tableName: 'Error Logs',
						tabName: 'Error Logs',
					},
				]);
				toggleIsFetchingTables();
			}
		} else {
			toggleIsFetchingTables();
		}
	}

	async function responseTransformer(res, componentId) {
		const resId = componentId.split('_')[0];
		if (resId === 'result' && res.hits.hits && res.hits.hits.length) {
			const { hits } = res.hits;
			const ids = hits.map(item => item._id);
			const body = {
				query: {
					ids: {
						values: ids,
					},
				},
			};

			const uiData = await axios.post(
				`${
					api().api
				}/airline-reports/shopelect-ui-updates/_doc/_search`,
				body,
				{
					headers: {
						'x-autho-token': auth.getAccessToken(),
						'x-domain': customerDomain,
						'ngrok-skip-browser-warning': 40, 
					},
				},
			);

			const { data } = uiData;
			if (data.hits && data.hits.hits && data.hits.hits.length) {
				const hits = res.hits.hits.map(item => {
					return {
						...item,
						_source: {
							...(
								data.hits.hits.find(
									r => r._id === item._id && r._source,
								) || {}
							)._source,
							...item._source,
						},
					};
				});

				const newRes = {
					...res,
					hits: {
						...res.hits,
						hits,
					},
				};

				return newRes;
			}
			return res;
		}
		return res;
	}

	let currentTab = {};

	if (tabs.length > 0) {
		currentTab = tabs.find(item => item.tabName === activeTab);
	}

	return !_.isEmpty(workspace) ? (
		<Flex
			style={{ marginTop: '-105px', position: 'relative' }}
			key={termValue}
		>
			<SideBar
				style={{
					paddingTop: 25,
				}}
				isOpen={isSideBarOpen}
			>
				<ReactiveBase
					app={ES_APP}
					url={`${api().api}/airline-reports`}
					headers={{
						'x-autho-token': auth.getAccessToken(),
						'x-domain': customerDomain,
					}}
					transformResponse={responseTransformer}
				>
					{isSideBarOpen ? (
						<Summary />
					) : (
						<ClosedSideBarContainer>
							<ClosedSideBarItem>
								<Tooltip
									title="Open sidebar to view summary"
									placement="right"
								>
									<Icon type="dashboard" />
								</Tooltip>
							</ClosedSideBarItem>
						</ClosedSideBarContainer>
					)}
					<SideBarTrigger isOpen={isSideBarOpen}>
						<Icon
							type={isSideBarOpen ? 'left' : 'right'}
							style={{
								fontSize: 24,
								cursor: 'pointer',
							}}
							onClick={toggleSideBar}
						/>
					</SideBarTrigger>
				</ReactiveBase>
			</SideBar>
			<Content isOpen={isSideBarOpen} style={{ position: 'relative' }}>
				<ReactiveBase
					app={`${ES_APP},shopelect-activity-log,sync-errors`}
					url={`${api().api}/airline-reports`}
					headers={{
						'x-autho-token': auth.getAccessToken(),
						'x-domain': customerDomain,
					}}
					transformResponse={responseTransformer}
				>
					<Scrollbars
						style={{
							width: '100%',
							height: 40,
							borderBottom: '1px solid #dfdfdf',
							overflowY: 'hidden',
						}}
						autoHide
					>
						<Menu>
							{tabs.map((tab, i) => (
								<MenuItem
									onClick={() => {
										setActiveTab(tab.tabName);
									}}
									className={tab.tabClass || ''}
									key={tab.tabName}
									active={tab.tabName === activeTab}
									index={i}
								>
									{tab.tabName}
								</MenuItem>
							))}
						</Menu>
					</Scrollbars>

					{!_.isEmpty(currentTab) && (
						<div style={{ marginTop: 30 }}>
							{activeTab !== 'Activity Logs' &&
								activeTab !== 'Error Logs' && 
								activeTab !== 'Scheduled Emails' && (
									<DynamicTab
										key={currentTab.tabName}
										currentTab={{ ...currentTab }}
									/>
								)}
							{activeTab === 'Activity Logs' && <Activity />}
							{activeTab === 'Error Logs' && <ErrorLog />}
							{activeTab === 'Scheduled Emails' && <ScheduledEmails />}							
						</div>
					)}
					
				</ReactiveBase>
			</Content>
		</Flex>
	) : (
		<Flex
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			style={{
				marginTop: 100,
			}}
		>
			<Icon
				type="frown"
				theme="outlined"
				style={{ fontSize: 44, marginBottom: 20 }}
			/>
			Please select workspace.
		</Flex>
	);
};

const mapStateToProps = (state, ownProps) => ({
	workspace: state.workspace,
});

export default withRouter(connect(mapStateToProps)(AirlineReport));
